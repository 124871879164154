import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "messaging-image avatar-handle" }
const _hoisted_3 = {
  key: 0,
  class: "avatar-img"
}
const _hoisted_4 = {
  key: 1,
  class: "avatar-img",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
  alt: ""
}
const _hoisted_5 = { class: "messaging-body" }
const _hoisted_6 = { class: "messaging-meta" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_auth_image = _resolveDirective("auth-image")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.msg.id,
    class: _normalizeClass(["messaging-wrap", {'messaging-right': (_ctx.msg.userId === _ctx.userStore.getters.id)}])
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.msg.userId !=='00000000-0000-0000-0000-000000000000')
        ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_3, null, 512)), [
            [_directive_auth_image, _ctx.imagePrefix + _ctx.msg.userId]
          ])
        : _createCommentVNode("", true),
      (_ctx.msg.userId ==='00000000-0000-0000-0000-000000000000')
        ? (_openBlock(), _createElementBlock("img", _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("b", null, _toDisplayString(_ctx.dateTime2(_ctx.msg.createdAt)), 1)
      ]),
      _createElementVNode("div", {
        class: "messaging-bubble",
        innerHTML: _ctx.msg.message
      }, null, 8, _hoisted_7)
    ])
  ], 10, _hoisted_1))
}