
import {defineComponent, reactive} from 'vue'
import channelStore from '@/stores/channel'
import GroupMembers from './GroupMembers.vue'
import SearchMembers from './SearchMembers.vue'

import {Channel} from '@/types'
import {useI18n} from "vue-i18n";
import ArrowForward from "@/assets/img/icons/arrow-forward.svg";
import ArrowBack from "@/assets/img/icons/arrow-back.svg";
import Logout from "@/assets/img/icons/logout.svg";
import Group from "@/assets/img/icons/group.svg";
import Mail from "@/assets/img/icons/mail.svg";
import Alarm from "@/assets/img/icons/alarm.svg";
import BootstrapModal from "@/components/BootstrapModal.vue"
import axios from "axios";

const messages = {
  "en": {
    archive: "Archive",
    archiveHeading: "Archive Network",
    leave: "Leave network",
    aboutgroup: "About Network",
    search: "Search for member",
    members: "Members",
    invite: "Invite",
    notifications: "Notifications",
    archiveExplanation: "The network will no longer be visible or available for the members. All content will be archived and you can easily restore access.",
  },
  "nb_NO": {
    archive: "Arkiver",
    archiveHeading: "Arkiver nettverk",
    leave: "Forlat gruppe",
    aboutgroup: "Om nettverket",
    search: "Søk etter medlem",
    members: "Medlemmer",
    invite: "Inviter",
    notifications: "Varsler",
    archiveExplanation: "Nettverket vil ikke lenger være synlig eller tilgjengelig for medlemmene. Alt innhold vil arkiveres og du kan enkelt gjenopprette nettverket.",
  }
}

// modal using routes, see https://stackoverflow.com/questions/41249767/control-modals-using-vue-router

export default defineComponent({
  components: { BootstrapModal, SearchMembers, ArrowForward, ArrowBack, Logout, Group, Mail, Alarm, GroupMembers },
  setup() {
    const state = reactive({
      group: {} as Channel,
    })
    
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    return { t, state }
  },
  mounted() {
    console.log("GroupInfo3:mounted()");
    console.log("GroupInfo3:mounted()", this.$route.params.id);

    let response = this.getChannel(this.$route.params.id as string)
      .then((resp) => {
        console.log("got group: ", resp)
        this.state.group = resp
      })
  },
  data() {
    return {
      showModal: false,
      currentPane: ''
    }
  },
  watch: {
    $route: {
      immediate: true,
      // See https://dev.to/charleswritescode/how-to-display-a-route-as-a-modal-in-vue-js-iid
      handler: function(newVal, oldVal) {
        this.showModal = newVal.meta && newVal.meta.showModal ? true : false;
        console.log("route watch", newVal.meta, newVal.meta.showModal, this.showModal)
      }
    }
  },
  
  methods: {
    async getChannel(channelId: string): Promise<any> {
      console.log("loading channel for id: " + channelId);

      return await axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels/'+channelId)
        .then(resp => {
          console.log("channel.ts:getChannel()", resp.data)
          return resp.data
        })
        .catch(err => {
          console.log("error: " + err)
          return
        })
    },
    showPane(ev: Event, pane: string) {
      if (ev) { ev.stopPropagation() }
      console.log('showing pane: ' + pane)
      this.currentPane = pane
    },
    leave() {
      console.log("toggling subscription: " + this.state.group.id);
      channelStore.subscribe("00000000-0000-0000-0000-000000000000", this.state.group.id, false)
      this.showModal = false
      this.$router.push("/")
    }
  }
})
