import { computed, inject, reactive} from 'vue'
import * as Request from '@/requests'
import axios, { AxiosResponse } from 'axios';
import {Message, Protocol, SearchResults, User} from '@/types'
import userStore from "@/stores/user";
import ReconnectingWebSocket from "reconnecting-websocket";

const state = reactive({
  messages: [] as Array<Message>,
  error: '',
  ws: {} as ReconnectingWebSocket,
  setup: false as boolean,
  cbs: [] as [string, (proto: Protocol) => any][],
  // cbs2: [] as (Protocol => any)[],
})

const getters = reactive({
  latest: computed(() => state.messages),
})

function removeEmptyIds(key: any, value: any) {
  // console.log("key: " + key + ", value: " + value)  
  if (key == "recipientId" || key == "inReplyToId" || key == "id") {
    if (value === '') {
      console.log("removing value for key: " + key)
      return undefined;
    }
  }
  return value;
}

const actions = {
  async getMessages(id: string, title: string, count: number, before: string, cb: (title: string, messages: Message[]) => any) {

    console.log("Fetching messages for channel: " + id)
    
    const queryParams = new URLSearchParams(Object.assign({ "count": count }))
    if (before && before.length > 0) {
      queryParams.append("before", before)
    }
    
    const queryString = new URLSearchParams(queryParams).toString()
    
    axios.get (process.env.VUE_APP_BACKEND + `/api/v1/channels/${id}/messages?${queryString}`)
      .then(resp => {
        console.log("message.ts:getMessages())", resp.data);
        state.messages = resp.data
        cb(title, resp.data)
        return resp.data
      })
      .catch(err => {
        console.log("error: " + err)
      })
  },

  async getMessage(id: string): Promise<Message> {

    console.log("Fetching message with id: " + id)

    return axios.get (process.env.VUE_APP_BACKEND + `/api/v1/message/${id}`)
      .then(resp => {
        console.log("message.ts:getMessage())", resp.data);
        return resp.data
      })
      .catch(err => {
        console.log("error: ", err)
        return null
      })
  },
  
  
/*  async allMessages(id: string, count: number) {
    console.log("will fetch messages..")
    const response = await Request.getMessages(id, count)
      .then((responseData) => { 
        console.log("message.ts:allMessages())", responseData)
        state.messages = responseData
        return responseData
      })
  },*/

  async autocomplete(query: string): Promise<string[]> {
    console.log("autocomplete lookup for tags; ", query)
    return axios.get(process.env.VUE_APP_BACKEND + '/api/v1/messages/tags', {params: { query: query } })
      .then(resp => {
        console.log("response; ", resp.data)
        return resp.data;
      })
      .catch(err => {
      console.error("unable to perform autocomplete lookup")
      return null;
    })
    // return null;
    
  },

  async searchMessages(query: string): Promise<Message[]> {
    console.log("searching for messages; ", query)

    return axios.get(process.env.VUE_APP_BACKEND + `/api/v1/messages/search?query=${query}`)
      .then(resp => {
        console.log("response; ", resp)
        return resp.data;
      })
      .catch(err => {
        console.error("unable to perform messages search")
        return null;
      })
    // return null;
  },
  
  async markRead(messageId: string, channelId: string) {
    /*console.log("updating read timestamp for message id: " + messageId)
    const resp = await axios.post(process.env.VUE_APP_BACKEND + '/api/v1/messages/'+messageId+"/read", {})
      .then(resp => {
        console.log("markRead()", resp.data)
      })
      .catch(err => {
        console.log("error; ", err)
      })*/
    console.log("markRead", messageId)
    const payload = JSON.stringify({
      type: "read",
      id: messageId,
      read: {
        email: localStorage.getItem("uid"),
        userId: localStorage.getItem("id"),
        id: messageId,
      }
    }, removeEmptyIds)
    console.log('payload; ' + payload)
    state.ws.send(payload)
  },

  async like(messageId: string, channelId: string) {
    /*const resp = await axios.post(process.env.VUE_APP_BACKEND + '/api/v1/messages/'+messsageId+"/like", {})
      .then(resp => {
        console.log("like()", resp.data)
      })
      .catch(err => {
        console.log("error; ", err)
      })*/
    console.log("like", messageId)
    const payload = JSON.stringify({
      type: "like",
      id: messageId,
      like: {
        email: localStorage.getItem("uid"),
        userId: localStorage.getItem("id"),
        id: messageId,
      }
    }, removeEmptyIds)
    console.log('payload; ' + payload)
    state.ws.send(payload)
  },
  

  async lastMessages(query: string, count: number) {
    console.log("will fetch messages..")

    // const requestOptions = { method: "GET", headers: { 'Authorization': 'Bearer ' + userStore.getters.authToken, "Content-Type": "application/json" } }
    // const request = await fetch(process.env.VUE_APP_BACKEND + `/api/v1/messages?query=${query}&count=${count}`, requestOptions)
    
    axios.get (process.env.VUE_APP_BACKEND + `/api/v1/messages?query=${query}&count=${count}`)
      .then(resp => {
        console.log("message.ts:allMessages())", resp)
        state.messages = resp.data
        return resp

        //
        // console.log("message.ts:getMessages())", resp.data);
        // state.messages = resp.data
        // cb(title, resp.data)
        // return resp.data
      })
      .catch(err => {
        console.log("error: " + err)
      })
    
    
    /*const response = await Request.getMessages2(query, count)
      .then((responseData) => {
        console.log("message.ts:allMessages())", responseData)
        state.messages = responseData
        return responseData
      })*/
  },

  async send(channelId: string, inReplyToId: string, email: string, userId: string, message: string) {
    await this.post(channelId, inReplyToId, email, userId, message, "", "reply", "", 
      "", [], '');
  },

  async post(channelId: string, inReplyToId: string, email: string, userId: string, message: string, 
             title: string, type: string, postingType: string, url: string, tags: string[], id: string) {
    // console.log("wrapping up message for ws: " + message + ", channelId: " + channelId);
    // let keywords: string[]
    const tags2  = Object.assign([], tags)
    console.log("message:send()", channelId, inReplyToId, email, userId, message, url, tags2)
    const payload = JSON.stringify({
      type: "msg",
      id: channelId,
      message: {
        channelId: channelId,
        inReplyToId: inReplyToId,
        messageType: type,
        email: localStorage.getItem("uid"),
        userId: localStorage.getItem("id"),
        title: title,
        message: message, // this.stripHtml(message),
        postingType: postingType,
        externalUrl: url,
        tags: tags2,
        id: id,
      }
    }, removeEmptyIds)
    console.log('payload; ' + payload)
    state.ws.send(payload)
  },

  async call(recipientId: string, init: any) {
    console.log("call()", recipientId, localStorage.getItem("id"), init)
    state.ws.send(JSON.stringify({
      type: "call",
      id: recipientId,
      call: {
        messageType: "call",
        recipientId: recipientId,
        userId: localStorage.getItem("id"),
        connection: JSON.stringify(init),
      }
    }, removeEmptyIds));
  },

  async answer(recipientId: string, init: any) {
    console.log("answer()", recipientId, localStorage.getItem("id"), init)
    state.ws.send(JSON.stringify({
      type: "answer",
      id: recipientId,
      call: {
        messageType: "answer",
        recipientId: recipientId,
        userId: localStorage.getItem("id"),
        connection: JSON.stringify(init),
      }
    }, removeEmptyIds));
  },

  async candidate(recipientId: string, cand: any) {
    console.log("candidate()", recipientId, localStorage.getItem("id"), cand)
    state.ws.send(JSON.stringify({
      type: "candidate",
      id: recipientId,
      call: {
        messageType: "candidate",
        recipientId: recipientId,
        userId: localStorage.getItem("id"),
        // connection: JSON.stringify({type: "candidate", ice: JSON.stringify(cand)}),
        connection: JSON.stringify(cand),
      }
    }, removeEmptyIds));
  },
  
  async message(recipientId: string, email: string, userId: string, message: string) {
    // console.log("wrapping up message for ws: " + message + ", channelId: " + channelId);
    console.log("message:message()", recipientId, email, userId, message)
    state.ws.send(JSON.stringify({
      type: "msg",
      id: recipientId,
      message: {
        messageType: "msg",
        recipientId: recipientId,
        email: localStorage.getItem("uid"),
        userId: localStorage.getItem("id"),
        message: message,
      }
    }, removeEmptyIds));
  },
  
  async typing(id: string, email: string, userId: string) {
    console.log("message:throbber()", id, email, userId)
    state.ws.send(JSON.stringify({
      type: "typ",
      id: id,
      typing: {
        id: id,
        email: localStorage.getItem("uid"),
        userId: localStorage.getItem("id"),
      }
    }, removeEmptyIds));
  },
  
  // https://tutorialedge.net/javascript/vuejs/vuejs-websocket-tutorial/
  // https://masteringjs.io/tutorials/vue/vue-websocket
  // https://scotch.io/bar-talk/build-a-realtime-chat-server-with-go-and-websockets ??
  
  // reconnect, a few different approaches; https://stackoverflow.com/questions/3780511/reconnection-of-client-when-server-reboots-in-websocket
  
  async connect(channelId: string, cb: (proto: Protocol) => any) {
    console.log("registering WS callback for channelId: " + channelId)
    state.cbs.push([channelId, cb])
    this.start()
  },
  start() {
    const that = this
    if (!state.setup) {
      state.setup = true
      
      // https://github.com/pladaria/reconnecting-websocket 
      state.ws = new ReconnectingWebSocket(process.env.VUE_APP_WS_BROKER as string, [],{
        debug: true,
        
      });

      // state.ws.addEventListener('open', () => {
      //   state.ws.send('hello!');
      // });

      // let cid = channelId
      state.ws.onopen = () => {
        console.log('message:connect()', state.ws.url)
        state.ws.onmessage = ({data}) => {

          const proto: Protocol = JSON.parse(data)
          console.log('message:onmessage()', proto, data)

          // this.emitter.emit("ws-message", proto);
          
          // render message callbacks
          // console.log("onmessage size; ", state.cbs)
          let i = 0;
          for (const [id, cb] of state.cbs) {
            console.log("[" + i + "] check callback for id: " + id + ", mid: " + proto.id) //, messagep)
            i++;
            // if (id === proto.id) {
              console.log("CB!", proto)
              cb(proto)
            // }
          }
        }
        console.log("closing")
      }

/*      state.ws.onerror = (event) => {
        console.log("ws error: ", event)
      }

      state.ws.onclose = function(e) {
        console.log("ws close, reconnect?")
        // that.check();
      }
*/
    }
  },
  check() {
    if(!state.ws || state.ws.readyState == 3) {
      console.log("ws reconnecting")
      this.start();
    }
  }
}

export default { state, getters, ...actions }
