import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-696a48f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "verify",
  class: "scroll-area container-fluid"
}
const _hoisted_2 = { class: "h3 mb-3 fw-normal" }
const _hoisted_3 = { class: "token" }
const _hoisted_4 = { class: "d-grid gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsBar = _resolveComponent("BreadcrumbsBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadcrumbsBar, { title: "Registrering" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('heading')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.t('explanation')), 1),
      _createElementVNode("p", _hoisted_3, " debug token: " + _toDisplayString(_ctx.$route.params.token), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-rounded mt-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.verify(_ctx.$route.params.token)))
        }, _toDisplayString(_ctx.t('action')), 1)
      ])
    ])
  ]))
}