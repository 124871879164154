import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["list"]
const _hoisted_2 = {
  class: "itags",
  ref: "tagsList"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tag-input", { 'with-count': _ctx.showCount, duplicate: _ctx.state.noMatchingTag }])
  }, [
    _withDirectives(_createElementVNode("input", {
      class: "form-control border-0",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.newTag) = $event)),
      type: "text",
      list: _ctx.id,
      autocomplete: "off",
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTagsChange && _ctx.onTagsChange(...args))),
      onKeydown: [
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.addTag(_ctx.state.newTag)), ["prevent"]), ["enter"])),
        _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.addTag(_ctx.state.newTag)), ["prevent"]), ["tab"])),
        _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.state.newTag.length || _ctx.removeTag(_ctx.$props.modelValue.length - 1)), ["delete"]))
      ],
      onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addTagIfDelem(_ctx.state.newTag))),
      style: _normalizeStyle({ 'padding-left': `${_ctx.state.paddingLeft}px` })
    }, null, 44, _hoisted_1), [
      [_vModelText, _ctx.state.newTag]
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.modelValue, (tag, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tag,
          class: _normalizeClass(["itag", { duplicate: tag === _ctx.state.duplicate }])
        }, [
          _createTextVNode(_toDisplayString(tag) + " ", 1),
          _createElementVNode("button", {
            class: "delete",
            onClick: ($event: any) => (_ctx.removeTag(index))
          }, "✗", 8, _hoisted_3)
        ], 2))
      }), 128))
    ], 512),
    (_ctx.showCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$props.modelValue.length), 1),
          _createTextVNode(" tags ")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}