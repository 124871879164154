import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "navigationbar"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 1,
  class: "hd-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feed = _resolveComponent("feed")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Groups = _resolveComponent("Groups")!
  const _component_Messages = _resolveComponent("Messages")!
  const _component_Info = _resolveComponent("Info")!
  const _component_logout = _resolveComponent("logout")!

  return (_openBlock(), _createBlock(_Transition, { name: "list" }, {
    default: _withCtx(() => [
      (_ctx.$route.meta.hideNavigation !== true)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createElementVNode("div", {
              class: "app-logo hd-item",
              title: _ctx.t('home')
            }, [
              _createVNode(_component_router_link, {
                class: "",
                to: "/"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_feed)
                ]),
                _: 1
              })
            ], 8, _hoisted_2),
            _createElementVNode("div", {
              class: "app-logo hd-item",
              title: _ctx.t('subscription')
            }, [
              _createVNode(_component_router_link, {
                class: "",
                to: "/subscriptions"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Groups)
                ]),
                _: 1
              })
            ], 8, _hoisted_3),
            _createElementVNode("div", {
              class: "app-logo hd-item",
              title: _ctx.t('messages')
            }, [
              _createVNode(_component_router_link, {
                class: "",
                to: "/messages"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Messages)
                ]),
                _: 1
              })
            ], 8, _hoisted_4),
            (_ctx.userStore.getters.isLoggedIn)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "hd-item",
                  title: _ctx.t('about')
                }, [
                  _createVNode(_component_router_link, {
                    class: "",
                    to: "/about"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Info)
                    ]),
                    _: 1
                  })
                ], 8, _hoisted_5))
              : _createCommentVNode("", true),
            (_ctx.userStore.getters.isLoggedIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
                  }, [
                    _createVNode(_component_logout)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}