
import { defineComponent, onMounted } from 'vue'
import channelStore from '@/stores/channel'

export default defineComponent({
  name: "ChannelList",
  setup() {
    onMounted(() => {

      console.log("getting channels... ");

      /*let response = getUsers()
        .then((resp) => {
          console.log("loaded users: " + JSON.stringify(response), resp)
          this.state.users = resp

        })*/
    })

    const { state, getters, getChannels } = channelStore
    return { state, getters, getChannels }
  },
  mounted() { // deprecated
    console.log("mounted");
  },
})
