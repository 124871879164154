
/* eslint-disable */
/* eslint-disable vue/no-unused-components, vue/no-reserved-component-names, vue/no-unused-vars */
import {Form} from 'vee-validate'
import {useI18n} from 'vue-i18n'
import {defineComponent, onMounted, reactive, ref} from "vue"
import {Briefcase, CheckDecagram, ClipboardAccount, Earth, FileDocument, Image, Poll, Video} from "mdue";
import messageStore from '@/stores/message'
import Close from '@/assets/img/icons/close.svg'
import userStore from "@/stores/user"
import {useRouter} from "vue-router"
import * as Yup from "yup"
import {Modal} from 'bootstrap'
import VueBottomSheet from "@/components/external/VueBottomSheet.vue"
import TagInput from "@/components/external/TagInput.vue"
import Multiselect from '@vueform/multiselect'

// import '@mayank1513/tag-input/dist/TagInput.css'

// designs for bottom drawers
// https://dribbble.com/shots/9776568-Fund-Project
// https://dribbble.com/shots/9919199-Mobile-Bottom-Sheet
// https://dribbble.com/shots/14845410-Filter-Bottom-Sheet-Insurance-Ranked
// https://dribbble.com/shots/6759319-Sprint-App-UI-Interaction-Design
// https://material.io/components/sheets-bottom
// more: https://dribbble.com/tags/bottom_sheet

// https://www.npmjs.com/package/@vueform/multiselect#tags-with-search-create-and-array-of-objects-options
// https://vuejsexamples.com/vue-3-multiselect-component-with-multiselect-and-tagging-options/

const messages = {
  "en": {
    heading: "Create Posting",
    headingEdit: "Edit Posting",
    newPostingTitle: "Title",
    newPostingMessage: "What would you like to post?",
    newPostingUrl: "https://",
    newPostingUrlLabel: "Link to jobad",
    newPostingTagsLabel: "Add 1-3 keywords",
    settings: "Settings",
    interests: "Interests",
    messageRequired: "content required",
    create: "Post",
    save: "Save",
    postingTypePeopleRequest: "Looking for more people to hire",
    needpeople: "Looking for people",
    needjob: "Looking for a job",
    external: "Link to external job posting",
    keywords: "Keywords",
    deadline: "Deadline",
    location: "Area",
    ifrelevant: "If relevant",
  },
  "nb_NO": {
    heading: "Nytt innlegg",
    headingEdit: "Rediger innlegg",
    newPostingTitle: "tittel",
    newPostingMessage: "Hva har du lyst til å snakke om?",
    newPostingUrl: "https://",
    newPostingUrlLabel: "Lenke til jobbannonse",
    newPostingTagsLabel: "Legg til 1-3 stikkord",
    settings: "Innstillinger",
    interests: "Interesser",
    messageRequired: "tom melding",
    create: "Legg ut",
    save: "Lagre",
    postingTypePeopleRequest: "Ser etter nye medarbeidere",
    needpeople: "Ser etter folk",
    needjob: "Ser etter jobb",
    external: "Lenke til jobbannonse",
    keywords: "Stikkord",
    deadline: "Frist",
    location: "Område",
    ifrelevant: "Hvis relevant"
  }
}

export default defineComponent({
  components: { Form, Close, Earth, Briefcase, VueBottomSheet, Image, FileDocument, Video, Poll, 
    ClipboardAccount, CheckDecagram, TagInput, Multiselect },
  setup() {

    const element = ref<HTMLElement>()
    const instance = ref<Modal>()
    
    const typeSheet = ref<any>()
    
    const form = reactive({
      newPostingTitle: '',
      newPostingMessage: '',
      newPostingUrl: '',
      newPostingLocation: '',
      newPostingDeadline: '',
      hideUrl: true,
      newPostingType: 'job',
      newPostingTags: [] as string[],
      id: "",
      channelId: "",
    })
    
    const separators = ['enter','space','tab',',']
    
    const router = useRouter()

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    
    const schema = Yup.object().shape({
      newPostingMessage: Yup.string()
        .required(t('messageRequired')),
    })

    onMounted(() => {
      instance.value = new Modal(element.value as HTMLElement, {})
      // instance.value?.show()
    })
    
    return { form, t, router, schema, instance, element, typeSheet, userStore, onMounted, separators }
  },
  data() {
    return {
      customDelimiter: [','],
      postingTypeLabel: this.t('needpeople'),
      job: false,
      options: ['Batman', 'Robin', 'Joker',],
    }
  },
  methods: {
    onSubmit() {
      console.log("saving posting: " + this.form.newPostingTitle + ", ny? " + this.form.id === "")


      messageStore.post(this.form.channelId, '', localStorage.getItem("uid") as string, localStorage.getItem("id") as string,
        this.form.newPostingMessage, this.form.newPostingTitle, 'post', this.form.newPostingType, 
        this.form.newPostingUrl, this.form.newPostingTags, this.form.id)
        .then(() => {
          console.log("posted new post with title: " + this.form.newPostingTitle)
          /*if () {
            router.push('/channels');
            // reload channel list
            channelStore.getChannels()
          } else {
            // errors = {}
            // need to reset input validation, only show login error
          }*/
        })
      // this.instance?.value?.hide()
      this.instance?.hide()
    },
    openTypeSelector() {
      this.typeSheet?.open()
      // this.$refs.myBottomSheet.open();
    },
    closeTypeSelector() {
      this.typeSheet?.close()
      // this.$refs.myBottomSheet.close();
    },

    async autocomplete(query: string): Promise<string[]> {
      console.log("autocomplete: " + query)
      return await messageStore.autocomplete(query)
        .then(data => {
           console.log("data; ", data)
          return data
        })
    },
    
    select(type: string) {
      this.form.newPostingType = type
      if (type === "job") {
        this.postingTypeLabel = this.t('needpeople')
        this.form.hideUrl = true
        this.job = true
      } else if (type === "people") {
        this.postingTypeLabel = this.t('needjob')
        this.form.hideUrl = true
        this.job = false
      } else {
        this.postingTypeLabel = this.t('external')
        this.form.hideUrl = false
      }

      this.typeSheet?.close()
    },
    newPosting(channelId: string) {
      console.log("showing modal for posting in channel: " + channelId);
      this.form.newPostingTitle = ""
      this.form.newPostingMessage = ""
      this.form.newPostingUrl = ""
      this.form.newPostingTags = []
      this.form.id = ""
      this.form.channelId = channelId
      this.job = true
      this.postingTypeLabel = this.t('needpeople') 

      // this.TagInput.onTagsChange


      // let mo = new Modal((this.element as HTMLElement), {})
      // mo.show()
      this.instance?.show()
    },

    async editPosting(id: string) {
      console.log("showing modal");
      let self = this
      await messageStore.getMessage(id)
      .then(results => {
        console.log("resp1", results);

        self.form.newPostingTitle = ""
        self.form.newPostingMessage = results.message
        console.log("tags; " + results.tags)
        self.form.newPostingTags = results.tags
        console.log("tags; " + self.form.newPostingTags)
        self.form.newPostingUrl = results.externalUrl
        self.form.id = id
        self.form.channelId = results.channelId
        self.job = results.postingType === "job"

        self.postingTypeLabel = results.postingType === "job" ? self.t('needpeople') : self.t('needjob')
        
        console.log("using title: " + self.form.newPostingTitle)
        self.instance?.show()
      })
    },
    /*
    onSubmit2() {
      console.log("oppretter nytt nettverk: " + this.form.newPostingTitle)

      channelStore.createChannel(this.form.newPostingTitle, this.form.newPostingMessage, this.form.newPostingOpen, 
        this.form.newPostingPromote)
      .then((success) => {
        if (success) {
          this.router.push('/channels');
        } else {
          // router.push('/login')
          // errors = {}
          // need to reset input validation, only show login error
        }
      })
    }*/
  }  
})
