import {App} from "vue";
import axios, {AxiosResponse} from 'axios';

// https://github.com/championswimmer/vue-plugin-timers/blob/master/src/mixin.ts
// https://stackoverflow.com/questions/55311595/how-to-add-types-for-vue-plugin
// loosely based on https://github.com/theCrius/vue-connectivity

export default {
  install: (app: App, options?: Options) => {
    const status = {
      network: false,
      internet: false
    };
    // let options: Partial<Options> = {}
    const opts = Object.assign({
      heartbeatEnabled: true,
      heartbeatUrl: process.env.VUE_APP_BACKEND + '/api/v1/health',
      heartbeatInterval: 180000,
      requestMethod: 'get',
      debug: false,
    }, options);
    let heartBeatLoop = undefined as NodeJS.Timer | undefined;
    if (opts.debug) {
      console.log('Vue-Connectivity - Ready!', opts)
    }
    heartBeatInit(opts);

    app.mixin({
      data() {
        const onlineHandler = async () => {
          status.network = true;
          status.internet = await request(opts.requestMethod, opts.heartbeatUrl, opts.debug);
        }
        const offlineHandler = () => {
          status.network = false;
          status.internet = false;
        }
        return { onlineHandler, offlineHandler, vueConnectivity: status }
      },
      mounted() {
        if (typeof window !== 'undefined') {
          status.network = !!navigator.onLine;

          window.addEventListener('online', this.onlineHandler)
          window.addEventListener('offline', this.offlineHandler)
          
          // https://www.smashingmagazine.com/2022/09/javascript-api-guide/#page-visibility-api
          
          /*this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('online', this.onlineHandler)
            window.removeEventListener('offline', this.offlineHandler)
          })*/
        }
      },
      beforeUnmount() {
        // console.log("beforeUnmount")
        window.removeEventListener('online', this.$data.onlineHandler)
        window.removeEventListener('offline', this.$data.offlineHandler)
      },
    });

    async function heartBeatInit(options: Options, reset: boolean = false) {
      if (reset && heartBeatLoop) {
        if (options.debug) {
          // console.log('resetting heartbeat');
        }
        clearInterval(heartBeatLoop);
      }
      if (options.heartbeatEnabled) {
        status.internet = await request(options.requestMethod, options.heartbeatUrl,options.debug);
        heartBeatLoop = setInterval(async () => {
          status.internet = await request(options.requestMethod, options.heartbeatUrl,options.debug);
        }, options.heartbeatInterval);
      }
    }
  }
}

// https://rclayton.silvrback.com/easy-class-api-options-with-typescript-and-joi
type Options = {
  heartbeatEnabled: boolean,
  heartbeatUrl: string,
  heartbeatInterval: number,
  requestMethod: string,
  debug: boolean,
}


async function request(method: string, url: string, debug: boolean): Promise<boolean> {
  if (debug) {
    // console.log('Heartbeat to', url);
  }
  try {
    await axios.request<any>({
      url: url,
      method: method
    }).then((response: AxiosResponse<any>) => {
      if (debug) {
        console.log('Heartbeat succeeded: ', response);
      }
      return true
    }).catch((err) => {
      if (debug) {
        console.error('Heartbeat failed:', err)
      }
    })
  } catch (err) {
    if (debug) {
      console.error('Heartbeat failed:', err)
    }
  }
  return false
}
