
/* eslint-disable vue/no-reserved-component-names */
import {defineComponent, reactive} from 'vue'
import {useI18n} from 'vue-i18n'
import {Field, Form} from 'vee-validate';
import userStore from '@/stores/user'
import {useRouter} from 'vue-router'
import * as Yup from "yup";
import Forward from '@/assets/img/icons/arrow-forward.svg'

const messages = {
  "en": {
    heading: "Welcome to Heldel!",
    username: "email address",
    password: "password",
    passwordRequired: 'password required',
    login: "log in",
    emailRequired: "email required",
    emailInvalid: "email format invalid",
    action: "register account",
    forgot: "forgot password",
    required: "Registration at Heldel.no currently required using an email address with a whitelisted domain.",
  },
  "nb_NO": {
    heading: "Velkommen til Heldel!",
    username: "e-post adresse",
    password: "passord",
    passwordRequired: "du må skrive inn passord",
    login: "Logg inn",
    emailRequired: "oppgi e-post",
    emailInvalid: "ugyldig e-post format",
    action: "Opprett brukerkonto",
    forgot: "Glemt passord",
    required: "Registrering på Heldel krever foreløbig en e-post adresse fra en forhåndsgodkjent liste.",
  }
}

export default defineComponent({
  components: { Form, Field, Forward },
  setup() {
    const form = reactive({
      username: '',
      password: ''
    })
    const router = useRouter()

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const schema = Yup.object().shape({
      username: Yup.string()
        .required(t('emailRequired'))
        .email(t('emailInvalid')),
      password: Yup.string()
        .required(t('passwordRequired')),
    })

    const onSubmit3= () => {
      console.log("signing in")
      const u = form.username, p = form.password;
      // form.username = ''
      // form.password = ''
      // this.$validator.clean();
      // this.errors.clear()
      userStore.login(u, p)
        .then(success => {
          if (success) {
            router.push('/');
          } else {
            // router.push('/login')
            // errors = {}
            // need to reset input validation, only show login error
          }
        })
    }

    return { t, schema, form, router, userStore } //, onSubmit }
  },
  methods: {
    onSubmit() {
      // this.$refs.form.reset()
      console.log("signing in")
      const u = this.form.username, p = this.form.password;
      // form.username = ''
      // form.password = ''
      // this.$validator.clean();
      // this.errors.clear()
      console.log("v", this.$refs.observer)
      // this.$refs.observer.reset();


      userStore.login(u, p)
        .then(success => {
          if (success) {
            this.router.push('/');
          } else {
            // router.push('/login')
            // errors = {}
            // need to reset input validation, only show login error
          }
        })
    }
  }
})
