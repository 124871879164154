
import {defineComponent, PropType} from "vue"
import moment from 'moment'
import messageStore from '@/stores/message'
import userStore from '@/stores/user'
import router from "@/router";
import {Pencil, ThumbUp, ThumbUpOutline} from 'mdue';
import {Message} from '@/types'
import {useI18n} from "vue-i18n";
import LinkPrevue from "@/components/external/LinkPrevue.vue";
// import Waving from "@/assets/img/extras/Emoji_u1f44b.svg";

const messages = {
  "en": {
    privatemessage: "Private Message",
    replies: "replies",
    reply: "reply",
    like: "like",
    likes: "likes",
    hi: "say hi",
  },
  "nb_NO": {
    privatemessage: "Privat melding",
    replies: "svar",
    reply: "svar",
    like: "like",
    likes: "likes",
    hi: "si hei",
  }
}

export default defineComponent({
  components: { Pencil, LinkPrevue, ThumbUpOutline, ThumbUp, },
  props: {
    linkToChannel: { type: Boolean, default: false },
    showChannel: { type: Boolean, default: false },
    showLike: { type: Boolean, default: false },
    msg: { type: Object as PropType<Message>, default: () => ({}) },
    limitheight: { type: Boolean, default: false },
    replyLink: { type: Boolean, default: false },
    selectedId: { type: String, default: '' },
    me: { type: String, default: '' },
  },
  emits: ["select-message", "edit-message", "show-profile"],
  data() {
    return {
      imagePrefix: process.env.VUE_APP_BACKEND + '/images/'
    }
  },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    return { userStore, t }
  },
  methods: {
    liked(): boolean {
      if (!this.msg.likes) { return false }
      for (let i = 0; i < this.msg.likes.length; i++) {
        if (this.msg.likes[i].userId === this.userStore.getters.id) {
          return true
        }
      }
      return false
    },
    clickClick(ev: Event) {
      try {
        if (ev.currentTarget) {
          let url = (ev.currentTarget as HTMLElement).getAttribute('href')
          console.log("click;", url)
          if (url) { window.open(url, '_blank') }
        }
      } catch (err) { console.error("unable to open link; ", err) }
    },
    editable(id: string): boolean {
      return this.msg.userId === userStore.getters.id && this.msg.messageType === 'post'
    },
    // https://stackoverflow.com/questions/66857293/vue-3-date-time-format-in-moment-js
    dateTime(value: string) {
      moment(value).format('YYYY-MM-DD');
    },

    select() {
      console.log("select: " + (this.msg as Message).id)
      
      if (this.linkToChannel) {
        this.goto((this.msg as Message).channelId, true)
      } else {
        this.$emit('select-message', (this.msg as Message).id)
      }
    },
    showProfile() {
      console.log("show profile: " + (this.msg as Message).userId)
      this.$emit('show-profile', (this.msg as Message).userId)
    },
    
    like() {
      messageStore.like((this.msg as Message).id, (this.msg as Message).channelId);
    },
    edit() {
      console.log("edit: " + (this.msg as Message).id)
      this.$emit('edit-message', (this.msg as Message).id)
    },
    
    goto(channelId: string, goto: boolean) {
      console.log("navigating to channel: " + channelId + " go: " + goto);
      if (goto) {
        router.push({name: 'channel', params: {'id': channelId}});
      }
      // return true;
    },
    // https://momentjs.com/guides/
    dateTime2(value: string) {
      moment.locale("nb_NO")
      const diff = moment(new Date()).diff(value, 'day');
      if (diff < 3) {
        return moment(value).fromNow()
      } else {
        return moment(value).format('MMM Do YYYY')
      }
      
    }
  }
})
