
import {defineComponent, reactive, ref} from 'vue'
import messageStore from '@/stores/message'
import channelStore from '@/stores/channel'
import PostWrapper from '@/components/groups/PostWrapper.vue'
import {Channel, Message, Protocol} from '@/types'
import {Delta, QuillEditor} from '@vueup/vue-quill'
import Post from '@/assets/img/icons/post.svg'
import PostActive from '@/assets/img/icons/posting.svg'
import {useI18n} from "vue-i18n";
import PostingModal from "@/components/groups/PostingModal.vue";
import Back from '@/assets/img/icons/arrow-back.svg'
import Info from '@/assets/img/icons/info-yellow.svg'
import ProfileSheet from "@/components/profile/ProfileSheet.vue";

// onload example here https://stackoverflow.com/questions/40421945/vue-how-to-set-property-on-a-component-with-parent-and-in-itself-warn

// https://stackoverflow.com/questions/57600452/vue-js-correct-way-to-set-up-a-detail-component

// possibly integrate https://github.com/afry/quill-mention https://github.com/swim/quill-dropzone
// new theme https://quilljs.com/docs/themes/ https://stackoverflow.com/questions/50129438/creating-a-quill-js-theme

// to impl custom component: https://pineco.de/wrapping-quill-editor-in-a-vue-component/
// more quill resources: https://github.com/quilljs/awesome-quill
// https://github.com/vueup/vue-quill/blob/beta/demo/src/examples/WithForm.vue

// scroll config is based on this question
// https://stackoverflow.com/questions/14962468/how-can-i-combine-flexbox-and-vertical-scroll-in-a-full-height-app

const messages = {
  "en": {
    messagesfor: "Messages for ",
    created: "created",
    replies: "replies",
    reply: "reply",
    editorPlaceholder1: "write something in the network..",
    editorPlaceholder2: "write a reply..",
  },
  "nb_NO": {
    messagesfor: "Meldinger for ",
    created: "opprettet",
    replies: "kommentarer",
    reply: "kommentar",
    editorPlaceholder1: "skriv noe i nettverket..",
    editorPlaceholder2: "skriv et svar..",
  }
}


export default defineComponent({
  components: {
    QuillEditor, Post, PostingModal, PostWrapper, 
    Back, Info, PostActive, ProfileSheet,
  },
  props: ['id', 'title'],
  watch: {
    $route(newVal, oldVal) {
      console.log("route watch ")
      // this.showInfoModal = newVal.meta && newVal.meta.showInfoModal;
    }
  },
  data() {
    return {
      showInfoModal: false,
      content: "meldingen kommer her",
      lastScrollTop: Infinity as number
    }
  },
  setup() {
    // const smoothScroll = Vue.inject('smoothScroll')
    
    const editor = ref(null)
    const newMsg = ref('')

    const postingmodal = ref(null)
    
    // const scr = ref(null)
    const chscroller = ref(null)
    const state = reactive({
      // selectedId: '00000000-0000-0000-0000-000000000000',
      id: '',
      title: '',
      description: '',
      open: false,
      promote: false,
      messages: [] as Message[],
      group: {} as Channel,
      // newMsg: '',
      error: '',
      fetching: false,
      throbber: false,
      throbberId: '',
    })
    
    function hasMessages(): boolean { return state.messages.length !== 0 }

    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    const bindings = {
      custom: {
        key: 'enter',
        handler: function () {
          console.log("Channel:handler()")
          // this.send1();
          document.getElementById('send-btn')!.click()
        }
      },
    }
    // https://vueup.github.io/vue-quill/guide/options.html#globaloptions-prop
    // need to setmatchVisual to false, to avoid <p><br/></p> being added on empty content
    // https://bestofvue.com/repo/vueup-vue-quill-vuejs-rich-text-editing
    // https://github.com/quilljs/quill/blob/1.3.6/docs/docs/modules/clipboard.md#matchvisual
    
    // https://github.com/quilljs/quill/issues/1379
    const quillOptions = {
      theme: 'snow',
      placeholder: t('editorPlaceholder1'),
      modules: {
        toolbar: false,
/*        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          ['link', 'clean']
          // add emoji? https://github.com/contentco/quill-emoji
        ],*/
        keyboard: {
          bindings: bindings
        }
      }
    }
    
    
    return { state, messageStore, channelStore, hasMessages, t, quillOptions, editor, newMsg, chscroller, postingmodal }
  },
  
  mounted() {
    console.log("Channel:mounted()")
    console.log("channelId: " + this.id)
    messageStore.connect(this.id, this.onmessage)
    channelStore.setSelectedChannelId(this.id)

    // (this.chscroller! as HTMLBaseElement).addEventListener('scroll', this.onScroll);
  },
  unmounted() {
    console.log("ChannelPane:unmounted()")
    channelStore.setSelectedChannelId('00000000-0000-0000-0000-000000000000')
    channelStore.setSelectedPostId('00000000-0000-0000-0000-000000000000')
  },
  // https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
  // https://next.router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
  // https://stackoverflow.com/questions/14638990/are-strongly-typed-functions-as-parameters-possible-in-typescript
  
  beforeRouteEnter: function (to, from, next) {
    console.log("Channel:beforeRouteEnter()", to, from, next)
    channelStore.setSelectedChannelId(to.params.id as string)
    messageStore.getMessages(to.params.id as string, to.params.title as string,10, "",
      (title: string, messages: Message[]) => {
      console.log("Channel:beforeRouteEnter()", messages, title)
      next(vm => {
        (vm as any).state.title = to.params.title as string
        (vm as any).state.id = to.params.id as string
        (vm as any).setData(messages) // https://github.com/vuejs/vue/issues/8721
        let that = vm as any;
        let response = channelStore.getChannel(to.params.id as string)
          .then((resp) => {
            // console.log("got group: ", resp)
            that.state.group = resp
            // next(vm => {
            //   (vm as any).state.group = resp
            // })
            // console.log("got group, decoded/encoded; ", that.state.group)
          })
        
        // console.log("")
        
      })
    })

    
    /*channelStore.getChannel(to.params.id as string),
      (title: string, messages: Message[]) => {
      console.log("Channel:beforeRouteEnter()", )
      next(vm => {
        (vm as any).state.title = to.params.title as string
        (vm as any).state.id = to.params.id as string
        (vm as any).setData(messages) // https://github.com/vuejs/vue/issues/8721
      })
    })*/
  },
  beforeRouteUpdate: function (to, from, next) {
    console.log("Channel:beforeRouteUpdate()", to, from, next)

    channelStore.setSelectedChannelId(to.params.id as string)
    this.state.id = to.params.id as string
    this.state.title = to.params.title as string

    messageStore.getMessages(to.params.id as string, to.params.title as string, 10, "",
      (title: string, messages: Message[]) => {
        console.log("Channel:beforeRouteUpdate()", messages, title)
        this.setData(messages)
      })
    next();
  },
  updated: function() {
    // this.channelStore.markRead(this.state.id);
  },
  methods: {
    throbberImage(): string {
      return process.env.VUE_APP_BACKEND + '/images/' + this.state.throbberId
    },
    newPosting() {
      console.log("creating new posting for channel: " + channelStore.getters.selectedChannelId);
      (this.$refs.postingmodal! as any).newPosting(channelStore.getters.selectedChannelId)
    },
    back() {
      channelStore.setSelectedPostId('00000000-0000-0000-0000-000000000000')
      // this.state.selectedId = '00000000-0000-0000-0000-000000000000'
      if (this.editor) {
        (this.editor as any).editor.__quill.root.setAttribute('data-placeholder', this.t('editorPlaceholder1'))
      }
      
    },
    textChange( delta: Delta, oldContents: Delta ) {
      // console.log("textChange", (this.editor as any).getContents())
      if ((this.editor as any).getContents() === "<p><br></p>") {
        // this.state.throbber = false
        // possibly send a throbber-off signal to remote?
      } else {
        messageStore.typing(channelStore.getters.selectedChannelId, localStorage.getItem("uid") as string, localStorage.getItem("id") as string)
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        try {

          const el = this.$refs.chscroller;

          if (el) {
            console.log("scroll..", (this.editor as any).editor.__quill.root);
            (this.editor as any).editor.__quill.root.focus();
            
            // Use el.scrollIntoView() to instantly scroll to the element
            (el as HTMLBaseElement).scrollTo({top: (el as HTMLBaseElement).scrollHeight, behavior: 'smooth'})
            // (el as HTMLBaseElement).scrollIntoView({block: "nearest", inline: "nearest"});
            // (el as HTMLBaseElement).scrollTop = (el as HTMLBaseElement).scrollHeight
          }
          
          // (this.chscroller! as HTMLBaseElement).scrollTop = (this.chscroller! as HTMLBaseElement).scrollHeight
        } catch (e) {
          console.log("Err")
        }
      }, 200)
    },

    /*onScroll() {
      // console.log("scroll?")
      let scrollTop = (this.chscroller! as HTMLBaseElement).scrollTop;
      let offsetHeight = (this.chscroller! as HTMLBaseElement).offsetHeight;
        
      if (scrollTop < 50 && scrollTop < this.lastScrollTop) {
        console.log(" top: " + scrollTop + ", height: " + offsetHeight)
        // console.log("fetch more!")
        this.fetchOlder();
      }
      this.lastScrollTop = scrollTop
    },
    fetchOlder() {
      if (!this.state.fetching) {
        console.log("fetching older messages: ")
        this.state.fetching = true
        // this.state.id = to.params.id as string
        // this.state.title = to.params.title as string
        // need to turn off fetching older
        let lastTimestamp = ""
        if (this.state.messages.length > 0) {
          // console.log("last timestamp: " + this.state.messages[this.state.messages.length-1].createdAt)
          console.log("last timestamp: " + this.state.messages[0].createdAt)
          lastTimestamp = this.state.messages[0].createdAt
        }

        messageStore.getMessages(this.state.id, this.state.title, 10, lastTimestamp,
          (title: string, messages: Message[]) => {
            console.log("Channel:beforeRouteUpdate()", messages, title)
            this.appendData(messages)
            this.state.fetching = false
            // turn back on fetching older  
          })
      } else {
        console.log(".")
      }
    },*/

    onmessage(proto: Protocol) {
      console.log("ChannelPane:onmessage()", proto)

      if (this.state.id !== proto.id) {
        console.log("ignoring id: " + proto.id + " our id: " + this.state.id)
        return
      }
      
      // function findParent(parent: Message, message: Message) {
      //   return parent.id === message.inReplyToId;
      // }
      // this.state.messages.find(findParent)

      // console.log("checking: ", proto)
      if (proto.type === 'msg') {
        console.log("post")
        this.state.throbber = false
        const message = proto.message
        if (message.messageType === 'reply') {
          console.log("reply")
          const parent = this.state.messages.find(e => e.id === message.inReplyToId)
          if (parent) {
            if (!parent.replies) {
              parent.replies = new Array<Message>()
            }
            parent.replies.push(message)
          } else {
            if (!this.state.messages) {
              this.state.messages = new Array<Message>()
            }
            this.state.messages.push(message)
          }
        } else {
          if (!this.state.messages) {
            console.log("first message")
            this.state.messages = new Array<Message>()
          }
          this.insertAppend(message, this.state.messages)
        }

        // Auto scroll to the bottom
        setTimeout(() => {
          // console.log("scrollTop 2");
          try {
            // (this.chscroller! as HTMLBaseElement).scrollTop = (this.chscroller! as HTMLBaseElement).scrollHeight
          } catch (e) {
            console.log("Err")
          }
        }, 200)
      } else if (proto.throbber) {
        console.log("throbber;", proto)
        this.showThrobber(proto.throbber.userId)
      }      
    },
    showThrobber(userId: string) {
      // console.log("adding throbber for userId: " + userId)
      if (userId !== localStorage.getItem("id")) {
        this.state.throbber = true
        this.state.throbberId = userId
        setTimeout(() => {
          console.log("clearing throbber")
          this.state.throbber = false
        }, 20000)
      }
    },
    appendData(messages: Message[]) {
      // let tmp = 
      this.state.messages = messages.concat(this.state.messages)
      // messages.push(tmp)
    },
    setData (/* err: Error, */ messages: Message[]) {
      // if (err) {
      //   this.state.error = err.toString()
      // } else {
        this.state.messages = messages
      
      if (messages.length > 0) { 
        console.log("last timestamp: " + messages[messages.length-1].createdAt)
      }
      // need to extract last time stamp for further fetching
      
      // Auto scroll to the bottom on view
      setTimeout(() => {
        // console.log("scrollTop 1");
        try {
          // (this.chscroller! as HTMLBaseElement).scrollTop = (this.chscroller! as HTMLBaseElement).scrollHeight
        } catch (e) { console.log("Err") }
      }, 800)
      
    },
    send1() {
      console.log("send1: ", (this.editor as any).getContents())
      this.sendMessage((this.editor as any).getContents(), this.state.id);
      (this.editor as any).setContents("")
    },

    selectMessage(id: string) {
      console.log("selected: ", this.$refs['collapse' + id])
      // if (this.$refs['collapse' + id] && (this.$refs['collapse' + id] as HTMLUListElement[])[0]) {
      //   (this.$refs['collapse' + id] as HTMLUListElement[])[0]?.classList.remove('collapse');
      // }
      // console.log('collapse' + id)
      // this.editor.root.dataset.placeholder = 'Your new placeholder'

      // console.log("editor:", (vm as any).editor.editor.__quill.root);
      if (this.editor) {
        (this.editor as any).editor.__quill.root.setAttribute('data-placeholder', this.t('editorPlaceholder2'))
      }
      
      channelStore.setSelectedPostId(id)
      messageStore.markRead(id, this.state.id)
      this.scrollToBottom()
      // this.state.selectedId = this.state.selectedId === id ? '00000000-0000-0000-0000-000000000000' : id;
    },

    sendMessage(message: string, channelId: string) {
      messageStore.send(channelId, channelStore.getters.selectedPostId, localStorage.getItem("uid") as string, 
      localStorage.getItem("id") as string, message)
    },
    editPosting(id: string) {
      console.log("edit posting: " + id);
      (this.$refs.postingmodal! as any).editPosting(id)
    },
    showProfile(id: string) {
      console.log("22 show profile: " + id);
      (this.$refs.profileSheet! as any).show(id)
    },
    insertAppend(m: Message, messages: Message[]) {
      var i;
      for (i = 0; i < messages.length; i++) {
        if (messages[i].id === m.id) {
          console.log("updating existing message: ", messages[i], m)
          // messages[i] = m
          messages[i].postingType = m.postingType
          messages[i].tags = m.tags
          messages[i].title = m.title
          messages[i].message = m.message
          messages[i].externalUrl = m.externalUrl
          return
        }
      }
      console.log("adding new message: ", m)
      this.state.messages.unshift(m)
      // this.state.messages.push(m)
    },
  },
})

