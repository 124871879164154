
import { ref, defineComponent, reactive } from 'vue'
import router from '@/router'
import axios from 'axios'
import VaxEditor from '@/components/profile/VaxEditor.vue'
import userStore from '@/stores/user'
import { VOnboardingWrapper, VOnboardingStep, useVOnboarding } from 'v-onboarding'
import {useI18n} from "vue-i18n";
import Close from '@/assets/img/icons/close.svg'


const messages = {
  "en": {
    step1title: "Add Profile Image",
    step1description: "Click on the image to select your profile image or company logo",
    finish: "OK",
    cancel: "Cancel",
    save: "Save",
  },
  "nb_NO": {
    step1title: "Legg inn profilbilde",
    step1description: "Trykk på ikonet for å velge profilbilde eller bedriftslogo",
    finish: "OK",
    cancel: "Avbryt",
    save: "Lagre",
  }
}

// based on https://github.com/fpluquet/vue-avatar-editor
// 
// altertnative upload frameworks; 
// 
// https://github.com/andymark-by/vue3-image-uploader (comprehensive)
// https://www.vuescript.com/drag-drop-image-uploader/ 
// 
export default defineComponent({
  components: { VaxEditor, VOnboardingWrapper, VOnboardingStep, Close, },
  setup () {
    const vueavatar = ref(null)
    const image = ref(null)

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const wrapper = ref(null)
    const options = { labels: { finishButton: t('finish') } }
    const { start, goToStep, finish } = useVOnboarding(wrapper)
    const steps = [
      { attachTo: { element: '#avatarEditorCanvas' },
        content: {
          title: t('step1title'), description: t('step1description')
        }
      }
    ]
    
    return { userStore, vueavatar, image, wrapper, steps, options, start, t,  }
  },
  data () {
    return {
      rotation: 0,
      scale: 1,
      imageUrl: process.env.VUE_APP_BACKEND + '/images/' + userStore.getters.id
    };
  },
  mounted() {
    // console.log("mounted vax")
    this.imageUrl =  process.env.VUE_APP_BACKEND + '/images/' + userStore.getters.id + '?rnd=' + Math.floor(Math.random() * 256)
    // (this.vueavatar as any).$forceUpdate()
    
    // do we have an image?
    let that = this
    axios.head(this.imageUrl, {}).then(function (res) {
      console.log("image check returns; ", res)
      if (res.status === 404) {
        // start onboardingh
        console.log("starting onboarding")
        that.start()
      } else {
        console.log("no onboarding")        
      }
    }).catch((e: Error) => {
      console.log("image check returns; ", e)
      console.log(e)
    })
    
    /*
    let self = this

    const load = AppSettings.BACKEND + '/images/' + userStore.getters.id
    axios({ method: 'get', url: load, responseType: 'blob' })
      .then(function(resp) {
        console.log("response", resp)
        self.imageUrl = URL.createObjectURL(resp.data)
        // const mimeType = resp.headers['content-type'].toLowerCase();
        // const imgBase64 = new Blob(resp.data, 'binary').toString('base64');
        // (this as any).imageUrl = 'data:' + mimeType + ';base64,' + imgBase64;
        // el.src = 'data:' + mimeType + ';base64,' + imgBase64;
      }).catch((function() {
        console.error("unable to load image: " + load)
    }));*/
  },
  beforeUpdate() {
    // console.log("profile before update")
    // this.imageUrl = AppSettings.BACKEND + '/images/' + userStore.getters.id + '?rnd=' + Math.floor(Math.random() * 256)
  },
  methods: {
    onSelectFile (files: any) {
      console.log("selected")
      this.$emit('select-file', files);
    },
    saveClicked () {
      console.log("uploading image");
      // var img = (this.vueavatar as any).getImageScaled();
      // (this.image as any).src = img.toDataURL();

      (this.vueavatar as any).getImageScaled().toBlob(async (blob: Blob | null) => {
          const formData = new FormData()
          formData.append('file', blob as Blob, 'tmp.jpeg')

          console.log("headers: ", axios)
          console.log("auth: ", axios.defaults.headers.common['Authorization'])

          axios.post(process.env.VUE_APP_BACKEND + '/api/v1/user/image', formData, {
          }).then((resp) => {
            if (resp.status === 201) {
              console.log('File uploaded')
              router.push('/me')
            } else {
              console.error('upload failed')
            }
          });
        },
        "image/jpeg", // mime type,
        "75")  // jpeg quality
    }
  },
  onImageReady () {
    console.log("onImageReady")
    this.scale = 1
    this.rotation = 0
  }
})
