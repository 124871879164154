import { computed, inject, reactive, readonly } from 'vue'
import {Channel, ChannelList, Message} from '@/types'
import axios from "axios";

// const backend = inject('backend')

const state: {
  loaded: boolean
  channels: Array<Channel>
  allChannels: Array<Channel>
  subscriptions: Array<String>
  suggestions: Array<Channel>
  pending: Array<Channel>
  error: string
  selectedChannelId: string
  selectedPostId: string
  ws: WebSocket
} = reactive({
  loaded: false,
  channels: [],
  allChannels: [],
  subscriptions: [],
  suggestions: [],
  pending: [],
  error: '',
  selectedChannelId: '00000000-0000-0000-0000-000000000000',
  selectedPostId: '00000000-0000-0000-0000-000000000000',
  ws: {} as WebSocket
})

const getters = reactive({
  isLoaded: computed(() => state.loaded),
  channels: computed(() => state.channels),
  allChannels: computed(() => state.allChannels),
  subscriptions: computed(() => state.subscriptions),
  pending: computed(() => state.pending),
  suggestedChannels: computed(() => state.suggestions),
  selectedChannelId: computed(() => state.selectedChannelId),
  selectedPostId: computed(() => state.selectedPostId),
  isChannelSelected: computed(() => state.selectedChannelId !== '00000000-0000-0000-0000-000000000000'),
  isPostSelected: computed(() => state.selectedPostId !== '00000000-0000-0000-0000-000000000000'),
  // isLoggedIn: computed(() => state.token !== ''),
  // authToken: computed( () => state.token )
  ws: computed( () => state.ws)
})

// start timer that checks if state is cleared
// Calling updateLine() function after 5 mins
let checkTimer = setTimeout(checkState,5000); // every five seconds


const actions = {
  setSelectedChannelId(channelId: string) {
    state.selectedChannelId = channelId;
  },
  setSelectedPostId(channelId: string) {
    state.selectedPostId = channelId;
  },
  async getChannels() {
    console.log("will fetch channels..");

    // const requestOptions = { method: "GET", headers: { 'Authorization': 'Bearer ' + userStore.getters.authToken, "Content-Type": "application/json" } }
    // const request = await fetch(AppSettings.BACKEND + '/api/v1/channels?messages=true', requestOptions)
    //
    // return await request.json()

    axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels?messages=true')
      .then(resp => {
        console.log("channel.ts:getChannels() my channels:", resp.data.items);
        state.channels = resp.data.items
        state.loaded = true
        return resp.data.items
      })
      .catch(err => {
        console.log("error; ", err)
      })
      .finally(() => {
        axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels?all=true')
          .then(resp => {
            console.log("channel.ts:getChannels() all channels:", resp.data.items);
            state.allChannels = resp.data.items
            return resp.data.items
          })
          .catch(err => {
            console.log("error; ", err)
          })
        axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels/subscriptions')
          .then(resp => {
            console.log("channel.ts:getChannels() subscriptions:", resp.data);
            state.subscriptions = resp.data
            return resp.data
          })
          .catch(err => {
            console.log("error; ", err)
          })
        axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels?pending=true')
          .then(resp => {
            console.log("pending:", resp.data);
            state.pending = resp.data.items
            return resp.data
          })
          .catch(err => {
            console.log("error; ", err)
          })
        axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels/suggestions')
          .then(resp => {
            console.log("channel.ts:getChannels() suggestions:", resp.data);
            state.suggestions = resp.data.items
            return resp.data.items
          })
          .catch(err => {
            console.log("error; ", err)
          })
      })
    
  },
  
  /*async getChannels2() {
    fetch(AppSettings.BACKEND + '/api/v1/channels?messages=true')
      .then((response) => response.json())
      .then((responseData) => {
        state.channels = responseData
        console.log("found channels: " + JSON.stringify(responseData))
      })
  }*/
  async getChannel(channelId: string): Promise<any> {
    console.log("loading channel for id: " + channelId);

    return await axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels/'+channelId)
      .then(resp => {
        console.log("channel.ts:getChannel()", resp.data)
        return resp.data
      })
      .catch(err => {
        console.log("error; ", err)
        return
      })
  },

  async searchChannels(query: string): Promise<Channel[]> {
    console.log("searching for channels; ", query)

    return axios.get(process.env.VUE_APP_BACKEND + `/api/v1/channels/search?query=${query}`)
      .then(resp => {
        console.log("response; ", resp)
        return resp.data;
      })
      .catch(err => {
        console.error("unable to perform channel search")
        return null;
      })
    // return null;
  },
  
  /*async markRead(channelId: string) {
    console.log("updating read timestamp for channel id: " + channelId)
    const resp = await axios.post(process.env.VUE_APP_BACKEND + '/api/v1/channels/'+channelId+"/read", {})
      .then(resp => {
        console.log("channel.ts:markRead()", resp.data)
      })
      .catch(err => {
        console.log("error; ", err)
      })
    
  },*/

  async subscribe(userId: string, channelId: string, subscribe: boolean): Promise<any> {
    console.log("subscribing to channel id: " + channelId + "; " + subscribe + " for userId: " + userId)
    if (!userId || userId === "") {
      console.log("replacing empty user id with zeros;", userId)
      userId = "00000000-0000-0000-0000-000000000000"
    }
    const resp = await axios.post(process.env.VUE_APP_BACKEND + '/api/v1/channels/'+channelId+"/subscribe", {
      subscribe: subscribe,
      userId: userId })
      .then(resp => {
        console.log("channel.ts:subscribe()", resp.data)

          this.getChannels()
        
          /*axios.get(process.env.VUE_APP_BACKEND + '/api/v1/channels/subscriptions')
          .then(resp => {
            console.log("channel.ts:getChannels() subscriptions:", resp.data);
            state.subscriptions = resp.data
            return resp.data
          })
          .catch(err => {
            console.log("error; ", err)
          })*/
        
      })
      .catch(err => {
        console.log("error; ", err)
      })
  },
  async storeChannel(name: string, description: string, url: string, open: boolean, promote: boolean, id: string): Promise<any> {
    console.log("saving group: " + name + ", open: " + open, ", id: " + id)

    if (id === "") { id = "new" }
    const resp = await axios.post(process.env.VUE_APP_BACKEND + `/api/v1/channels/${id}`,{ 
      title: name, description: description, open: open, promote: promote, url: url })
      .then(resp => {
        console.log("channel.ts:storeChannel()", resp.data);
        // state.channels = resp.data
        return resp.data
      })
      .catch(err => {
        console.log("error; ", err)
        return null
      })
    return resp
  },
}

export default { state, getters, ...actions }

function checkState() {
  // console.log("checking state; ", getters.isLoaded)
  if (!getters.isLoaded && localStorage.getItem("id")) {
    console.log("state is not loaded, loading")
    actions.getChannels()
  }
  checkTimer = setTimeout(checkState,5000); // every five seconds
}
