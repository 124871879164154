
import { defineComponent, onMounted, ref } from 'vue'
import NavigationBar from '@/components/NavigationBar.vue'
import OnlineHelp from '@/components/OnlineHelp.vue'
import LocalToast from '@/components/LocalToast.vue'

import userStore from '@/stores/user'

export default defineComponent({
  components: { NavigationBar, OnlineHelp, LocalToast },
  setup() {
    onMounted(userStore.getUser2)

    const view = ref(false)

    return { userStore, view }
  },
  watch: {
    vueConnectivity: {
      deep: true,
      handler(oldValue, newValue) {
        console.log('Watcher from App - Network:', newValue.network, ' - Internet:', newValue.internet);
      }
    }
  },
  // computed: {
  //   connectivity() {
  //     return (this.$root as any).vueConnectivity
  //   }
  // },  
  methods: {
    search(val: any) {
      console.log("val", val)
    },
    enter() {
      console.log('enter');
    },
    leave() {
      console.log('leave');
    },
  }
})
