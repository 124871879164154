import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-title" }
const _hoisted_2 = {
  key: 0,
  class: "scroll-area"
}
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "links" }
const _hoisted_5 = { class: "l flex-grow-1 text-start mt-1" }
const _hoisted_6 = { class: "l flex-grow-1 text-start" }
const _hoisted_7 = {
  class: "d-flex disabled",
  disabled: "true"
}
const _hoisted_8 = { class: "l flex-grow-1 text-start" }
const _hoisted_9 = { class: "d-flex explanation" }
const _hoisted_10 = { class: "flex-grow-1" }
const _hoisted_11 = {
  key: 1,
  class: "scroll-area"
}
const _hoisted_12 = {
  key: 2,
  class: "scroll-area"
}
const _hoisted_13 = {
  key: 3,
  class: "scroll-area"
}
const _hoisted_14 = {
  key: 0,
  class: "wr my-5"
}
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_Group = _resolveComponent("Group")!
  const _component_ArrowForward = _resolveComponent("ArrowForward")!
  const _component_Mail = _resolveComponent("Mail")!
  const _component_Alarm = _resolveComponent("Alarm")!
  const _component_GroupMembers = _resolveComponent("GroupMembers")!
  const _component_SearchMembers = _resolveComponent("SearchMembers")!
  const _component_Logout = _resolveComponent("Logout")!
  const _component_BootstrapModal = _resolveComponent("BootstrapModal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createVNode(_component_BootstrapModal, null, _createSlots({
      header: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.currentPane === '' ? _ctx.t('aboutgroup') : '') + " " + _toDisplayString(_ctx.currentPane === 'members' ? (_ctx.t('members') + _ctx.state.group.title) : '') + " " + _toDisplayString(_ctx.currentPane === 'invite' ? _ctx.t('invite') : '') + " " + _toDisplayString(_ctx.currentPane === 'notifications' ? _ctx.t('notifications') : ''), 1)
      ]),
      body: _withCtx(() => [
        (_ctx.currentPane === '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.state.group.title), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.state.group.description), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.currentPane), 1),
                _createElementVNode("ul", _hoisted_4, [
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "d-flex",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.showPane($event,'members')), ["prevent"]))
                    }, [
                      _createVNode(_component_Group, { class: "group indent" }),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('members')), 1),
                      _createVNode(_component_ArrowForward, { class: "chevron-right" })
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "d-flex",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.showPane($event,'invite')), ["prevent"]))
                    }, [
                      _createVNode(_component_Mail, { class: "mail indent" }),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('invite')), 1),
                      _createVNode(_component_ArrowForward, { class: "chevron-right" })
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", _hoisted_7, [
                      _createVNode(_component_Alarm, { class: "alarm indent" }),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('notifications')), 1),
                      _createVNode(_component_ArrowForward, { class: "chevron-right" })
                    ])
                  ])
                ]),
                _createElementVNode("h3", null, _toDisplayString(_ctx.t('archiveHeading')), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('archiveExplanation')), 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "btn btn-rounded btn-outline-dark disabled",
                      disabled: "disabled",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.archive()), ["stop"]))
                    }, _toDisplayString(_ctx.t('archive')), 1)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentPane === 'members')
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_GroupMembers, {
                id: _ctx.state.group.id
              }, null, 8, ["id"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentPane === 'invite')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_SearchMembers, {
                id: _ctx.state.group.id
              }, null, 8, ["id"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentPane === 'notifications')
          ? (_openBlock(), _createElementBlock("div", _hoisted_13))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        (_ctx.currentPane === '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("button", {
                class: "d-block mx-auto btn btn-rounded btn-dark",
                onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.leave()), ["stop"]))
              }, [
                _createVNode(_component_Logout, { class: "invert logout" }),
                _createTextVNode(" " + _toDisplayString(_ctx.t('leave')), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentPane !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_15))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.currentPane !== '')
        ? {
            name: "back",
            fn: _withCtx(() => [
              _createElementVNode("a", {
                class: "btn-back",
                href: "#",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showPane($event,'')), ["prevent"]))
              }, [
                _createVNode(_component_ArrowBack, { class: "arrowback" })
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1024)
  ]))
}