
/* eslint-disable vue/no-reserved-component-names */
import {defineComponent, reactive} from 'vue'
import {useI18n} from 'vue-i18n'
import {Field, Form} from 'vee-validate'
import * as Yup from 'yup'
import {useRouter} from "vue-router"
import userStore from "@/stores/user"
import Forward from '@/assets/img/icons/arrow-forward.svg'

// https://kazupon.github.io/vue-i18n/guide/messages.html
const messages = {
  "en": {
    heading: "Create Account",
    q: "Already have an account?",
    signin: "Log in",
    password: "password",
    "repeat-password": "repeat passord",
    email: "email address",
    action: "create account",
    terms: "accept terms & conditions",
    register: "Register",
    required: "Registration at Heldel.no currently required using an email address with a whitelisted domain.",
  },
  "nb_NO": {
    heading: "Opprett brukerkonto",
    q: "Allerede registrert?",
    signin: "Logg inn",
    password: "passord",
    "repeat-password": "gjenta passord",
    email: "e-post adresse",
    account: "opprett konto",
    // terms: "jeg godtar brukervilkårene, <a target='_blank' href='/terms'>les mer</a>"
    terms: "jeg godtar brukervilkårene",
    register: "Register",
    required: "Registrering på Heldel krever foreløbig en e-post adresse fra en forhåndsgodkjent liste.",
  }
}

export default defineComponent({
  components: { Form, Field, Forward },
  setup() {
    // https://jasonwatmore.com/post/2020/10/01/vue-3-veevalidate-form-validation-example
    const schema = Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      password2: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
      acceptTerms: Yup.boolean()
        .required('Accept Ts & Cs is required')
    });

    const form = reactive({
      email : '',
      password : '',
      password2 : '',
      acceptTerms: false
    })
    const router = useRouter()

    const onSubmit= () => {
      console.log("onSubmit()")
      const e = form.email, p = form.password;
      console.log(`register: ${e}`);
      userStore.register(e, p)
        .then(() => {
          form.email = ''
          form.password = ''
          form.password2 = ''
          form.acceptTerms = false
          router.push('/signup/done') })
        .catch((err) => {
          console.error("unable to registrer new account;", err)
          router.push('/signup/failed') })
    }
    
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    return { t, schema, form, onSubmit }
  }
})
