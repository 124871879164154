
/* eslint-disable vue/no-unused-components */
import { defineComponent, onMounted, reactive, ref } from 'vue'
import GroupBubble from '@/components/groups/GroupBubble.vue'
import channelStore from '@/stores/channel'
import chatStore from '@/stores/chat'
import { Magnify, Pound, Pencil, CheckboxIntermediate } from 'mdue'
import { useI18n } from "vue-i18n";
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import {Channel, Message, User} from "@/types";
import ProfileBubble from '@/components/profile/ProfileBubble.vue'
import ExcerptBubble from './ExcerptBubble.vue'

const messages = {
  "en": {
    created: "created",
    chatlist: "Private Messages",
    mymessages: "My Messages",
    search: "Search in messages"
  },
  "nb_NO": {
    created: "opprettet",
    chatlist: "Private meldinger",
    mymessages: "Mine meldinger",
    search: "Søk i meldinger"
  }
}

export default defineComponent({
  components: { Magnify, Pencil, GroupBubble, BreadcrumbsBar, ProfileBubble, ExcerptBubble },
  data() {
    return {
    }
  },
  setup() {

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const state = reactive({
      messages: [] as Message[],
    })
    
    const { getters, getChats } = chatStore
    
    return { state, getters, t, getChats }
  },
  mounted() {
  
    // console.log("Fetching channels... " + this.$options);
    console.log("logging this: ")


    // https://learnvue.co/2020/12/how-to-use-lifecycle-hooks-in-vue3/
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
    let response = chatStore.getChats()
      .then((resp) => {
        console.log("got channels, ready: " + JSON.stringify(response), resp);
        this.state.messages = resp
      })
      .catch((resp) => {
        console.error("unable to fetch messages list", resp)
      })
  
  },
  methods: {
  },
})
