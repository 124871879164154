import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61cf2e0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-area channels-wrapper" }
const _hoisted_2 = { class: "search-wrapper m-3" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "nav" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-link active" }
const _hoisted_7 = {
  class: "tab-content py-3 my-3",
  id: "channel-content"
}
const _hoisted_8 = {
  class: "tab-pane fade active show",
  id: "messages"
}
const _hoisted_9 = { class: "messages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsBar = _resolveComponent("BreadcrumbsBar")!
  const _component_Magnify = _resolveComponent("Magnify")!
  const _component_ExcerptBubble = _resolveComponent("ExcerptBubble")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadcrumbsBar, {
      back: "/me",
      title: _ctx.t('chatlist'),
      image: "true"
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Magnify, { class: "icon" }),
        _createElementVNode("input", {
          type: "text",
          class: "form-control border-0 disabled",
          disabled: "true",
          placeholder: _ctx.t('search')
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.t('mymessages')) + ":", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("ul", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.messages, (msg) => {
              return (_openBlock(), _createElementBlock("li", {
                key: msg.id,
                class: "msg"
              }, [
                _createVNode(_component_ExcerptBubble, { msg: msg }, null, 8, ["msg"])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}