
import {defineComponent, reactive, ref} from 'vue'
import GroupBubble from '@/components/groups/GroupBubble.vue'
import channelStore from '@/stores/channel'
import {Close, Magnify, PlusCircleOutline,} from 'mdue'
import GroupModal from './GroupModal.vue'
import {useI18n} from "vue-i18n";
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import {Channel} from "@/types";

const messages = {
  "en": {
    created: "created",
    createnetwork: "Create Network",
    mynetworks: "My Networks",
    allnetworks: "All Networks",
    teaser: "Or something else?",
    search: "Search for networks",
    searchresults: "Search Results",
  },
  "nb_NO": {
    created: "opprettet",
    createnetwork: "opprett nettverk",
    mynetworks: "Mine nettverk",
    allnetworks: "Alle nettverk",
    teaser: "Eller noe annet?",
    search: "Søk etter nettverk",
    searchresults: "Søkeresultat",
  }
}

export default defineComponent({
  props: [ 'suggestions' ],
  components: { Magnify, GroupModal, GroupBubble, BreadcrumbsBar, PlusCircleOutline, Close },
  data() {
    return {
      // https://renatello.com/vue-js-tabs/
      activeItem: 'subscriptions' as String
    }
  },
  setup() {
    const query = ref()
    
    // const router = useRouter()
    // const state = reactive({
    //   checkedCategories: Array<String>
    // });
    // console.log("Fetching channels... " + this.$options);

    const state = reactive({
      channelResults: [] as Channel[],
    })
    
    const editGroupModal2 = ref(null)
    const mymodal = ref(null)
    
    /*onMounted(() => {

      // console.log("Fetching channels... " + this.$options);
      console.log("logging this: " + this)

      // https://learnvue.co/2020/12/how-to-use-lifecycle-hooks-in-vue3/
      // https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
      let response = getChannels()
        .then((resp) => {
          console.log("got channels, ready: " + JSON.stringify(response), resp)
        })
      
    })*/

    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    const { getters, getChannels } = channelStore
    return { state, getters, t, getChannels, editGroupModal2, mymodal, query }
  },
  mounted() {
  
    // console.log("Fetching channels... " + this.$options);
    console.log("logging this: ", this.$options)
  
    // https://learnvue.co/2020/12/how-to-use-lifecycle-hooks-in-vue3/
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
    let response = channelStore.getChannels()
      .then((resp) => {
        console.log("got channels, ready: " + JSON.stringify(response), resp)
      })
  
  },
  methods: {
    newChannel() {
      console.log("creating new group");
      (this.$refs.mymodal! as any).newGroup()
    },

    clearSearch(ev: Event) {
      // if (ev.currentTarget 
      console.log("clearing search input", ev);
      (this.query as HTMLInputElement).value = ""
      this.state.channelResults = []
      this.setActive("subscriptions")
    },
    
    filterList() {
      console.log("searching for: " + (this.query as HTMLInputElement).value)

      channelStore.searchChannels((this.query as HTMLInputElement).value)
        .then(data => {
          console.log("data; ", data)
          this.state.channelResults = data
          this.setActive("searchResults")
        })
    },
    
    toggleSubscription: function(e: Event, channelId: string) {
      // this.$nextTick(() => {
      //   console.log(e, channelId)
      // })
      
      console.log("toggling subscription: " + channelId);
      channelStore.subscribe("00000000-0000-0000-0000-000000000000", channelId, (e.target as HTMLInputElement).checked)
    },
    editChannel(entryId: string) {
      console.log("edit group: " + entryId);
      (this.$refs.mymodal! as any).editGroup(entryId)
    },
    isActive (menuItem: string) {
      return this.activeItem === menuItem
    },
    setActive (menuItem: string) {
      this.activeItem = menuItem
    }    
    
  },
})
