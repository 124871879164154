import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b0eea6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "group-wrap" }
const _hoisted_2 = { class: "group-body w-100" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "d-flex flex-row" }
const _hoisted_5 = { class: "group-title flex-grow-1" }
const _hoisted_6 = { class: "group-meta flex-grow-0" }
const _hoisted_7 = {
  key: 0,
  class: ""
}
const _hoisted_8 = { class: "group-bubble clearfix" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "postcount" }
const _hoisted_11 = { class: "d-flex flex-row" }
const _hoisted_12 = { class: "avatars flex-grow-1" }
const _hoisted_13 = {
  key: 0,
  class: "avatar-img"
}
const _hoisted_14 = {
  key: 1,
  class: "avatar-img",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
  alt: ""
}
const _hoisted_15 = { class: "action flex-grow-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MinusCircleOutline = _resolveComponent("MinusCircleOutline")!
  const _component_PlusCircleOutline = _resolveComponent("PlusCircleOutline")!
  const _directive_auth_image = _resolveDirective("auth-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.channel.title), 1),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.date)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.t('created')) + " " + _toDisplayString(_ctx.dateTime2(_ctx.channel.createdAt)), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", {
            class: "description",
            innerHTML: _ctx.channel.description
          }, null, 8, _hoisted_9),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.channel.messages && _ctx.channel.messages.length > 0 ? (_ctx.channel.messages.length + ' ' + _ctx.t('posts')) : ''), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channel.participants, (participant) => {
            return (_openBlock(), _createElementBlock("span", {
              key: participant.id,
              class: "avatar-handle"
            }, [
              (participant.id !=='00000000-0000-0000-0000-000000000000')
                ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_13, null, 512)), [
                    [_directive_auth_image, _ctx.imagePrefix + participant.id]
                  ])
                : _createCommentVNode("", true),
              (participant.id ==='00000000-0000-0000-0000-000000000000')
                ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("a", {
            class: "ms-1 btn btn-secondary btn-rounded",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.subscribe($event, _ctx.channel.id, false)))
          }, [
            _createVNode(_component_MinusCircleOutline, { class: "pencil" }),
            _createTextVNode(" " + _toDisplayString(_ctx.t('decline')), 1)
          ]),
          _createElementVNode("a", {
            class: "ms-1 btn btn-primary btn-rounded",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.subscribe($event, _ctx.channel.id, true)))
          }, [
            _createVNode(_component_PlusCircleOutline, { class: "pencil" }),
            _createTextVNode(" " + _toDisplayString(_ctx.t('accept')), 1)
          ])
        ])
      ])
    ])
  ]))
}