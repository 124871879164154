import { createWebHistory, createRouter, createWebHashHistory } from "vue-router";
import HomePage from '@/components/HomePage.vue'
import AboutHeldel from '@/components/AboutHeldel.vue'
// import ProfileForm from '@/components/profile/ProfileForm.vue'
import SettingsForm from '@/components/profile/SettingsForm.vue'
import LoginForm from '@/components/account/LoginForm.vue'
import ForgotLoginForm from '@/components/account/ForgotLoginForm.vue'
import AvatarForm from '@/components/profile/AvatarForm.vue'
import SignupForm from '@/components/account/SignupForm.vue'
import SignupDone from '@/components/account/SignupDone.vue'
import SignupFailed from '@/components/account/SignupFailed.vue'
import GroupSubscriptions from '@/components/groups/GroupSubscriptions.vue'
import VerifySignup from '@/components/account/VerifySignup.vue'
import NotFound from '@/components/404Error.vue'
import GroupChannels from '@/components/groups/GroupChannels.vue'
import ChannelPane from '@/components/groups/ChannelPane.vue'
import HeldelProfile from '@/components/profile/HeldelProfile.vue'
import TermsPage from '@/components/TermsPage.vue'
import GroupMembers from '@/components/groups/GroupMembers.vue'
import ViewProfile from '@/components/profile/ViewProfile.vue'
import GroupInfo3 from '@/components/groups/GroupInfo3.vue'
import MessagingList from '@/components/messaging/MessagingList.vue'
import MessagingPane from '@/components/messaging/MessagingPane.vue'
import userStore from '@/stores/user'
import channelStore from '@/stores/channel'
import UsersList from '@/components/account/UsersList.vue'
import WelcomeHeldel from '@/components/WelcomeHeldel.vue'
// https://router.vuejs.org/guide/#html

// auth: https://stackoverflow.com/questions/46262094/checking-auth-token-valid-before-route-enter-in-vue-router

// getting transitions working with routes 
// https://stackblitz.com/edit/vue-rwnvsv?file=src%2FApp.vue
// https://stackoverflow.com/questions/70002026/vue-nested-router-view-leave-transition-doesnt-run

const HomeDefault = {
  template: `<p>Gruppe ikke valgt</p>`
}

const routes = [
  { path: '/', name: 'home', component: HomePage, meta: { requiresAuth: true }, },
  { path: '/welcome', name: 'welcome', component: WelcomeHeldel, },
  { path: "/:catchAll(.*)", component: NotFound, },

  { path: '/user/:id', name: 'userProfile', component: ViewProfile, meta: { requiresAuth: true, props: true, } },
  { path: '/me', name: 'profile', component: HeldelProfile, meta: { requiresAuth: true, } },
  // { path: '/me/form', name: 'profileForm', component: ProfileForm, meta: { requiresAuth: true } },
  { path: '/me/settings', name: 'settingsForm', component: SettingsForm, meta: { requiresAuth: true } },
  { path: '/me/avatar', name: 'avatar', component: AvatarForm, meta: { requiresAuth: true } },
  { path: '/subscriptions', name: 'subscriptions', component: GroupSubscriptions, meta: { requiresAuth: true, } },
  // { path: '/subscriptions/:suggestions', name: 'suggestions', component: GroupSubscriptions, meta: { requiresAuth: true } },

  { path: '/messages', name: 'messagingList', component: MessagingList, meta: { requiresAuth: true, } },
  { path: '/messages/:id', name: 'messagingPane', component: MessagingPane, meta: { requiresAuth: true, props: true, } },

  // https://router.vuejs.org/guide/essentials/passing-props.html#passing-props-to-route-components
  { path: '/channels/:id', name: 'channel', component: ChannelPane, props: true,
    children: [
      { path: 'info3', name: 'groupInfo3', component: GroupInfo3, props: true, meta: { showModal: true, }, },
      // { path: 'members', name: 'members', component: GroupMembers, props: true, },
    ],
  },
  { path: '/channels/:id/members', name: 'members', component: GroupMembers, props: true, },

  { path: '/users', name: 'usersList', component: UsersList, meta: { requiresAuth: true } },
  
  { path: '/login', name: 'login', component: LoginForm, meta: { hideNavigation: true } },
  { path: '/login/forgot', name: 'forgot', component: ForgotLoginForm, meta: { hideNavigation: true } },
  { path: '/signup', name: 'signup', component: SignupForm, meta: { hideNavigation: true } },
  { path: '/signup/done', name: 'signup-done', component: SignupDone, meta: { hideNavigation: true } },
  { path: '/signup/failed', name: 'signup-failed', component: SignupFailed, meta: { hideNavigation: true } },
  {
    // path: '/signup/verify/:token?email=:email',
    path: '/signup/verify/:token',
    name: 'verify',
    component: VerifySignup, meta: { hideNavigation: true }
  },
  { path: '/about', name: 'about', component: AboutHeldel, },
  { path: '/terms', name: 'terms', component: TermsPage }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// https://router.vuejs.org/guide/advanced/navigation-guards.html

router.beforeEach((to, from, next) => {
  console.log("route: ", to)
  if (to.path === "/" && !userStore.getters.isLoggedIn) {
    console.log("show welcome page")
    next({ path: '/welcome', })
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!userStore.getters.isLoggedIn) {
      console.log('redirecting to login')
      next({
        path: '/login',
        params: {nextUrl: to.fullPath}
      })
      // check if time since last auth is longer than timeout period for token refresh
    // } else if (userStore.authrequired) {
      
    } else if (!channelStore.getters.isLoaded) {
      console.log("state not loaded, hang on..")
      channelStore.getChannels()
        .catch((err) => {
          console.error("unable to load state; ", err)
        })
        .finally(() => {
          next()
          })
      // next()
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresLoading)) {
    if (!userStore.getters.isLoggedIn) {
      console.log("state not loaded #2, hang on..")
      channelStore.getChannels()
    }
    next()
  } else {
    next()
  }
})

export default router;
