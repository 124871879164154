import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-84fdaf32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-area container-fluid" }
const _hoisted_2 = { class: "profile-wrap d-flex flex-row" }
const _hoisted_3 = {
  ref: "profileImage",
  class: "profile-image",
  src: ""
}
const _hoisted_4 = { class: "mt-3 ms-3" }
const _hoisted_5 = {
  key: 0,
  class: "form-floating mb-3"
}
const _hoisted_6 = { class: "wr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsBar = _resolveComponent("BreadcrumbsBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadcrumbsBar, {
      title: _ctx.t('profile')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", _hoisted_3, null, 512),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.user.givenName) + " " + _toDisplayString(_ctx.user.surName) + " " + _toDisplayString(_ctx.user.userId), 1)
      ]),
      (_ctx.user.description && _ctx.user.description.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t('about')) + ": " + _toDisplayString(_ctx.user.description), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "d-block mx-auto btn btn-rounded btn-dark",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.message && _ctx.message(...args)), ["stop"]))
        }, _toDisplayString(_ctx.t('sendmessage')), 1)
      ])
    ])
  ], 64))
}