import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "post" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "unread-wrap"
}
const _hoisted_4 = { class: "unread-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageBubble = _resolveComponent("MessageBubble")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MessageBubble, {
      selectedId: _ctx.selectedId,
      msg: _ctx.msg,
      "link-to-channel": false,
      showLike: _ctx.selectedId === _ctx.msg.id,
      onSelectMessage: _ctx.selectMessage,
      onShowProfile: _ctx.showProfile,
      onEditMessage: _ctx.editMessage,
      "reply-link": true
    }, null, 8, ["selectedId", "msg", "showLike", "onSelectMessage", "onShowProfile", "onEditMessage"]),
    (_ctx.msg.replies && _ctx.msg.replies.length > 0)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "wr-separator text-muted",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.selectMessage(_ctx.msg.id)), ["prevent"]))
        }, _toDisplayString(_ctx.msg.replies.length) + " " + _toDisplayString(_ctx.msg.replies.length > 1 ? _ctx.t('replies')  : _ctx.t('reply')), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("ul", {
      ref: `collapse${_ctx.msg.id}`,
      id: `collapse${_ctx.msg.id}`
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.msg.replies, (reply) => {
        return (_openBlock(), _createElementBlock("li", {
          key: reply.id,
          class: _normalizeClass(["message reply", {unread: _ctx.isUnread(_ctx.msg, reply)}])
        }, [
          (_ctx.isUnread(_ctx.msg, reply))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('unread')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_MessageBubble, {
            onShowProfile: _ctx.showProfile,
            showLike: true,
            selectedId: _ctx.selectedId,
            msg: reply
          }, null, 8, ["onShowProfile", "selectedId", "msg"])
        ], 2))
      }), 128))
    ], 8, _hoisted_2), [
      [_vShow, _ctx.msg.id == _ctx.selectedId]
    ])
  ]))
}