
/* eslint-disable vue/no-unused-components, vue/no-reserved-component-names, no-undef */
import {defineComponent, reactive, ref} from 'vue'
import messageStore from '@/stores/message'
import {Protocol} from '@/types'
import {Video, VideoOff} from 'mdue'
import {useI18n} from "vue-i18n"

// webRTC samples; https://webrtc.github.io/samples/
// play ring sound; https://stackoverflow.com/questions/61502464/webrtc-ring-sound-on-remote-peer-browser

// notifications;
// https://web-push-book.gauntface.com/demos/notification-examples/
// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
// https://web-push-book.gauntface.com/display-a-notification/#title-and-body-options

const messages = {
  "en": {
    "start": "Start", 
    "disabled": "Videocalls disabled",
  },
  "nb_NO": {
    "start": "start",
    "disabled": "Videosamtaler ikke aktivert",
  }
}

export default defineComponent({
  components: {
    Video, VideoOff,
  },
  props: {
    id: { type: String, default: '', },
  },
  watch: {
  },
  data() {
    return {
    }
  },
  setup(props, context) {
    console.log("VideoPane:Setup()")
    
    const local = ref<HTMLVideoElement>(null as any)
    const remote = ref<HTMLVideoElement>(null as any)

    const state = reactive({
      id: '',
    })

    var peerConnection = new RTCPeerConnection({"iceServers": [{"urls": "stun:stun.l.google.com:19302"}]})

    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    return { state, t, peerConnection, local, remote }
  },
  
  mounted() {
    console.log("mounted(), connecting with ", this.$props.id)
    messageStore.connect(localStorage.getItem("id") as string, this.onmessage)

    let startTime: number
    
    this.local.addEventListener('loadedmetadata', function() {
      console.log(`Local video videoWidth: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`)
    })

    this.remote.addEventListener('loadedmetadata', function() {
      console.log(`Remote video videoWidth: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`)
    })

    this.remote.addEventListener('resize', () => {
      console.log(`Remote video size changed to ${this.remote.videoWidth}x${this.remote.videoHeight} - Time since pageload ${performance.now().toFixed(0)}ms`)
      // We'll use the first onsize callback as an indication that video has started
      // playing out.
      if (startTime) {
        const elapsedTime = window.performance.now() - startTime
        console.log('Setup time: ' + elapsedTime.toFixed(3) + 'ms')
        // startTime = null
      }
    })



    // create a RTCPeerConnection object with the ICE servers configuration
    // this.peerConnection = new RTCPeerConnection({"iceServers": [{"urls": "stun:stun.l.google.com:19302"}]})

    // callback handlers for webRTC
    this.peerConnection.ontrack = evt => {
      console.log("ontrack", this.remote, evt)
      if (this.remote.srcObject === evt.streams[0]) {
        return
      }
      this.remote.srcObject = evt.streams[0]
      this.remote.muted = true
      this.remote.play()
    }
    
    this.peerConnection.onicecandidate = evt => {
      if (evt.candidate) {
        console.log("onicecandidate; ", evt.candidate)
        // ws.send(JSON.stringify({type: "candidate", ice: evt.candidate}))
        messageStore.candidate(this.$props.id, evt.candidate)
        // ws.send(JSON.stringify(peerConnection.localDescription))
      }
    }

    this.peerConnection.onconnectionstatechange = evt => {
      console.log("state changed;", evt)
    }

    
    /*peerConnection.on('close', () => {
      console.log("closing connection")
      showCallContent()
    })*/
    
    
    
  },
  computed: {
  },
  methods: {
    onmessage(proto: Protocol) {
      console.log("onmessage()", proto)

      if (proto.type === 'call') {
        console.log("1 got connection; ", proto.call.connection)
        let conn = JSON.parse(proto.call.connection) as RTCSessionDescriptionInit
        // console.log("got connection; ", conn)
        this.peerConnection.setRemoteDescription(conn).then(() => {
          console.log("2 creating answer")
          return this.peerConnection.createAnswer()
        }).then(answer => {
          console.log("3 setting local description from answer: ",  answer)
          return this.peerConnection.setLocalDescription(answer)
          // return null
        }).then(() => {
          console.log("4 sending localDescription; ", this.peerConnection.localDescription)
          messageStore.answer(this.$props.id, this.peerConnection.localDescription)
          //ws.send(JSON.stringify(peerConnection.localDescription))
        })
        
      } else if (proto.type === 'answer') {
        console.log("1x setting remoteDescription", proto.call.connection)
        if (proto.call.connection && proto.call.connection !== "null") {
          let conn = JSON.parse(proto.call.connection) as RTCSessionDescriptionInit
          // console.log("got answer; ", conn)
          this.peerConnection.setRemoteDescription(conn)
        } else {
          console.log("call connection is null")
        }
      } else if (proto.type === 'candidate') {
        let ice = JSON.parse(proto.call.connection) as RTCIceCandidate
        if (ice.candidate) {
          console.log("2x got candidate; ", ice)
          this.peerConnection.addIceCandidate(new RTCIceCandidate(ice))
        } else {
          console.log("finished ice candidate neg")
        }
      }      
    },
    start1() {
      console.log("call1: ")
      this.startVideo()
    },
    call1() {
      console.log("call1: ")
      this.makeCall()
    },
    end1() {
      console.log("end1:")
      this.peerConnection.close()
      console.log("ended")
    },

    startVideo() {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {

        // if (this.local) {
        console.log("enabling video")

        // streaming two streams per connection;
        // https://stackoverflow.com/questions/66243915/how-to-get-multiple-streams-from-webrtc-peerconnection
        
        this.local.srcObject = stream as MediaStream
        this.local.play()
          /*.then(() => {
          stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream))
          this.peerConnection.onnegotiationneeded = () => {

            // create an offer (SDP session description) with the RTCPeerConnection createOffer method
            this.peerConnection.createOffer().then(offer => {
              // console.log("created offer; ", offer)
              return this.peerConnection.setLocalDescription(offer)
            }).then(() => {
              console.log("send localDescription offer to remote", this.peerConnection.localDescription)
              messageStore.call(this.$props.id, this.peerConnection.localDescription, localStorage.getItem("id") as string)
              // ws.send(JSON.stringify(peerConnection.localDescription))
            })
          }
        })*/
      })
      console.log("done?")
    },
    
    makeCall() {
      navigator.mediaDevices.getUserMedia({ video: true,
/*        video: {
          frameRate: 24,
          width: {
            min: 480, ideal: 720, max: 1280
          },
          aspectRatio: 1.33333
        },*/
        audio: true
      }).then(stream => {
        
        // if (this.local) {
          console.log("making a call to recipientId;")

          // let element = document.getElementById("local_video")
          this.local.srcObject = stream as MediaStream
          this.local.play().then(() => {
            stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream))
            this.peerConnection.onnegotiationneeded = () => {

              // create an offer (SDP session description) with the RTCPeerConnection createOffer method
              this.peerConnection.createOffer().then(offer => {
                // console.log("created offer; ", offer)
                return this.peerConnection.setLocalDescription(offer)
              }).then(() => {
                console.log("send localDescription offer to remote", this.peerConnection.localDescription)
                messageStore.call(this.$props.id, this.peerConnection.localDescription)
                // ws.send(JSON.stringify(peerConnection.localDescription))
              })
            }
          })
        // }
      })
      console.log("done?")
    }
  }  
})
