import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d0a504a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-area channels-wrapper" }
const _hoisted_2 = { class: "search-wrapper my-3 mx-1" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "nav" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = {
  class: "tab-content py-3 my-3",
  id: "channel-content"
}
const _hoisted_9 = { class: "channel-subscriptions" }
const _hoisted_10 = { class: "channel-subscriptions" }
const _hoisted_11 = { class: "channel-subscriptions" }
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsBar = _resolveComponent("BreadcrumbsBar")!
  const _component_Close = _resolveComponent("Close")!
  const _component_Magnify = _resolveComponent("Magnify")!
  const _component_GroupBubble = _resolveComponent("GroupBubble")!
  const _component_PlusCircleOutline = _resolveComponent("PlusCircleOutline")!
  const _component_group_modal = _resolveComponent("group-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadcrumbsBar, {
      back: "/me",
      title: "Nettverk",
      image: "true"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.filterList && _ctx.filterList(...args)), ["prevent"])),
        class: "searchList"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", {
            class: "action",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearSearch && _ctx.clearSearch(...args)), ["prevent"]))
          }, [
            _createVNode(_component_Close, { class: "i" })
          ]),
          _createVNode(_component_Magnify, { class: "icon search-close" }),
          _createElementVNode("input", {
            type: "text",
            ref: "query",
            class: "form-control border-0",
            placeholder: _ctx.t('search')
          }, null, 8, _hoisted_3)
        ])
      ], 32),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link", { active: _ctx.isActive('subscriptions') }]),
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.setActive('subscriptions')), ["prevent"])),
            href: "#subscriptions"
          }, _toDisplayString(_ctx.t('mynetworks')), 3)
        ]),
        _createElementVNode("li", _hoisted_6, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link", { active: _ctx.isActive('suggestions') }]),
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.setActive('suggestions')), ["prevent"])),
            href: "#suggestions"
          }, _toDisplayString(_ctx.t('allnetworks')), 3)
        ]),
        _createElementVNode("li", _hoisted_7, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link", { active: _ctx.isActive('searchResults') }]),
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.setActive('searchResults')), ["prevent"])),
            href: "#searchResults"
          }, _toDisplayString(_ctx.t('searchresults')), 3)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: _normalizeClass(["tab-pane fade", { 'active show': _ctx.isActive('subscriptions') }]),
          id: "subscriptions"
        }, [
          _createElementVNode("ul", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getters.channels, (channel) => {
              return (_openBlock(), _createElementBlock("li", {
                key: channel.id,
                class: "channel channel-link"
              }, [
                _createVNode(_component_GroupBubble, {
                  link: true,
                  channel: channel
                }, null, 8, ["channel"])
              ]))
            }), 128))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-pane fade", { 'active show': _ctx.isActive('suggestions') }]),
          id: "suggestions"
        }, [
          _createElementVNode("ul", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getters.suggestedChannels, (channel) => {
              return (_openBlock(), _createElementBlock("li", {
                key: channel.id,
                class: "channel"
              }, [
                _createVNode(_component_GroupBubble, { channel: channel }, null, 8, ["channel"])
              ]))
            }), 128))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-pane fade", { 'active show': _ctx.isActive('searchResults') }]),
          id: "searchResults"
        }, [
          _createElementVNode("ul", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.channelResults, (channel) => {
              return (_openBlock(), _createElementBlock("li", {
                key: channel.id,
                class: "group"
              }, [
                _createVNode(_component_GroupBubble, {
                  link: true,
                  channel: channel
                }, null, 8, ["channel"])
              ]))
            }), 128))
          ])
        ], 2)
      ]),
      _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('teaser')), 1),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          class: "btn btn-rounded d-block btn-primary my-2 mx-auto text-nowrap",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.newChannel()))
        }, [
          _createVNode(_component_PlusCircleOutline, { class: "pencil" }),
          _createTextVNode(" " + _toDisplayString(_ctx.t('createnetwork')), 1)
        ])
      ])
    ]),
    _createVNode(_component_group_modal, { ref: "mymodal" }, null, 512)
  ]))
}