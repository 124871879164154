
/* eslint-disable vue/no-unused-components, vue/no-reserved-component-names */
import {defineComponent, reactive, ref} from 'vue'
import {User} from '@/types'
import userStore from '@/stores/user'
import {PlusCircleOutline} from "mdue";
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import {Field, Form} from "vee-validate";
import {useI18n} from "vue-i18n";
import VueBottomSheet from "@/components/external/VueBottomSheet.vue";
import setGlobalLocale from "@/main";


// public server key for push notifications
const publicServerKey = "BEMmXHBvC6Fsjlh1maMIILFO64mH7nOeQIJyH_dAZ7_1LloJGYq9cFLAK_QXzG50xUo8LzXtHz7KS5IHtlHvBZE"

const messages = {
  "en": {
    mysettings: "My Settings",
    contactme: "Let other users contact me on",
    add: "Add",
    cancel: "Cancel",
    phone: "Phone",
    email: "Email",
    notificationheading: "Use a notification popup",
    emailheading: "Send me email when:",
    browserNotify: "",
    newmessage: "New Message",
    newpost: "New Posting in Network",
    postreply: "Reply to My Postings",
    update: "Update",
    locale: "Language",
    locale_en: "English",
    locale_nb_NO: "Norsk",
    addkeyword: "Add Keyword",
    keyword: "Keyword",
    keywords: "Keywords",
  },
  "nb_NO": {
    mysettings: "Mine innstillinger",
    contactme: "La andre brukere kontakte meg på:",
    add: "Legg til",
    cancel: "Avbryt",
    phone: "Telefon",
    email: "E-post",
    notificationheading: "Bruk browser popup",
    emailheading: "Send meg e-post ved:",
    browserNotify: "",
    newmessage: "Ny melding",
    newpost: "Nytt innlegg i nettverk",
    postreply: "Svar på mine innlegg",
    update: "Oppdater",
    locale: "Språk",
    locale_en: "English",
    locale_nb_NO: "Norsk",
    addkeyword: "Legg til ",
    keyword: "stikkord",
    keywords: "Stikkord",
  }
}

// https://medium.com/js-dojo/manage-vue-i18n-with-typescript-958b2f69846f

// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API

export default defineComponent({
  components: { Form, Field, BreadcrumbsBar, PlusCircleOutline, VueBottomSheet, },
  data () {
    return { langs: ['nb_NO', 'en'] }
  },

  setup() {
    const form = reactive({
      contactPhone: false,
      contactEmail: false,
      notifyMessage: false,
      browserNotify: false,
      notifyPost: false,
      notifyReply: false,
      locale: 'nb_NO',
      keyword: '',
    })

    const interestSheet = ref<any>()

    // const keywords = [ "Veterinær", "Oslo", "Teknologi", "Psykologi", "Forskning", "Deltid", "Kultur", "Pedagogikk" ]

    const { t, locale, availableLocales } = useI18n({ messages: messages, useScope: 'global' })
    
    console.log("locale:", locale, "available:", availableLocales)
    return { form, userStore, t, locale, availableLocales, interestSheet, }
  },
  methods: {
    openInterestSheet() {
      this.interestSheet?.open();
    },
    closeInterestSheet() {
      this.interestSheet?.close();
    },
    addKeyword() {
      const keyword = this.form.keyword
      console.log("adding keyword: " + this.form.keyword)
      this.form.keyword = ''

      userStore.keyword(keyword, true)
        .catch((e: Error) => {
          console.error("unable to add keyword: ", keyword, e)
        })
      this.interestSheet?.close()
    },
    removeKeyword(keyword: string) {
      console.log("removing keyword: " + this.form.keyword)

      userStore.keyword(keyword, false)
        .catch((e: Error) => {
          console.error("unable to remove keyword: ", keyword, e)
        })
    },
    setProfile: function(profile: User) {
      console.log("profile: " + JSON.stringify(profile))
      this.form.contactEmail = profile.contactEmail
      this.form.contactPhone = profile.contactPhone
      this.form.notifyMessage = profile.notifyMessage
      this.form.notifyPost = profile.notifyPost
      this.form.notifyReply = profile.notifyReply
      this.form.locale = profile.locale
    },
    async fetchProfile() {
      await userStore.getUser2()
      .then((profile) => {
        console.log("getUser2 new:", profile)
        this.setProfile(profile)
      })
      .catch((e: Error) => {
        console.log(e);
      })
    },
    async change() {
      console.log("updating profile; ", this.form)
      let update = {
        contactPhone: this.form.contactPhone ? true : false,
        contactEmail: this.form.contactEmail ? true : false,
        notifyMessage: this.form.notifyMessage ? true : false,
        notifyPost: this.form.notifyPost ? true : false,
        notifyReply: this.form.notifyReply ? true : false,
        locale: this.form.locale
      }
      console.log("updating; ", update)
      userStore.updateSettings(update) 
      .then((response) => {
        console.log("updating user store with data: ", response)
      });
      console.log("setting current locale;", this.form.locale)
      this.locale = this.form.locale
      // setGlobalLocale(this.form.locale)
    },
    async notifyChange() {
      var message = " is getting a notification";
      var options = {
        body: message,
        icon: 'logo.png',
        tag: "tag",
      }
      // see also https://www.youtube.com/watch?v=DMStZnU1pPc
      // https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Re-engageable_Notifications_Push

      var n = new Notification('Website Says:', options);
      setTimeout(n.close.bind(n), 5000);
    },
    async notifyChange2() {
      if (this.form.browserNotify) {

        // init push notifications 

        if ('serviceWorker' in navigator && 'Notification' in window) {
          if (publicServerKey === undefined || publicServerKey == null) {
            console.error("Missing ServerKey!")
            return;
          }
          navigator.serviceWorker.register('web.push.worker.js').then(() => {
            console.log("Service worker registered");

            navigator.serviceWorker.ready.then(registration => {
              registration.pushManager.getSubscription().then((subscription: PushSubscription | null) => {
                if (subscription === undefined || subscription == null) {

                  navigator.serviceWorker.getRegistration().then((registration: ServiceWorkerRegistration | undefined) => {
                    if (registration) {
                      registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: URLB64ToUint8Array(publicServerKey) //This is a custom function for convert key in bytes array
                      }).then(subscription => {
                        const json = JSON.stringify(subscription.toJSON(), null, 2);
                        // Add Login to update subscription info to your Server
                        console.log("subscription details: " + json, subscription)
                        // this.updateNotification(subscription.toJSON()) // subscription.getKey(), ""); //p256dh)
                      })
                    }
                  })

                } else {
                  const json = JSON.stringify(subscription.toJSON(), null, 2);
                  // Add Login to update subscription info to your Server
                  console.log("subscription details 2: " + json, subscription)
                  // this.updateNotification(subscription.toJSON()) // subscription.getKey(), ""); //p256dh)
                  
                }
              });
            });
          });
        }        
      }
      
    },
    async update() {
      console.log("updating profile")
    },
    
    /*updateNotification(subscription: PushSubscriptionJSON) {
    // updateNotification(subscription: any) {
      console.log("updating notification; ", subscription.keys)
      
      let update = {
        endpoint: subscription.endpoint, // "https://updates.push.services.mozilla.com/wpush/v2/gAAAAABjB…qto7OH-zUFmG7UH4v00je-pJeth3aj3SN5WbYcEr0gH93kdoi3my4oWV7BHI"
        expirationTime: subscription.expirationTime, // null
        // keys: subscription.keys,
        auth: (subscription.keys as Record<string,string>)["auth"],// "hBMBUqOOzdlXavx1y1bobg"
        p256dh: (subscription.keys as Record<string,string>)["p256dh"], // "BM8jZlS2eHd3XI1tHnGDEeIXi4neSAdg-ci1Y6asfG1hm2pcXlpFhBOK-zzYpz6lhi_qWAa0cv_uBCCnzXPxWec" 
      }
      console.log("updating; ", update)
      userStore.updateNotification(update)
        .then((response) => {
          console.log("updated notifications: ", response)
        });
    },*/
  },
  mounted() {
    console.log("mounted, loading profile")
    this.fetchProfile()
  },
  computed: {
    profile() {
      return userStore.getters.user
    }
  }
  
})

function URLB64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
