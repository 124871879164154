
/* eslint-disable vue/no-unused-components, vue/no-reserved-component-names */
import {Form} from 'vee-validate'
import {useI18n} from 'vue-i18n'
import {defineComponent, onMounted, reactive, ref} from "vue"
import {
  Briefcase,
  CellphoneMessage,
  CheckDecagram,
  ClipboardAccount,
  Earth,
  FileDocument,
  Image, Phone,
  Poll,
  Video,
  Mail,
} from "mdue";
import messageStore from '@/stores/message'
import Close from '@/assets/img/icons/close.svg'
import userStore from "@/stores/user"
import {useRouter} from "vue-router"
import * as Yup from "yup"
import {Modal} from 'bootstrap'
import VueBottomSheet from "@/components/external/VueBottomSheet.vue"
import TagInput from "@/components/external/TagInput.vue"
import Multiselect from '@vueform/multiselect'
import {Channel, Message, User} from "@/types";
import moment from "moment";

// designs for bottom drawers

// https://www.npmjs.com/package/@webzlodimir/vue-bottom-sheet

// https://dribbble.com/shots/9776568-Fund-Project
// https://dribbble.com/shots/9919199-Mobile-Bottom-Sheet
// https://dribbble.com/shots/14845410-Filter-Bottom-Sheet-Insurance-Ranked
// https://dribbble.com/shots/6759319-Sprint-App-UI-Interaction-Design
// https://material.io/components/sheets-bottom
// more: https://dribbble.com/tags/bottom_sheet

const messages = {
  "en": {
    membersince: "member since ",
    privatemessage: "Private Message",
  },
  "nb_NO": {
    membersince: "medlem siden ",
    privatemessage: "Privat melding",
  }
}

export default defineComponent({
  components: { Form, Close, Earth, Briefcase, VueBottomSheet, Image, FileDocument, Video, Poll, 
    ClipboardAccount, CheckDecagram, TagInput, Multiselect, Phone, CellphoneMessage, Mail },
  setup() {

    const instance = ref<any>()
    
    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const state = reactive({
      id: '',
      profile: {} as User,
    })    

    return { instance, userStore, onMounted, state, t, }
  },
  computed: {
    imageUrl() {
      console.log('getting image url for profile: ', this.state.profile.id)
      let ret = (!this.state.profile || !this.state.profile.id || this.state.profile.id === '' || this.state.profile.id === '00000000-0000-0000-0000-000000000000') ?
        'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==' : process.env.VUE_APP_BACKEND + '/images/' + this.state.profile.id
      console.log("url: ", ret)
      return ret
    },
    showPhone(): boolean {
      // return (this.state.profile.phone.length > 0)
      return this.state.profile.contactPhone && !!this.state.profile.phone && (this.state.profile.phone.length > 0)       
    },
    showPhone2(): boolean {
      // return (this.state.profile.phone.length > 0)
      return this.state.profile.contactPhone && !!this.state.profile.phone && (this.state.profile.phone.length > 0)
    },
  },
  methods: {
    onSubmit() {
    },
    show(id: string) {
      userStore.getUserProfile(id)
        .then(resp => {
          console.log("response", resp);
          this.state.profile = resp
          this.instance?.open()
        })
    },
    dateTime2(value: string) {
      moment.locale("nb_NO")
      const diff = moment(new Date()).diff(value, 'day');
      if (diff < 3) {
        return moment(value).fromNow()
      } else {
        return moment(value).format('MMM Do YYYY')
      }
    },
    
  }  
})
