
import {defineComponent, nextTick, PropType, reactive, ref} from "vue";

// modeled after https://github.com/mayank1513/tag-input

// alternatives:
// https://smart-tagz.vercel.app/
// https://vuejsexamples.com/vue-3-multiselect-component-with-multiselect-and-tagging-options/
// https://vuejsexamples.com/chippy-a-tag-based-input-ui-for-vue/
// https://www.vuescript.com/vue-js-2-input-tag-component/
// http://www.vue-tags-input.com/#/
// https://vue-multiselect.js.org/


export default defineComponent({
  name: "TagInput",
  props: {
    name: { type: String, default: "" },
    modelValue: { type: Array as PropType<Array<string>>, default: () => ([]) },
    options: { type: Array as PropType<Array<string>>, default: () => ([]) },
    showCount: { type: Boolean, default: false },
    tagTextColor: { type: String, default: "white" },
    tagBgColor: { type: String, default: "rgb(250, 104, 104)" },
    tagClass: { type: String, default: "" },
  },
  // emits: [ 'update:modelValue' ],
  setup(props) {
    
    const tagsList = ref<HTMLUListElement>();

    const state = reactive({
      paddingLeft: 10,
      noMatchingTag: false,
      newTag: '',
      duplicate: null as any,
    })
    
    const id = Math.random().toString(36).substring(7);

    return { state, tagsList, id }
  },
  mounted () {
    this.onTagsChange()
  },
  watch: {
    modelValue: {
      deep: true,
      handler (val, oldVal) {
        console.log("modelValue updated")
        nextTick(this.onTagsChange)
      }
    }

  },
  methods: {
    addTag(tag: string) {
      tag = tag.trim();
      if (!tag) { return }
      if (this.$props.modelValue.includes(tag)) {
        this.handleDuplicate(tag)
        return
      }
      this.$props.modelValue.push(tag)
      this.state.newTag = "" 
    },

    addTagIfDelem(tag: string) {
      if (",".includes(tag.charAt(tag.length - 1))) {
        this.addTag(tag.substring(0, tag.length - 1))
      }
    },

    removeTag(index: any) {
      this.$props.modelValue.splice(index, 1)
    },

    handleDuplicate(tag: string) {
      this.state.duplicate = tag
      setTimeout(() => (this.state.duplicate = null), 1000);
      this.state.newTag = "";
    },
    
    onTagsChange() {
      console.log("current: " + this.$props.modelValue)
      const extraCushion = 15;
      if (!this.$refs.tagsList) {
        console.log("returning early")
        return 
      }
      // tagsList.value.style.setProperty("--tagBgColor", props.tagBgColor);
      (this.$refs.tagsList as HTMLElement).style.setProperty("--tagTextColor", this.$props.tagTextColor);
      // this.tagsList.value.style.setProperty("--tagTextColor", props.tagTextColor);

      console.log("list width; " + (this.$refs.tagsList as HTMLElement).clientWidth)
      this.state.paddingLeft = (this.$refs.tagsList as HTMLElement).clientWidth + extraCushion;
      (this.$refs.tagsList as HTMLElement).scrollTo((this.$refs.tagsList as HTMLElement).scrollWidth, 0);
      // this.$emit("update:modelValue", this.$refs.tags);
    },
  },
  /*watch: {
    modelValue(newVal: string, oldVal: string) {
      console.log("bingo!:", oldVal, newVal)
      // this.sayHello();
      this.onTagsChange()
    },
  },*/
})
