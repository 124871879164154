
import { defineComponent, PropType, reactive, ref } from 'vue'
import MessageBubble from "@/components/groups/MessageBubble.vue";
import Post from "@/assets/img/icons/post.svg";
import Posting from "@/assets/img/icons/posting.svg";
import { useI18n } from "vue-i18n";
import {Message, User} from "@/types";
import userStore from "@/stores/user";

const messages = {
  "en": {
    messagesfor: "Messages for ",
    created: "created",
    replies: "replies",
    reply: "reply",
    unread: "unread",
  },
  "nb_NO": {
    messagesfor: "Meldinger for ",
    created: "opprettet",
    replies: "svar",
    reply: "svar",
    unread: "nye svar",
  }
}

export default defineComponent({
  components: {
    MessageBubble,
  },
  props: {
    // msg: { type: Object as () => Message, default: {}  },
    msg: { type: Object as PropType<Message>, default: () => ({}) },
    selectedId: { type: String, default: '' },
  },
  emits: ["select-message", "edit-message", "show-profile"],
  data() {
    return { 
      showReplies: false 
    }
  },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })
  
    return { t }
  },
  
  mounted() {
    // console.log("PostWrapper:mounted()");
  },
  methods: {
    selectMessage(id: string) {
      console.log("PostWrapper:selectPost()")
      this.showReplies = true
      this.$emit('select-message', id)
    },
    showProfile(id: string) {
      // console.log("PostWrapper:showProfile()")
      this.$emit('show-profile', id)
    },
    editMessage(id: string) {
      console.log("PostWrapper:editPost()")
      this.$emit('edit-message', id)
    },
    userLastTimestamp(msg: Message): Date {
      let unreadTimestamp: Date = new Date("1970-1-1")
      if (msg.read && msg.read.length > 0) {
        msg.read.forEach((element) => {
          if (element.userId === userStore.getters.id) {
            unreadTimestamp = element.readAt
          }
        })
      }
      console.log("returning: " + unreadTimestamp.toString(), unreadTimestamp)
      return unreadTimestamp
    },
    isUnread(msg: Message, reply: Message): boolean {
      if (reply.userId == userStore.getters.id) { return false }
      let last = this.userLastTimestamp(msg)
      return last < reply.createdAt ? true : false
    },
    /*userLastTimestamp(msg: Message): Date {
      let unreadTimestamp: Date = new Date
      if (msg.read && msg.read.length > 0) {
        msg.read.forEach((element) => {
          if (element.userId === userStore.getters.id) {
            unreadTimestamp = element.readAt
          }
          console.log(".")
        })
      }
      return unreadTimestamp
    }*/
  }
})
