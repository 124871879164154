
/* eslint-disable vue/no-unused-components */
import { defineComponent, reactive, ref } from 'vue'
import { User, UserImpl } from '@/types'
import {  Radio, ChevronRight, Pencil, CheckboxIntermediate } from 'mdue'
import Close from '@/assets/img/icons/close.svg'
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import Key from '@/assets/img/icons/key.svg';
import Settings from '@/assets/img/icons/settings.svg';
import Logout from '@/assets/img/icons/logout.svg';
import ArrowForward from '@/assets/img/icons/arrow-forward.svg';
import Help from '@/assets/img/icons/help.svg';
import axios from "axios";
import { useI18n } from "vue-i18n";
import userStore from '@/stores/user'
import { useRouter } from "vue-router";
import VueNextSelect from 'vue-next-select'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
// import 'v-onboarding/dist/style.css';
import { VOnboardingWrapper, VOnboardingStep, useVOnboarding } from 'v-onboarding'

const messages = {
  "en": {
    myprofile: "My Profile",
    email: "email",
    givenname: "givenname",
    surname: "surname",
    companyName: "company name",
    companyUrl: "company website",
    phone: "phone",
    bornyear: "born year",
    aboutme: "about me",
    edit: "Edit",
    update: "Update",
    editimage: "Edit profile image",
    mysettings: "My Settings",
    whyheldel: "Why HelDel",
    terms: "Terms and Conditions",
    ifcompany: "If you represent a company",
    location: "Location",
    step1title: "Add Profile Image",
    step1description: "Show who you are to others by adding a profile image, or a company logo",
    finish: "OK",
  },
  "nb_NO": {
    myprofile: "Min profil",
    email: "e-post",
    givenname: "fornavn",
    surname: "etternavn",
    companyName: "bedriftsnavn",
    companyUrl: "bedriftens webside",
    phone: "telefon",
    bornyear: "født år",
    aboutme: "om meg",
    edit: "rediger",
    update: "oppdater",
    editimage: "bytt profilbilde",
    mysettings: "Mine innstillinger",
    whyheldel: "Hvorfor HelDel",
    terms: "Vilkår og personvern",
    ifcompany: "Hvis du representerer en bedrift",
    location: "Område",
    step1title: "Legg inn profilbilde",
    step1description: "Vis hvem du er til andre med et profil bilde, eller en firmalogo",
    finish: "OK",
  }
}

export default defineComponent({
  components: { Key, Help, Settings, ArrowForward, Logout, BreadcrumbsBar,
    'v-select': vSelect, 'v-next-select': VueNextSelect, VOnboardingWrapper, VOnboardingStep, Close, },
  setup() {
    const profileImage = ref(null);
    const state = reactive({
      givenName: '',
      middleName: '',
      surName: '',
      companyName: '',
      companyUrl: '',
      location: '',
      deadline: '',
      phone: '',
      description: '',
      imageref: '',
      yearBorn: '1970',
      isFetching: true, // see https://stackoverflow.com/questions/54024406/vue-js-waiting-for-data-before-rendering
      previewImage: '',
      // profileImage: '', //AppSettings.BACKEND + '/images/' + state.id, // + '?rnd=' + state.rnd,
      id: '',
      selected: 'B',
    })

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const router = useRouter()
    
    const wrapper = ref(null)
    const options = { labels: { finishButton: t('finish') } }
    const { start, goToStep, finish } = useVOnboarding(wrapper)
    const steps = [
      { attachTo: { element: '#changeProfileImage' }, 
        content: { 
          title: t('step1title'), description: t('step1description') 
        } 
      }
    ]

    
    const currentYear = new Date().getFullYear()
    const allYears: Array<String> = Array.from({length: currentYear - 1900}, (value, index) => (1901 + index).toString()).reverse()

    return { profileImage, t, state, allYears, router, userStore, wrapper, steps, options, start, }
  },
  methods: {
    setProfile: function(profile: User) {
      console.log("profile: " + JSON.stringify(profile))
      this.state.givenName = profile.givenName ? profile.givenName : ''
      this.state.surName = profile.surName ? profile.surName : ''
      this.state.companyName = profile.companyName
      this.state.companyUrl = profile.companyUrl
      this.state.location = profile.location
      console.log("dob: " + profile.DOB)
      this.state.phone = profile.phone ? profile.phone : ''
      // this.state.yearBorn = profile.dob
      this.state.description = profile.description ? profile.description : ''
      this.state.yearBorn = profile.DOB ? profile.DOB : ''
      this.state.middleName = profile.middleName ? profile.middleName : ''
      this.state.isFetching = false
      this.state.id = profile.id
      // this.state.profileUrl = AppSettings.BACKEND + '/images/' + profile.id // + '?rnd=' + state.rnd,
      
      let self = this
      
      let imageUrl = process.env.VUE_APP_BACKEND + '/images/' + profile.id
      axios({ method: 'get', url: imageUrl, responseType: 'blob' })
        .then(function(resp) {
          console.log("response", resp);
          (self.profileImage! as HTMLImageElement).src = URL.createObjectURL(resp.data)
          // var mimeType = resp.headers['content-type'].toLowerCase();
          // var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
          // (self.profileImage! as HTMLImageElement).src = 'data:' + mimeType + ';base64,' + imgBase64
        }).catch((function() {
          // console.log("starting onboarding")
          // that.start()
        // el.src = imageUrl;
      }));

      // do we have an image?
      let that = this
      axios.head(imageUrl, {}).then(function (res) {
        console.log("image check returns; ", res)
        if (res.status === 404) {
          // start onboardingh
          console.log("starting onboarding")
          that.start()
        } else {
          console.log("no onboarding")
        }
      }).catch((e: Error) => {
        console.log("image check returns; ", e)
        console.log(e)
      })
      
      
      
/*
      let self = this

      const load = AppSettings.BACKEND + '/images/' + userStore.getters.id
      axios({ method: 'get', url: load, responseType: 'blob' })
        .then(function(resp) {
          console.log("response", resp)
          self.imageUrl = URL.createObjectURL(resp.data)
          // const mimeType = resp.headers['content-type'].toLowerCase();
          // const imgBase64 = new Blob(resp.data, 'binary').toString('base64');
          // (this as any).imageUrl = 'data:' + mimeType + ';base64,' + imgBase64;
          // el.src = 'data:' + mimeType + ';base64,' + imgBase64;
        }).catch((function() {
        console.error("unable to load image: " + load)
      
  */    
      
      
      
      

    },
    years (): Array<String> {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1900}, (value, index) => (1901 + index).toString()).reverse()
    },    
    async fetchProfile() {
      console.log("auth: ", axios.defaults.headers.common['Authorization'])
      axios.get (process.env.VUE_APP_BACKEND + '/api/v1/user')
        .then(resp => {
          // console.log("get user response: ", resp);
          this.setProfile(resp.data)
          // this.selected = this.inventory.find(i => i.id === this.selected);
          
          
        })
        .catch(err => {
          console.log("error: " + err)
          // commit('auth_error', err)
        })
      
      /*
      
      try {
        const response = await Request.getUser()
        this.setProfile(response)
      } catch (err) {
        if (err.response) {
          console.log("Server Error:", err)
        } else if (err.request) {
          console.log("Network Error:", err)
        } else {
          console.log("Client Error:", err)
        }
      }*/
    },
    async submitForm() {
      console.log("updating profile")
      let user: User = new UserImpl()
      user.givenName = this.state.givenName, user.middleName = this.state.middleName, user.surName = this.state.surName,
        user.DOB = this.state.yearBorn, user.phone = this.state.phone, user.description = this.state.description,
        user.companyName = this.state.companyName, user.companyUrl = this.state.companyUrl
      userStore.updateUser(user)
        .then((response) => {
          console.log("updating user store with data: ", response)
          // userStore.
          this.$router.push('/me')
        })
    },
    async signout() {
      userStore.logout().then(() => this.router.push('/'))
    },


    /*async handleSubmit(e: Event) {
      //e.preventDefault();
      console.log("submitting")
    },*/
  },
  updated() {
    console.log("updated")
    // this.state.profileImage = AppSettings.BACKEND + '/images/' + this.state.id + '?rnd=' + Math.floor(Math.random() * 256)

    let self = this

    var imageUrl = process.env.VUE_APP_BACKEND + '/images/' + this.state.id + '?rnd=' + Math.floor(Math.random() * 256)
    axios({ method: 'get', url: imageUrl, responseType: 'blob' })
      .then(function(resp) {
        console.log("loading image response", resp);
        (self.profileImage! as HTMLImageElement).src = URL.createObjectURL(resp.data)
      }).catch((function() {
      }
      ));
  },
  beforeUpdate() {
    // console.log("beforeUpdate")
  },
  mounted() {
    this.fetchProfile()
    
    // only start the onboarding if we're missing a profile image and givenName, surName
  },
}) 

