import {createApp} from 'vue'
import mitt from 'mitt'
import {createI18n} from 'vue-i18n'
import App from './App.vue'
import router from './router'
import BootstrapVue3 from 'bootstrap-vue-3'
import {QuillEditor} from '@vueup/vue-quill'
import VueUploadComponent from 'vue-upload-component/src/FileUpload.vue'
import ToastPlugin from 'vue-toast-notification'

// https://www.npmjs.com/package/mdi-vue
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

// @ts-ignore
import imagePlugin from './plugins/image-plugin.js'
import heartbeat2 from './plugins/heartbeat2'

// import ImageUploader from 'vue-image-upload-resize'
// import ImageUploader from 'vue3-image-uploader'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import '@vueup/vue-quill/dist/vue-quill.bubble.css';
// https://cdmoro.github.io/bootstrap-vue-3/getting-started/#bundlers
import '@/assets/bootstrap.scss'; // with our own bootstrap mods
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import '@vueform/multiselect/themes/default.css'

// internationalization configurations
const myi18n = createI18n({
  legacy: false,
  locale: 'nb_NO',
  messages: {
    'nb_NO': { ok: 'OK', readmore: 'les mer' },
    'en': { ok: 'OK!', readmore: 'read more' }
  }
  // fallbackLocale: 'en',
  // locale: 'en'
})

// const i18n = new VueI18n({
//   locale: 'nb_NO', // set locale
// })

// export const eventBus = new Vue(); // creating an event bus.

const emitter = mitt();

const app = createApp(App)

// https://logaretm.com/blog/2020-12-23-type-safe-provide-inject
// app.provide('backend', AppSettings.BACKEND);

// Vue.component('ValidationProvider', ValidationProvider);
// Vue.component("ValidationObserver", ValidationObserver);

// init interceptors
// import setupInterceptors from './services/setupInterceptors';

// material icons
// import MenuIcon from 'vue-material-design-icons/Menu.vue';
// import MenuIcon from 'vue3-material-design-icons-ts/dist/Menu.vue'
// import MenuIcon from 'vue-material-design-icons/Menu.vue';

// humble icons
// https://humbleicons.com/

app.config.globalProperties.emitter = emitter;

app.use(router)
  .use(BootstrapVue3)
  .use(myi18n)
  .use(ToastPlugin)
  .use(imagePlugin)
  .use(heartbeat2)
  .use(mdiVue, { icons: mdijs })
  .component('QuillEditor', QuillEditor)
  // .component('ImageUploader', ImageUploader)
  .component('file-upload', VueUploadComponent)
  .mount('#app')


// https://github.com/kazupon/vue-i18n/issues/253
export default function setGlobalLocale(localeString: any) {
  if (localeString && localeString !== '') {
    console.log("Setting locale: ", localeString);
    myi18n.global.locale.value = localeString;
  }
}
