import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2520a94a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "profile-sheet"
}
const _hoisted_2 = { class: "profile-body" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "d-flex flex-row align-items-center" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "avatar-wrap" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "d-flex flex-column flex-shrink-0" }
const _hoisted_9 = { class: "phone-wr w-100 mt-2" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_13 = { class: "profile-title" }
const _hoisted_14 = {
  key: 0,
  class: "mail-wr w-100"
}
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "profile-meta flex-grow-0" }
const _hoisted_18 = { class: "text-muted" }
const _hoisted_19 = { class: "about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Phone = _resolveComponent("Phone")!
  const _component_CellphoneMessage = _resolveComponent("CellphoneMessage")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Mail = _resolveComponent("Mail")!
  const _component_vue_bottom_sheet = _resolveComponent("vue-bottom-sheet")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createVNode(_component_vue_bottom_sheet, {
      ref: "instance",
      class: "wide-sheet",
      rounded: false,
      "max-width": "100%"
    }, {
      default: _withCtx(() => [
        (_ctx.state.profile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("img", {
                          src: _ctx.imageUrl,
                          class: "avatar-img sheet me-3"
                        }, null, 8, _hoisted_7),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            (_ctx.showPhone)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  class: "btn btn-xs btn-outline-dark btn-rounded",
                                  href: 'tel:'+_ctx.state.profile.phone
                                }, [
                                  _createVNode(_component_Phone, { class: "phone" })
                                ], 8, _hoisted_10))
                              : _createCommentVNode("", true),
                            (_ctx.showPhone)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 1,
                                  class: "ms-2 btn btn-xs btn-outline-dark btn-rounded",
                                  href: 'sms:'+_ctx.state.profile.phone
                                }, [
                                  _createVNode(_component_CellphoneMessage, { class: "phone" }),
                                  _createTextVNode(" sms")
                                ], 8, _hoisted_11))
                              : _createCommentVNode("", true),
                            _createVNode(_component_router_link, {
                              class: "ms-2 btn btn-xs btn-rounded btn-outline-dark",
                              to: '/messages/'+_ctx.state.profile.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('privatemessage')), 1)
                              ]),
                              _: 1
                            }, 8, ["to"])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.state.profile.givenName) + " " + _toDisplayString(_ctx.state.profile.surName), 1),
                        (_ctx.state.profile.contactEmail)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("a", {
                                class: "me-2",
                                href: 'mailto:'+_ctx.state.profile.email
                              }, [
                                _createVNode(_component_Mail, { class: "mail" })
                              ], 8, _hoisted_15),
                              _createElementVNode("a", {
                                href: 'mailto:'+_ctx.state.profile.email
                              }, _toDisplayString(_ctx.state.profile.email), 9, _hoisted_16)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('membersince')) + " " + _toDisplayString(_ctx.dateTime2(_ctx.state.profile.createdAt)), 1)
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.state.profile.companyName), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.state.profile.companyUrl), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.state.profile.location), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.state.profile.description), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512)
  ]))
}