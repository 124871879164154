
import userStore from '@/stores/user'
import channelStore from '@/stores/channel'
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import Feed from '@/assets/img/icons/feed-active.svg'
import Messages from '@/assets/img/icons/messages-active.svg'
import Logout from '@/assets/img/icons/logout.svg'
import Info from '@/assets/img/icons/info-active.svg'
import Groups from '@/assets/img/icons/group-active.svg'
import {useI18n} from "vue-i18n";


// material design component: https://github.com/vyperium/mdue/blob/master/example/src/App.vue
// icon reference: https://materialdesignicons.com/

// alternative svg loading; https://github.com/shrpne/vue-inline-svg

// inline / external https://github.com/visualfanatic/vue-svg-loader/issues/150

// loader issue fix: https://github.com/vuejs/vue-cli/issues/6785

const messages = {
  "en": {
    home: "Home",
    subscription: "All Networks",
    channel: "New Posting",
    messages: "Messages",
    about: "About Heldel",
  },
  "nb_NO": {
    home: "Hjem",
    subscription: "Alle nettverk",
    channel: "Nytt innlegg",
    messages: "Meldinger",
    about: "Om Heldel",
  }
}
export default defineComponent({
  components: {
    Feed, Messages, Logout, Info, Groups,
  },
  setup() {
    const router = useRouter()

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const postingmodal = ref(null)
    
    const logout = function() {
      userStore.logout().then(() => { router.push('/') })
    }
    
    return { t, logout, userStore, channelStore, postingmodal }
  },
  methods: {
    newPosting() {
      // https://stackoverflow.com/questions/67053956/vue-3-get-access-to-router-view-instance-in-order-to-call-child-methods
      console.log("creating new posting for channel id: " + channelStore.getters.selectedChannelId);
      console.log("ref: ", (this.$root?.$refs.view as any).$.ctx);
      (this.$root?.$refs.view as any).$.ctx['newPosting']()
    },
  }
})
