
import {defineComponent, PropType} from "vue"
import moment from 'moment'
import userStore from '@/stores/user'
import {MinusCircleOutline} from 'mdue'
import Send from "@/assets/img/icons/send.svg"
import {useI18n} from "vue-i18n";
import {Channel, User} from "@/types";

const messages = {
  "en": {
    privatemessage: "Private Message",
  },
  "nb_NO": {
    privatemessage: "Privat melding",
  }
}

export default defineComponent({
  props: {
    profile: { type: Object as PropType<User>, default: null},
    channel: { type: Object as PropType<Channel>, default: null},
    channelId: { type: String, default: ''},
    date: { type: String, default: '' },
    invite: { type: Boolean, default: false },
  },

  components: { MinusCircleOutline, Send },
  data() {
    return {
      imagePrefix: process.env.VUE_APP_BACKEND + '/images/' + this.profile.id
    }
  },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    return { userStore, t }
  },
  computed: {
    imageUrl() {
      return process.env.VUE_APP_BACKEND + '/images/' + this.profile.id
    }
  },
  methods: {
    dateTime(value: string) {
      moment(value).format('YYYY-MM-DD');
    },

    dateTime2(value: string) {
      moment.locale("nb_NO")
      const diff = moment(new Date()).diff(value, 'day');
      if (diff < 3) {
        return moment(value).fromNow()
      } else {
        return moment(value).format('MMM Do YYYY')
      }
    },

    subscribe: function(subscribe: boolean) {
      // e.stopPropagation()

      console.log("subscribing user id: " + this.profile.id + " to channel id: " + this.channelId);
      // channelStore.subscribe(this.profile.id, this.channelId, subscribe)

      this.$emit('subscribe-profile', this.profile.id, this.channelId, subscribe)
    },
    
    isSubscribed() {
      try {
        let _id = this.profile.id // userStore.getters.id
        console.log("got channel? ", this.channel)
        if (this.channel) {
          console.log("participants; ", this.$props.channel.participants)
          if (this.$props.channel.participants) {
            for (let p of this.$props.channel.participants) {
              // console.log("p: " + p)
              if (p.id === _id) {
                return true;
              }
            }
          }
        }
      } catch (ex) {
        console.log("ex: " + ex)
      }
      return false
    },
    
  }
})
