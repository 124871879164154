
import { defineComponent, ref } from "vue";
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import { useI18n } from "vue-i18n";

const messages = {
  "en": {
    pageTitle: "404 Not Found",
    title: "Oops, page not found!",
    description: "No page found at this address",
    home: "Home",
  },
  "nb_NO": {
    pageTitle: "404 Ikke funnet",
    title: "Oops, siden ikke funnet!",
    description: "Ingen side funnet under denne adressen",
    home: "Hjem"
  }
}

export default defineComponent({
  components: { BreadcrumbsBar },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    return { t }
  },  
})
