

// validation, see https://vee-validate.logaretm.com/v3/guide/basics.html

// import { mapActions } from "vuex"
// import store from "@/store"

import { defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import userStore from "@/stores/user"
import router from "@/router"
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue"

const messages = {
  "en": {
    "heading": "Verify Account Creation",
    "explanation": "Select verify to create the account",
    "action": "verify"
  },
  "nb_NO": {
    "heading": "Opprett brukerkonto",
    "explanation": "Trykk bekreft for å opprette kontoen",
    "action": "bekreft"
  }
}

export default defineComponent({
  props: {
    token: { type: String, default: '' },
    email: { type: String, default: '' },
  },
  components: { BreadcrumbsBar },
  name: 'VerifySignup',
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    return { userStore, t }
  },
  methods: {
    verify: function (token: string, email: string) {
      console.debug("verifying token: ${token}")

      userStore.verify(token)
        .then(() => router.push('/me'))
      
      // this.loginUser({username: this.username, password: this.password});
      // this.$store.dispatch('verify', { token: this.token })
        // commit('login', { username: this.username, password: this.password, grant_type: 'password', scope: 'public' })
        // .then(() => this.$router.push('/'))
        // .catch(err => console.log(err))
    }
  }
})


/*export default {
  name: 'verify',
  setup() {
  },
}*/
/*export default {
  name: 'Verify',
  props: ['token', 'email'],
  methods: {
    ...mapActions(['verify']),

    verify: function () {
      console.debug("verifying token: ${token}")
      // this.loginUser({username: this.username, password: this.password});
      this.$store.dispatch('verify', { token: this.token })
          // commit('login', { username: this.username, password: this.password, grant_type: 'password', scope: 'public' })
          .then(() => this.$router.push('/'))
          .catch(err => console.log(err))
      }
  }
}*/
