
import {defineComponent, reactive, ref} from 'vue'
import channelStore from '@/stores/channel'
import ProfileBubble from '@/components/profile/ProfileBubble.vue'
import {Magnify} from "mdue";

import {Channel, User} from '@/types'
import {useI18n} from "vue-i18n"

const messages = {
  "en": {
    members: "Members of ",
    search: "Search for member",
  },
  "nb_NO": {
    members: "Medlemmer av ",
    search: "Søk etter medlem",
  }
}


export default defineComponent({
  components: { ProfileBubble, Magnify },
  props: {
    id: { type: String, default: '' }
  },
  setup() {
    const state = reactive({
      members: [] as User[],
      group: {} as Channel
    })

    const query = ref()
    
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    const { getters, getChannel } = channelStore
    return { state, getters, t, getChannel, query }
  },
  data() {
    return {
      queryString: ''
    }
  },
  computed: {
    filteredMembers() {
      let data = this.state.members
      // let query = (this.query as HTMLInputElement).value
      
      if (this.queryString && this.queryString.length > 0) {

        data = data.filter((obj) => {
          return obj.givenName.toLowerCase().includes(this.queryString.toLowerCase()) ||
            obj.surName.toLowerCase().includes(this.queryString.toLowerCase())
        });
      }
      return data
    }
  },  
  mounted() {
    console.log("GroupMembers:mounted()");

    let response = this.getChannel(this.$props.id)
      .then((resp) => {
        console.log("got group: ", resp)
        this.state.group = resp
        this.state.members = resp.participants
      })
  },
  methods: {
    filterList() {
      this.queryString = (this.query as HTMLInputElement).value
    },
  },
})
