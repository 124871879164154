<template>
  <div>
  <BreadcrumbsBar title="Registrering"/>
  <div class="scroll-area container-fluid">
    <h1>Sjekk E-post</h1>
    <p>Du vil få tilsendt en lenke på e-post som du må trykke på for å fullføre registreringen.</p>
    <p>Hvis du ikke mottar e-post kan du prøve på nytt. Det opprettes ikke noen konto hos oss før lenken blir åpnet.</p>
    
    <router-link to="/">Hjem</router-link>
  </div>
  </div>
</template>
<script>
import BreadcrumbsBar from "@/components/BreadcrumbsBar";
export default {
  components: { BreadcrumbsBar }
}
</script>
