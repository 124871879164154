<template>
  <div>
  <BreadcrumbsBar title="Registrering"/>
  <div class="scroll-area container-fluid">
    <h1>Registrering feilet!</h1>
    <p>For å kunne registrere deg må du ha en godkjent e-post adresse.</p>
    
    <router-link to="/">Hjem</router-link>
  </div>
  </div>
</template>
<script>
import BreadcrumbsBar from "@/components/BreadcrumbsBar";
export default {
  components: { BreadcrumbsBar }
}
</script>
