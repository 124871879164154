
import { defineComponent, reactive, ref } from 'vue'
import {Message, User} from '@/types'
import { Radio, ChevronRight, Pencil, CheckboxIntermediate } from 'mdue'
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import Key from '@/assets/img/icons/key.svg';
import Settings from '@/assets/img/icons/settings.svg';
import Logout from '@/assets/img/icons/logout.svg';
import ArrowForward from '@/assets/img/icons/arrow-forward.svg';
import Help from '@/assets/img/icons/help.svg';
import axios from "axios";
import { useI18n } from "vue-i18n";
import userStore from '@/stores/user'
import { useRouter } from "vue-router";
import messageStore from "@/stores/message";
import channelStore from "@/stores/channel";

const messages = {
  "en": {
    member: "Member",
    email: "email",
    sendmessage: "send private message",
    givenname: "givenname",
    surname: "surname",
    phone: "phone",
    bornyear: "born year",
    about: "about",
    edit: "Edit",
    update: "Update",
    editimage: "Edit profile image",
    mysettings: "My Settings",
    whyheldel: "Why HelDel",
    terms: "Terms and Conditions",
  },
  "nb_NO": {
    member: "Medlem",
    email: "e-post",
    sendmessage: "send private melding",
    givenname: "fornavn",
    surname: "etternavn",
    phone: "telefon",
    bornyear: "født år",
    about: "om",
    edit: "rediger",
    update: "oppdater",
    editimage: "bytt profilbilde",
    mysettings: "Mine innstillinger",
    whyheldel: "Hvorfor HelDel",
    terms: "Vilkår og personvern",
  }
}

export default defineComponent({
  components: { BreadcrumbsBar },
  props: {
    id: { type: String, default: '00000000-0000-0000-0000-000000000000', }
  },
  data() { 
    return {
      user: {} as User,
    }
  },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const profileImage = ref(null);
    
    const router = useRouter()

    return { t, router, userStore, profileImage }
  },
  beforeRouteEnter: function (to, from, next) {
    console.log("ViewProfile:beforeRouteEnter()", to, from, next)

    next(vm => {
      (vm as any).fetchProfile(to?.params.id as string)
    })
  },
  beforeRouteUpdate: function (to: any, from: any, next: any) {
    console.log("Channel:beforeRouteUpdate()", to, from, next)

    this.fetchProfile(to?.params.id as string)
    // userStore.getUserProfile(to?.params.id as string)
    //   .then((resp) => {
    //     console.log("got data: ", resp)
    //   })
    next();
  },
  methods: {
    message() {
      this.$router.push('/messages/' + this.user.id)
    },
    async fetchProfile(id: string) {
      console.log("auth: ", axios.defaults.headers.common['Authorization'])      
      axios.get (process.env.VUE_APP_BACKEND + '/api/v1/users/'+id)
        .then(resp => {
          console.log("ViewProfile:fetchProfile: ",resp);
          this.user = resp.data

          let self = this
          var imageUrl = process.env.VUE_APP_BACKEND + '/images/' + this.user.id + '?rnd=' + Math.floor(Math.random() * 256)
          axios({ method: 'get', url: imageUrl, responseType: 'blob' })
            .then(function(resp) {
              console.log("loading image response", resp);
              (self.profileImage! as HTMLImageElement).src = URL.createObjectURL(resp.data)
            }).catch((function() {
            }
          ));
        })
        .catch(err => {
          console.log("error: ", err)
        })
    },
  },
  updated() {
    console.log("updated")
  },
  mounted() {
  },
})

