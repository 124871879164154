
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    show: { type: Boolean, default: true },
  },
  methods: {
    stopclick(ev: Event) {
      ev.stopPropagation()
    },
    close(ev: Event) {
      // https://stackoverflow.com/questions/10554446/no-onclick-when-child-is-clicked
      if (ev.target === ev.currentTarget) {
        console.log('closing modal')
        this.$router.back()
      }
    },
    onBeforeLeave() {
      console.log("onBeforeLeave")
    },
    onLeave() {
      console.log("onLeave")
    },
    onAfterLeave() {
      console.log("onAfterLeave")
    },
  }
})
