import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id", "href"]
const _hoisted_2 = {
  key: 0,
  class: "avatar-img"
}
const _hoisted_3 = {
  key: 1,
  class: "avatar-img",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
  alt: ""
}
const _hoisted_4 = { class: "message-meta" }
const _hoisted_5 = { class: "meta" }
const _hoisted_6 = { class: "actions" }
const _hoisted_7 = {
  key: 0,
  class: "badge rounded-pill bg-secondary"
}
const _hoisted_8 = {
  key: 2,
  class: "icon-link"
}
const _hoisted_9 = {
  key: 0,
  class: "message-tags"
}
const _hoisted_10 = { class: "message-bubble" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: ""
}
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 1,
  class: "d-flex align-items-end justify-content-end"
}
const _hoisted_15 = { class: "btn btn-outline-secondary btn-rounded flex-grow-0" }
const _hoisted_16 = ["id"]
const _hoisted_17 = { class: "message-image avatar-handle" }
const _hoisted_18 = {
  key: 0,
  class: "avatar-img"
}
const _hoisted_19 = {
  key: 1,
  class: "avatar-img",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
  alt: ""
}
const _hoisted_20 = { class: "message-meta" }
const _hoisted_21 = { class: "meta" }
const _hoisted_22 = { class: "actions" }
const _hoisted_23 = { class: "message-bubble" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkPrevue = _resolveComponent("LinkPrevue")!
  const _component_ThumbUpOutline = _resolveComponent("ThumbUpOutline")!
  const _component_ThumbUp = _resolveComponent("ThumbUp")!
  const _component_Pencil = _resolveComponent("Pencil")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _directive_auth_image = _resolveDirective("auth-image")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.msg.id === _ctx.selectedId && _ctx.msg.externalUrl)
      ? (_openBlock(), _createBlock(_component_LinkPrevue, {
          key: 0,
          url: _ctx.msg.externalUrl
        }, null, 8, ["url"]))
      : _createCommentVNode("", true),
    (_ctx.msg.messageType !== 'system')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: _ctx.msg.id,
          href: `collapse${_ctx.msg.id}`,
          class: _normalizeClass(["message-wrap", {'channel-link': _ctx.linkToChannel, 'active': _ctx.msg.id === _ctx.selectedId, 'passive': _ctx.msg.id !== _ctx.selectedId, 'message-right': (_ctx.msg.userId === _ctx.userStore.getters.id), 'job': _ctx.msg.postingType === 'job', 'people': _ctx.msg.postingType === 'people'}]),
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args))),
          "data-bs-toggle": "collapse"
        }, [
          _createElementVNode("div", {
            class: "message-image avatar-handle",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showProfile && _ctx.showProfile(...args)), ["stop"]))
          }, [
            (_ctx.msg.userId !=='00000000-0000-0000-0000-000000000000')
              ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_2, null, 512)), [
                  [_directive_auth_image, _ctx.imagePrefix + _ctx.msg.userId]
                ])
              : _createCommentVNode("", true),
            (_ctx.msg.userId ==='00000000-0000-0000-0000-000000000000')
              ? (_openBlock(), _createElementBlock("img", _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["message-body", {'limitheight': _ctx.limitheight}])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("b", null, _toDisplayString(_ctx.msg.user ? (_ctx.msg.user.givenName + ' ' + _ctx.msg.user.surName) : ''), 1),
                _createTextVNode(" postet "),
                _createElementVNode("b", null, _toDisplayString(_ctx.dateTime2(_ctx.msg.createdAt)), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.showChannel)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.msg.channel.title), 1))
                  : _createCommentVNode("", true),
                (_ctx.showLike && !_ctx.liked)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: "icon-link",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.like && _ctx.like(...args)), ["stop"]))
                    }, [
                      _createVNode(_component_ThumbUpOutline, { class: "thumbup" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.msg.likes && _ctx.msg.likes.length > 0 ? ' · ' + _ctx.msg.likes.length : ''), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showLike && _ctx.liked)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                      _createVNode(_component_ThumbUp, { class: "thumbup" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.msg.likes && _ctx.msg.likes.length > 0 ? ' · ' + _ctx.msg.likes.length : ''), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editable(_ctx.msg.id) && !_ctx.showChannel)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      class: "ms-3",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)), ["stop"]))
                    }, [
                      _createVNode(_component_Pencil, { class: "pencil" })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.msg.tags && _ctx.msg.tags.length > 0)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.msg.tags, (tag) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: tag,
                      class: "tag"
                    }, _toDisplayString(tag), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", {
                innerHTML: _ctx.msg.message
              }, null, 8, _hoisted_11),
              (_ctx.msg.externalUrl && _ctx.msg.externalUrl.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("a", {
                      class: "badge rounded-pill bg-secondary external-url",
                      href: _ctx.msg.externalUrl,
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.clickClick($event)), ["stop"]))
                    }, _toDisplayString(_ctx.msg.externalUrl), 9, _hoisted_13)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.replyLink)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('reply')), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.msg.messageType === 'system')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          id: _ctx.msg.id,
          class: _normalizeClass(["message-wrap", {'channel-link': _ctx.linkToChannel}])
        }, [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_router_link, {
              to: '/messages/'+_ctx.msg.userId,
              title: _ctx.t('privatemessage')
            }, {
              default: _withCtx(() => [
                (_ctx.msg.userId !=='00000000-0000-0000-0000-000000000000')
                  ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_18, null, 512)), [
                      [_directive_auth_image, _ctx.imagePrefix + _ctx.msg.userId]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.msg.userId ==='00000000-0000-0000-0000-000000000000')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_19))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["to", "title"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["message-body", {'limitheight': _ctx.limitheight}])
          }, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("b", null, _toDisplayString(_ctx.dateTime2(_ctx.msg.createdAt)), 1)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("a", {
                  onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.like && _ctx.like(...args)), ["stop"])),
                  class: "icon-link"
                }, [
                  _createVNode(_component_mdicon, {
                    name: "hand-wave",
                    size: "1em"
                  }),
                  _createTextVNode("  " + _toDisplayString(_ctx.t('hi')) + " · " + _toDisplayString(_ctx.msg.likes && _ctx.msg.likes.length > 0 ? _ctx.msg.likes.length : ''), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("p", null, [
                _createElementVNode("b", null, _toDisplayString(_ctx.msg.user ? (_ctx.msg.user.givenName ? _ctx.msg.user.givenName : _ctx.msg.user.email) : ''), 1),
                _createTextVNode(" har blitt med i nettverket, ønsk " + _toDisplayString(_ctx.msg.user.givenName ? _ctx.msg.user.givenName : _ctx.msg.user.email) + " velkommen! ", 1)
              ])
            ])
          ], 2)
        ], 10, _hoisted_16))
      : _createCommentVNode("", true)
  ], 64))
}