<template>
  <div class="scroll-area container-fluid">
    <h1>Heldel brukervilkår</h1>
    <p>
      Vi vil ikke misbruke dataene dine
    </p>

    <h3>Hvordan?</h3>

    <p>
    </p>
  </div>
</template>
