
/* eslint-disable vue/no-reserved-component-names */
import {defineComponent, reactive, ref} from 'vue'
import channelStore from '@/stores/channel'
import ProfileBubble from '@/components/profile/ProfileBubble.vue'
import Send from "@/assets/img/icons/send.svg"
import {Magnify} from "mdue"
import {Field, Form} from 'vee-validate'
import {Channel, User} from '@/types'
import {useI18n} from "vue-i18n"
import userStore from "@/stores/user"
import * as Yup from "yup";
import {useToast} from "vue-toast-notification";

const messages = {
  "en": {
    members: "Members",
    search: "Search for member",
    invite: "Invite",
    heading: "No Matches?",
    description: "We can help with that. Please enter the email address of the recipient and we will send an invite.",
    email: "Email Address",
    sendinvite: "Send Invite",
    emailrequired: "Email is required",
    emailinvalid: "Email is invalid",
    emailsent: "Invitation email sent",
    emailfailed: "Failed to send email, please try again",
  },
  "nb_NO": {
    members: "Medlemmer",
    search: "Søk etter medlem",
    invite: "Inviter",
    heading: "Ingen treff?",
    description: "Det kan vi gjøre noe med. Legg inn e-post adressen til den du vil invitere i feltet under, så sender vi en invitasjon.",
    email: "E-postadresse",
    sendinvite: "Send invitasjon",
    emailrequired: "Du må skrive inn en e-post adresse",
    emailinvalid: "E-post adressen er ikke gyldig format",
    emailsent: "E-post invitasjon sendt",
    emailfailed: "E-post ble ikke sent, prøv igjen",
  }
}


export default defineComponent({
  components: { ProfileBubble, Magnify, Send, Form, Field },
  props: {
    id: { type: String, default: '' },
  },
  setup() {
    const query = ref()
    const email = ref()
    const send = ref()

    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    const state = reactive({
      users: [] as User[],
      channel: {} as Channel,
      showSpinner: false as boolean,
      feedback: '' as string,
    })

    const schema = Yup.object().shape({
      // email: Yup.string().when('email', {
      //   is: (email) => email.length > 0
      //   then: Yup.email(t("emailinvalid"))
      // })
      email: Yup.string()
        .required(t("emailrequired"))
        .email(t("emailinvalid"))
    })

    const form = reactive({
      email : '',
    })

    // https://stackoverflow.com/questions/73051685/vee-validate-returning-form-is-always-valid-when-using-field
    const fm = ref()

    const { getChannel } = channelStore
    const { getUsers } = userStore

    return { state, t, getChannel, getUsers, query, email, schema, form, fm }
  },
  mounted() {
    console.log("searchMembers:mounted();", this.state.channel);
    this.loadChannel()
  },
  methods: {
    loadChannel() {
      let that = this
      let response = channelStore.getChannel(this.id)
        .then((resp) => {
          console.log("got group: ", resp)
          that.state.channel = resp
        })
    },
    /*searchMembers(channel: Channel) {
      this.state.group = channel
      console.log("SearchMembersModal:searchMembers()")
      // this.instance?.show()
    },*/
    filterList() {
      console.log("searching for: " + (this.query as HTMLInputElement).value)

      userStore.getUsers((this.query as HTMLInputElement).value, (users: User[]) => {
        console.log("SearchMembers:filterList()", users)
        console.log("users;", users)
        this.state.users = users
      })
      
      return false
    },
    subscribeProfile(profileId: string, channelId: string, subscribe: boolean) {
      console.log("subscribeProfile; ", profileId, channelId, subscribe)
      channelStore.subscribe(profileId, channelId, subscribe)
        .then((resp) => {
          // need to refresh list of users to update subscribe status
          this.loadChannel()
        })
    },
    invite(evt: Event) {  
      const address = this.form.email
      if (address.trim().length == 0) { return }
      console.log("inviting by email: " + address, evt);
      // (this.$root as any).triggerToast(this.t('emailsent'));
      const $toast = useToast();

      this.state.showSpinner = true;
      const success = userStore.invite(address, this.$props.id).then(resp => {
        console.log("invite response: " + JSON.stringify(resp));
        this.fm.resetForm()
        this.state.showSpinner = false;
        let instance = $toast.success(this.t('emailsent'), {position: 'top', duration: 50000});
      }).catch(err => {
        console.log("error: " + err);
        this.state.showSpinner = false
        let instance = $toast.error(this.t('emailfailed'), {position: 'top', duration: 5000});
      })        
      
      return false
    },
  }
})
