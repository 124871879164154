
import {defineComponent, PropType} from "vue"
import moment from 'moment'
import userStore from '@/stores/user'
import channelStore from '@/stores/channel'
import { Pound, Pencil, CheckboxIntermediate, PlusCircleOutline, MinusCircleOutline } from 'mdue'
import { useI18n } from "vue-i18n";
import GroupModal from "@/components/groups/GroupModal.vue";
import { User, Message, ChannelList, Channel, OauthResponse, SearchResults} from '@/types'
import router from "@/router";

const messages = {
  "en": {
    created: "created",
    accept: "accept",
    decline: "decline",
    edit: "edit",
    posts: "posts",
  },
  "nb_NO": {
    created: "opprettet",
    accept: "bli med",
    decline: "avslå",
    edit: "rediger",
    posts: "innlegg",
  }
}

export default defineComponent({
  // props: ["channel", "me", "date", "count"],
  // props: {
  //   channel, //{} as Channel,
  //   me: { type: String, default: '' }
  // },


  props: {
    channel: { type: Object as PropType<Channel>, default: () => ({}) },
    me: { type: Object as PropType<User>, default: () => ({}) },
    date: { type: Boolean, default: false },
    count: { type: Boolean, default: false },
  },


  components: { PlusCircleOutline, MinusCircleOutline },
  data() {
    return {
      imagePrefix: process.env.VUE_APP_BACKEND + '/images/'
    }
  },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    return { userStore, t }
  },
  methods: {
    dateTime(value: string) {
      moment(value).format('YYYY-MM-DD');
    },

    goto(channelId: string, goto: boolean) {
      console.log("navigating to channel: " + channelId + " go: " + goto);
      if (goto) {
        router.push({name: 'channel', params: {'id': channelId}});
      }
      // return true;
      return false;
    },
    
    amSubscribed() {
      try {
        let _me = userStore.getters.id // this.$props.me
        if (this.$props.channel.participants) {
          for (let p of this.$props.channel.participants) {
            // console.log("p: " + p)
            if (p.id === _me) {
              return true;
            }
          }
        }
        /*
      console.log("participants: " + this.$props.channel.participants)
      const result = this.$props.channel.participants.filter(
        function(u: any, index: any, array: any) {
          return ((u as User).id === _me)
        });
      console.log("amSubscribed: " + result)*/
      } catch (ex) {
        console.log("ex: " + ex)
      }
      return false
    },
    subscribe: function(e: Event, channelId: string, subscribe: boolean) {
      e.stopPropagation()

      console.log("subscribing: " + channelId);
      channelStore.subscribe("00000000-0000-0000-0000-000000000000", channelId, subscribe)
    },

    dateTime2(value: string) {
      moment.locale("nb_NO")
      const diff = moment(new Date()).diff(value, 'day');
      if (diff < 3) {
        return moment(value).fromNow()
      } else {
        return moment(value).format('MMM Do YYYY')
      }
    }
  }
})
