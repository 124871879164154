
import { defineComponent } from "vue"
import userStore from '@/stores/user'
import { useI18n } from "vue-i18n";
import Back from '@/assets/img/icons/arrow-back.svg'
import Info from '@/assets/img/icons/info-yellow.svg'


const messages = {
  "en": {
    home: "Home",
    back: "Back",
  },
  "nb_NO": {
    home: "Hjem",
    back: "Tilbake"
  }
}

export default defineComponent({
  props: ['back', 'title', 'backTitle', 'image', 'info'],
  components: { Back, Info },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })
    return { userStore, t }
  },
})
