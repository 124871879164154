import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-588db274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-wrapper" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  id: "profiles",
  class: "profiles mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Magnify = _resolveComponent("Magnify")!
  const _component_ProfileBubble = _resolveComponent("ProfileBubble")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.filterList && _ctx.filterList(...args)), ["prevent"])),
    class: "m-3"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Magnify, { class: "icon" }),
      _createElementVNode("input", {
        type: "text",
        ref: "query",
        onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.filterList && _ctx.filterList(...args))),
        class: "form-control border-0",
        placeholder: _ctx.t('search')
      }, null, 40, _hoisted_2)
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredMembers, (profile) => {
        return (_openBlock(), _createElementBlock("li", {
          key: profile.id,
          class: "profile"
        }, [
          _createVNode(_component_ProfileBubble, {
            class: _normalizeClass({active: profile.id == _ctx.state.selectedId}),
            channel: _ctx.group,
            profile: profile,
            onSelectprofile: _ctx.selectProfile
          }, null, 8, ["class", "channel", "profile", "onSelectprofile"])
        ]))
      }), 128))
    ])
  ], 32))
}