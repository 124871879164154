import axios, { AxiosResponse } from 'axios';
import userStore from '@/stores/user'
import { User, Message, ChannelList, Channel, OauthResponse, SearchResults} from '@/types'
import createAuthRefreshInterceptor from 'axios-auth-refresh';


// see https://github.com/Flyrell/axios-auth-refresh
console.log("configuring refresh token interceptor")

// Function that will be called to refresh authorization
export const RefreshAuthLogic = (failedRequest: any) => {
  if (localStorage.getItem("refreshToken")) {
    const params = new URLSearchParams();
    params.append("refresh_token", localStorage.getItem("refreshToken") as string)
    params.append("grant_type", "refresh_token")
    const config = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}

    return axios.post(process.env.VUE_APP_BACKEND + '/oauth2/tokenRefresh', params, config).then(tokenRefreshResponse => {
      console.log("received token refresh response: ", tokenRefreshResponse)
      console.log("using token: " + tokenRefreshResponse.data.access_token)
      localStorage.setItem('token', tokenRefreshResponse.data.access_token)
      localStorage.setItem('refreshToken', tokenRefreshResponse.data.refresh_token)
      localStorage.setItem('tokenType', tokenRefreshResponse.data.token_type)
      axios.defaults.headers.common['Authorization'] = tokenRefreshResponse.data.token_type + " " + tokenRefreshResponse.data.access_token;
      // failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
      return Promise.resolve();
    })
  } else {
    console.log("no refresh token, bailing")
    return Promise.resolve(undefined)
  }
}


await RefreshAuthLogic(null);

// Instantiate the interceptor
// console.log('adding auth refresh logic')
// createAuthRefreshInterceptor(axios, RefreshAuthLogic);
// axios.defaults.headers.common['Authorization'] = localStorage.getItem("tokenType") + " " + localStorage.getItem("token") 



// https://medium.com/front-end-weekly/how-to-wrap-axios-inside-rxjs-with-typescript-and-react-6c21e47dcb63

// fetch post 
// https://jasonwatmore.com/post/2020/04/30/vue-fetch-http-post-request-examples
// axios post
// https://jasonwatmore.com/post/2020/07/23/vue-axios-http-post-request-examples

// fetch or axois: https://blog.logrocket.com/axios-or-fetch-api/
// ditto: https://blog.bitsrc.io/requests-in-vuejs-fetch-api-and-axios-a-comparison-a0c13f241888

// axois and typescript; https://github.com/axios/axios#typescript

// refresh token code flow: https://developer.okta.com/docs/guides/refresh-tokens/get-refresh-token/#get-a-refresh-token-with-the-code-flow

/*export const instance = axios.create({
  baseURL: AppSettings.BACKEND
});*/

export async function login(username: string, password: string): Promise<OauthResponse> {
  console.log("logging in at " + process.env.VUE_APP_BACKEND)
  const user = { username: username, password: password, grant_type: 'password', scope: 'public' }
  
  const params = new URLSearchParams();
  params.append("username", username)
  params.append("password", password)
  params.append("grant_type", "password")
  params.append("scope", "public")
  
  const requestOptions = { method: "POST", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: params }
  const request = await fetch(process.env.VUE_APP_BACKEND + '/oauth2/token', requestOptions);
  console.log("request: " + JSON.stringify(request))
  return await request.json()
}

// export async function getUser(): Promise<User> {
//   const requestOptions = { method: "GET", headers: { 'Authorization': 'Bearer ' + userStore.getters.authToken } }
//   const request = await fetch(AppSettings.BACKEND + '/api/v1/user', requestOptions)
//   return await request.json()
// }

// export async function saveUser(givenName: string, middleName: string, surName: string, yearBorn: number) {
export async function saveUser(user: User) { //
  const requestOptions = { 
    method: "POST", 
    headers: { 'Authorization': 'Bearer ' + userStore.getters.authToken },
    // body: JSON.stringify({ givenName: givenName, surName: surName, middleName: middleName })
    body: JSON.stringify( user, removeMeta)
  }
  
  const request = await fetch(process.env.VUE_APP_BACKEND + '/api/v1/user', requestOptions)
  return await request.json()
}

export async function getChannels(): Promise<SearchResults<Channel>> {
  console.log("requests:getChannels()")
  const requestOptions = { method: "GET", headers: { 'Authorization': 'Bearer ' + userStore.getters.authToken, "Content-Type": "application/json" } }
  const request = await fetch(process.env.VUE_APP_BACKEND + '/api/v1/channels?messages=true', requestOptions)

  // console.log(response.data.items);
  // need to augment channel entries to include the channel content
  // var ch = {}
  // response.data.items.forEach((item) => {
  //   ch[item.id] = {"meta": item, 'channelContent': '', 'unreadCount': 0}
  // })
  // preprocess preloaded messages

  // console.log("channels: " + JSON.stringify(ch));
  
  return await request.json()
}

export async function getMessages(id: string, count: number): Promise<Message[]> {
  console.log("requests:getMessages()")
  const requestOptions = { method: "GET", headers: { 'Authorization': 'Bearer ' + userStore.getters.authToken, "Content-Type": "application/json" } }
  const request = await fetch(process.env.VUE_APP_BACKEND + `/api/v1/channels/${id}/messages?count=${count}`, requestOptions)

  // console.log(response.data.items);
  // need to augment channel entries to include the channel content
  // var ch = {}
  // response.data.items.forEach((item) => {
  //   ch[item.id] = {"meta": item, 'channelContent': '', 'unreadCount': 0}
  // })
  // preprocess preloaded messages

  // console.log("channels: " + JSON.stringify(ch));

  return await request.json()
}

export async function getMessages2(query: string, count: number): Promise<Message[]> {
  console.log("requests:getMessages()")
  const requestOptions = { method: "GET", headers: { 'Authorization': 'Bearer ' + userStore.getters.authToken, "Content-Type": "application/json" } }
  const request = await fetch(process.env.VUE_APP_BACKEND + `/api/v1/messages?query=${query}&count=${count}`, requestOptions)

  // console.log(response.data.items);
  // need to augment channel entries to include the channel content
  // var ch = {}
  // response.data.items.forEach((item) => {
  //   ch[item.id] = {"meta": item, 'channelContent': '', 'unreadCount': 0}
  // })
  // preprocess preloaded messages

  // console.log("channels: " + JSON.stringify(ch));

  return await request.json()
}

// using fetch https://jasonwatmore.com/post/2020/04/30/vue-fetch-http-post-request-examples
// https://blog.logrocket.com/how-to-make-http-requests-like-a-pro-with-axios/

export async function loginx(username: string, password: string) {
    try {
        const user = { username: username, password: password, grant_type: 'password', scope: 'public' }
        await axios.post(process.env.VUE_APP_BACKEND + '/oauth2/token', user)
          .then((response) => {
              console.log(response.data);
              console.log(response.status);
              console.log(response.statusText);
              console.log(response.headers);
              console.log(response.config);
          });
        
        // user = data.userDetails;
        // console.log(data)
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error);
        } else {
            console.log(error);
        }
    }    
}

export async function login3(username: string, password: string) {
    console.log("authenticating: ", username);

    // oauth struct
    const reqStruct = { username: username, password: password, grant_type: 'password', scope: 'public' }

    const requestOptions = {method: "POST", headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqStruct)
        };
    const request = await fetch(process.env.VUE_APP_BACKEND + '/oauth2/token', requestOptions)
          .then(response => response.json())
          .then(data => (console.log(data)))
    

    /*
    axios({url: backend + '/oauth2/token', data: user, method: 'POST'})
      .then(resp => {
          console.log("response: " + JSON.stringify(resp));
          const token = resp.data.token
          const user = resp.data.user
          localStorage.setItem('token', token)
          // Add the following line:
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', token, user)
          resolve(resp)
      })
      .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
      })*/    
    
    

    // using axios-oauth-client, https://www.npmjs.com/package/axios-oauth-client
    // console.log("config: " + JSON.stringify(state));
    /*oauth.client(axios.create(), {
        url: `${process.env.VUE_APP_BACKEND}/oauth2/token`,
        grant_type: 'password',
        username: username,
        password: password,
        scope: 'public'
    })().then((resp: AxiosResponse) => {
        console.log("authorization: " + JSON.stringify(resp));
        console.log("logged in: " + resp.data.properties.uid)

        console.log("response: " + JSON.stringify(resp));
        // const token = resp.data.token
        // const user = resp.data.user
        localStorage.setItem('token', resp.data.access_token)
        localStorage.setItem('uid', resp.properties.uid);
        // Add the following line:
        axios.defaults.headers.common['Authorization'] = resp.token_type + " " + resp.access_token;
        console.log("using auth header: '" + resp.token_type + " " + resp.access_token + "'");
        commit('auth_success', resp, user)
        resolve(resp)
    }).catch(err => {
        console.log(err), reject(err)
    });
    
     */
}

export async function register(username: string, password: string) {
  console.log("endpoint: " + process.env.VUE_APP_BACKEND + '/api/v1/register')
  axios({url: process.env.VUE_APP_BACKEND + '/api/v1/register', data: {username: username, password: password}, method: 'POST'})
    .then(resp => {
      console.log("register response: " + JSON.stringify(resp));
    })
    .catch(err => {
      console.log("error: " + err);
      // commit('auth_error', err)
    })
}

function removeMeta(key: any, value: any) {
  if (key == "id") {
    return undefined;
  } else if (key == "createdAt") {
    return undefined;
  }
  return value;
}
