import { computed, reactive } from 'vue'
import * as Request from '@/requests'
import axios, { AxiosResponse } from 'axios';
import {Message, User} from "@/types";
import {useRouter} from "vue-router";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {useI18n} from "vue-i18n";
import setLocaleX from "@/main";

console.log('adding auth refresh logic')
createAuthRefreshInterceptor(axios, Request.RefreshAuthLogic);
axios.defaults.headers.common['Authorization'] = localStorage.getItem("tokenType") + " " + localStorage.getItem("token")

// https://medium.com/@mario.brendel1990/vue-3-the-new-store-a7569d4a546f

// add timeout to check loaded state


const state = reactive({
  name: '',
  id: localStorage.getItem('id') || '',
  uid: localStorage.getItem('uid') || '',
  token: localStorage.getItem("token") || '',
  ts: localStorage.getItem("ts") || '',
  refreshToken: '',
  error: '',
  user: {} as User,
  keywords: {} as Array<String>,
  users: [] as Array<User>,
})

const getters = reactive({
  isLoggedIn: computed(() => state.token !== '' ),
  isPending: computed(() => false ),
  authToken: computed( () => state.token ),
  imageUrl: computed(() => { return state.id === '' ? '' : process.env.VUE_APP_BACKEND + '/images/' + state.id }),
  imageref: computed( () => { return state.user === {} as User ? '' : state.user.imageref }),
  keywords: computed( () => { return state.keywords }),
  uid: computed ( () => state.uid),
  email: computed ( () => state.user.email),
  user: computed ( () => state.user),
  id: computed ( () => state.id),

  // https://stackoverflow.com/questions/40238144/safe-navigation-operator-or-and-null-property-paths
  // need to use safe operators
  
  givenName: computed ( () => { return state.user ? state.user.givenName ? state.user.givenName : '' : ''}),
  surName: computed ( () => { return state.user ? state.user.surName ? state.user.surName : '' : ''}),
  companyName: computed ( () => { return state.user ? state.user.companyName ? state.user.companyName : '' : ''}),
  hasCompany: computed ( () => { return state.user && state.user.companyName && state.user.companyName.length > 0 }),
  initials: computed( () => {
    let initials = ""
    if (state.user) {
      initials = `${state.user?.givenName?.charAt(0)}${state.user.surName.charAt(0)}`
      if (initials.length == 0) {
        initials = `${state.user?.email.charAt(0)}${state.user?.email.charAt(1)}`
      }
    }
    return initials
  }),
})


const actions = {
  async getUser2(): Promise<any> {
    console.log("auth: ", axios.defaults.headers.common['Authorization'])
    return axios.get(process.env.VUE_APP_BACKEND + '/api/v1/user')
      .then(resp => {
        console.log("get user response: ", resp);
        // this.setProfile(resp.data)
        state.user = resp.data


        setLocaleX(state.user.locale)        
        // set current locale

        axios.get(process.env.VUE_APP_BACKEND + '/api/v1/user/keywords')
          .then(resp => {
            console.log("keywords:", resp.data.data);
            state.keywords = resp.data.data
            return resp.data
          })
          .catch(err => {
            console.log("error: " + err)
          })
        return resp.data
      })
      .catch(err => {
        console.log("error: " + err)
        // commit('auth_error', err)
      })
  },
  async getUsers(query: string, cb: (users: User[]) => any) {

    console.log("Fetching users matching query: " + query)

    const count = 20
    const queryParams = new URLSearchParams(Object.assign({ "count": count }))
    if (query && query.length > 0) {
      queryParams.append("query", query)
    }

    const queryString = new URLSearchParams(queryParams).toString()

    axios.get (process.env.VUE_APP_BACKEND + `/api/v1/users?${queryString}`)
      .then(resp => {
        console.log("user.ts:getUsers())", resp.data.items);
        state.users = resp.data.items
        cb(resp.data.items)
        return resp.data.items
      })
      .catch(err => {
        console.log("error: " + err)
      })
  },
  
  async getUser() {
    // const user = await Request.getUser()
    // if (user == null) return
    // state.user = user 
    // state.name = user.name
    // state.username = user.username
    // state.token = user.token

    const self = this
    console.log("auth: ", axios.defaults.headers.common['Authorization'])
    axios.get (process.env.VUE_APP_BACKEND + '/api/v1/user')
      .then(resp => {
        console.log("get user response: ", resp);
        // this.setProfile(resp.data)
        state.user = resp.data
        return resp.data
      })
      .catch(err => {
        console.log("error: " + err)
        // commit('auth_error', err)
      })

  },
  async getUserProfile(id: string): Promise<User> {
    return axios.get (process.env.VUE_APP_BACKEND + '/api/v1/users/' + id)
      .then(resp => {
        console.log("get user response: ", resp);
        return resp.data
      })
      .catch(err => {
        console.log("error: " + err)
        return null
      })
  },
  
  // async updateUser(givenName: string, middleName: string, surName: string, yearBorn: number): Promise<any> {
  // async updateUser(givenName: string, middleName: string, surName: string, yearBorn: number): Promise<any> {
  //   const response2 = await Request.saveUser(givenName, middleName, surName, yearBorn)
  async updateUser(user: User): Promise<any> {
    console.log("updateUser");
    return axios.post(process.env.VUE_APP_BACKEND + '/api/v1/user', 
      JSON.stringify(user, removeEmptyIds))
      .then(resp => {
        console.log("update user response: ", resp);
        return resp.data
      })
      .catch(err => {
        console.log("error: " + err)
        return null
      })
    /*const response2 = await Request.saveUser(user)
      .then((response) => {
        console.log("updating user store with data: ", response)
        // userStore.
        // this.$router.push('/me')

        // response from update: {"id":"f2caaf3c-beb7-41b7-870a-257125611229","createdAt":"2021-05-03T15:01:54.146147+10:00","updatedAt":"2021-11-18T23:04:44.060318+10:00","email":"torgeir@karriere.no","givenName":"Torgeir","surName":"Veimo","lastLogin":"2021-11-18T23:04:19.558459+10:00"}
        return response
      })
    return response2*/
  },

  // https://masteringjs.io/tutorials/axios/post-json
  async updateSettings(update: any): Promise<any> {
    return axios.post(process.env.VUE_APP_BACKEND + '/api/v1/user/settings',
      JSON.stringify(update))
      .then(resp => {
        console.log("update settings response: ", resp);
        state.user = resp.data
        return resp.data
      })
      .catch(err => {
        console.log("error: ", err)
        return null
      })
  },
  async updateNotification(update: any): Promise<any> {
    return axios.post(process.env.VUE_APP_BACKEND + '/api/v1/user/notifications',
      JSON.stringify(update))
      .then(resp => {
        console.log("update notification response: ", resp);
        // state.user = resp.data
        return resp.data
      })
      .catch(err => {
        console.log("error: ", err)
        return null
      })
  },

  async keyword(keyword: string, add: boolean) {
    //addKeyword
    console.log("keyword: " + keyword + ", adding? " + add)
    const resp = await axios.post(process.env.VUE_APP_BACKEND + '/api/v1/user/keyword', {
      keyword: keyword,
      add: add })
      .then(resp => {
        console.log("keyword()", resp.data)

        axios.get(process.env.VUE_APP_BACKEND + '/api/v1/user/keywords')
          .then(resp => {
            console.log("keywords:", resp.data.data);
            state.keywords = resp.data.data
            return resp.data.data
          })
          .catch(err => {
            console.log("error: " + err)
          })

      })
      .catch(err => {
        console.log("error: " + err)
      })
    
  },
  async login(username: string, password: string): Promise<boolean> {

    console.log("logging in user: " + username)
    /*const data = await Request.login(username, password)
    if (data == null) {
      state.error = 'Could not find user'
      return false
    }*/

    // const user = { username: username, password: password, grant_type: 'password', scope: 'public' }

    const params = new URLSearchParams();
    params.append("username", username)
    params.append("password", password)
    params.append("grant_type", "password")
    params.append("scope", "public")
    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

    const router = useRouter()
    
    return axios.post(process.env.VUE_APP_BACKEND + '/oauth2/token', params, config )
      .then(resp => {
        console.log("resp", resp)
        const data = resp.data
        // localStorage.setItem('user-token', token) // store the token in localstorage
        // resolve(resp)

        // use old token for testing refresh_token
        // iWbWEYCPeSeF/ZHmAT4V5fTYpCdzdK+uCQwHOT605Eco+Ji0mf9rPltuljgIxRN2i3xoJez86PStC+i1FYZQ2FU+vql9ka3p+El89Gqd89hAk1nVPYupERElHAgfhqkwO0dYesOlrrJ6XbySZCkd0vJ1+kPoJuagffzQJh6715UVRk0RJlsu43dRUzUDOdpS2qy0Sxf+Fr46ylN89VqmlRX/KpEmSKs15MU6m19ZVFsXtAmFB1U/I24m9Zrl54IEiM/YZ/hhQamknPTrdwxMTN1sCDeQf2z/844U8g==
        
        console.log("data: " + JSON.stringify(data))

        // state.name = data.name
        state.token = data.access_token
        state.uid = data.properties.uid
        state.id = data.properties.id
        state.ts = data.properties.ts
        state.error = ''

        localStorage.setItem('token', data.access_token)
        localStorage.setItem('refreshToken', data.refresh_token)
        localStorage.setItem('tokenType', data.token_type)
        localStorage.setItem('uid', data.properties.uid)
        localStorage.setItem('id', data.properties.id)
        localStorage.setItem('ts', data.properties.ts)

        // if using axios: 
        axios.defaults.headers.common['Authorization'] = data.token_type + " " + data.access_token
        // Request.instance.defaults.headers.common['Authorization'] = data.token_type + " " + data.access_token
        console.log("auth: ", axios.defaults.headers.common['Authorization'])


        const immediatelyResolvedPromise = (url: string) => {
          const resultPromise = new Promise((resolve, reject) => {
            // resolve(fetch(url))
            this.getUser2()
          })
          return resultPromise
        }
        
        return true
        
      })
      .catch(err => {
        // localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
        // reject(err)
        console.error("login failed")
        state.error = "login failed"
        return false
      })

    // const requestOptions = { method: "POST", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: params }
    // const request = await fetch(AppSettings.BACKEND + '/oauth2/token', requestOptions);
    // console.log("request: " + JSON.stringify(request))
    // return await request.json()
  },
  async register(email: string, password: string) {
    console.log("will try to register: ", email)
    // const response = await Request.register(email, password)
    
    const response = await axios({url: process.env.VUE_APP_BACKEND + '/api/v1/register', data: { email: email, password: password}, method: 'POST'})
      .then(resp => {
        console.log("register response: " + JSON.stringify(resp));
      })
      .catch(err => {
        console.log("error: " + err);
        throw err
        // commit('auth_error', err)
      })
  },

  invite(email: string, groupId: string): Promise<boolean> {
    // async invite(email: string, groupId: string): Promise<boolean> {
    console.log("sending invite email to: ", email)
    /*
    
const sendGetRequest = async () => {
    try {
        const resp = await axios.get('https://jsonplaceholder.typicode.com/posts');
        console.log(resp.data);
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
};    
  */
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_BACKEND + '/api/v1/invite',
        data: {email: email, groupId: groupId},
        method: 'POST'
      }).then((response) => {
        resolve(response.data as boolean);
      }, (err) => {
        reject(err);
      })
      
      
      
      /*this.axios.post('/account/getapps').then((response) => {
        resolve(response.data as boolean);
      }, (err) => {
        reject(err);
      });*/
      
    
    })




    /*const req = async () => {
      const resp = await axios({
        url: process.env.VUE_APP_BACKEND + '/api/v1/invite',
        data: {email: email, groupId: groupId},
        method: 'POST'
      })
        .then(resp => {
          console.log("register response: " + JSON.stringify(resp));
          if (resp.status === 200) {
            return true
          }
          return false
          // return resp
        })
        .catch(err => {
          console.log("error: " + err);
          // throw err
          return false
        })
    }
    return req*/
    // return response

    /*const response = await axios({url: process.env.VUE_APP_BACKEND + '/api/v1/invite', data: { email: email, groupId: groupId}, method: 'POST'})
      .then(resp => {
        console.log("register response: " + JSON.stringify(resp));
        if (resp.status === 200) { 
          return true
        }
        return false
        // return resp
      })
      .catch(err => {
        console.log("error: " + err);
        // throw err
        return false
      })
    return response*/
  },
  
  async verify2(token: string) {
    return new Promise((resolve, reject) => {
      console.log("token struct: " + JSON.stringify(token));

      // the verify oauth2 endpoint takes a plain form with certain attributes

      // TokenResponse tokenResponse = target.request(MediaType.APPLICATION_JSON_TYPE)
      //     .post(Entity.entity(form, MediaType.APPLICATION_FORM_URLENCODED_TYPE), TokenResponse.class);

/*      
      let tokenForm = {
        "grant_type": "authorization_code",
        "client_id": "heldel",
        "client_secret": token,
        "scope": "user.read user.write profile"
      }

      axios({url: `${process.env.VUE_APP_BACKEND}/oauth2/verify`, data: tokenForm, method: 'POST'})
          .then(resp => {
            console.log("verification succeeded!");
            const token = resp.data.token
            const user = resp.data.user
            localStorage.setItem('token', token)
            // Add the following line:
            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', token, user)
            resolve(resp)
          })
          .catch(err => {
            console.log("verification failed");
            commit('auth_error', err);
            localStorage.removeItem('token');
            reject(err)
          });*/

      // using axios-oauth-client, https://www.npmjs.com/package/axios-oauth-client
      // console.log("config: " + JSON.stringify(state));
      /*oauth.client(axios.create(), {
        url: `${process.env.VUE_APP_BACKEND}/oauth2/verify`,
        grant_type: "authorization_code",
        client_id: "heldel",
        client_secret: token.token,
        scope: "user.read user.write profile"
        // token: user.username,
        // password: user.password,
      })().then(resp => {
        console.log("authorization: " + JSON.stringify(resp));
        console.log("logged in: " + resp.properties.uid)

        console.log("response: " + JSON.stringify(resp));
        // const token = resp.data.token
        // const user = resp.data.user
        localStorage.setItem('token', resp.access_token)
        localStorage.setItem('uid', resp.properties.uid);
        // Add the following line:
        axios.defaults.headers.common['Authorization'] = resp.token_type + " " + resp.access_token;
        console.log("using auth header: '" + resp.token_type + " " + resp.access_token + "'");
        // commit('auth_success', resp, user)
        resolve(resp)
      }).catch(err => { console.log(err), reject(err) });*/
    })
  },

  async resetpassword(email: string) {
    console.log("sending password reset mail for: ", email)
    const response = await axios({url: process.env.VUE_APP_BACKEND + '/api/v1/resetpassword', data: { email: email }, method: 'POST'})
      .then(resp => {
        console.log("register response: " + JSON.stringify(resp));
      })
      .catch(err => {
        console.log("error: " + err);
      })

  },
  
  async verify(token: string) {
    console.log("verifying using token: " + token)

  // the verify oauth2 endpoint takes a plain form with certain attributes
  
  // TokenResponse tokenResponse = target.request(MediaType.APPLICATION_JSON_TYPE)
  //     .post(Entity.entity(form, MediaType.APPLICATION_FORM_URLENCODED_TYPE), TokenResponse.class);

    /*let tokenForm = {
      "grant_type": "authorization_code",
      "client_id": "heldel",
      "client_secret": token,
      "scope": "user.read user.write profile"
    }*/

    const params = new URLSearchParams()
    params.append("grant_type", "authorization_code")
    params.append("client_id", "heldel")
    params.append("client_secret", token)
    params.append("scope", "user.read user.write profile")

    /*const tokenForm = {
      "grant_type": "authorization_code",
      "client_id": "heldel",
      "client_secret": token,
      "scope": "user.read user.write profile"
    }*/
    
    const config = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
    
    const router = useRouter()

    // axios({url: `${process.env.VUE_APP_BACKEND}/oauth2/verify`, data: tokenForm, method: 'POST'})
    
    return axios.post(process.env.VUE_APP_BACKEND + '/oauth2/verify', params, config)
    .then(resp => {
      console.log("resp", resp)
      const data = resp.data
      // localStorage.setItem('user-token', token) // store the token in localstorage
      // resolve(resp)
    
      // use old token for testing refresh_token
      // iWbWEYCPeSeF/ZHmAT4V5fTYpCdzdK+uCQwHOT605Eco+Ji0mf9rPltuljgIxRN2i3xoJez86PStC+i1FYZQ2FU+vql9ka3p+El89Gqd89hAk1nVPYupERElHAgfhqkwO0dYesOlrrJ6XbySZCkd0vJ1+kPoJuagffzQJh6715UVRk0RJlsu43dRUzUDOdpS2qy0Sxf+Fr46ylN89VqmlRX/KpEmSKs15MU6m19ZVFsXtAmFB1U/I24m9Zrl54IEiM/YZ/hhQamknPTrdwxMTN1sCDeQf2z/844U8g==
    
      console.log("data: " + JSON.stringify(data))
    
      // state.name = data.name
      state.token = data.access_token
      state.uid = data.properties.uid
      state.id = data.properties.id
      state.ts = data.properties.ts
      state.error = ''
    
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('refreshToken', data.refresh_token)
      localStorage.setItem('tokenType', data.token_type)
      localStorage.setItem('uid', data.properties.uid)
      localStorage.setItem('id', data.properties.id)
      localStorage.setItem('ts', data.properties.ts)
    
      // if using axios: 
      axios.defaults.headers.common['Authorization'] = data.token_type + " " + data.access_token
      // Request.instance.defaults.headers.common['Authorization'] = data.token_type + " " + data.access_token
      console.log("auth: ", axios.defaults.headers.common['Authorization'])
      return true
    
    })
    .catch(err => {
      // localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
      // reject(err)
      console.error("verify failed")
      state.error = "verify failed"
      return false
    })
  },  


  async logout() {
    console.log('logging out user')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('tokenType')
    localStorage.removeItem('uid')
    localStorage.removeItem('id')
    localStorage.removeItem('ts')
    // delete axios.defaults.headers.common["Authorization"]
    state.name = ''
    state.uid = ''
    state.id = ''
    state.token = ''
    state.refreshToken = ''
    state.error = '',
    state.user = {} as User,
    state.ts = ''
  }
}

function setLocale(localeString: string) {
  // const { t, locale, availableLocales } = useI18n({ messages: messages, useScope: 'global' })

  console.log("locale:", localeString)
  // return { form, userStore, t, locale, availableLocales, interestSheet, }
  
}

function removeEmptyIds(key: any, value: any) {
  // console.log("key: " + key + ", value: " + value)
  if (key == "id") {
    if (value === '') {
      console.log("removing value for key: " + key)
      return undefined;
    }
  }
  return value;
}

export default { state, getters, ...actions }
