<template>
<!--  <BreadcrumbsBar title="Hjelp" />-->
  <div class="bc mb-3"><h2 class="bc-item bc-title">Informasjon</h2></div>  
  <div class="container-fluid" v-if="userStore.getters.isLoggedIn">
    
    <!--
    <h1>Hvordan bruke HelDel?</h1>
    <p>Heldel laster som en webapp på din telefon eller datamaskin. Den vil etterhvert kunne pakkes og gjøres tilgjengelig
    som en app som kan installeres via app store. Heldel slik den er nå vil kunne legges til din home screen på din telefon
    og virke som en annen app i mellomtiden.</p>
    <p>Ikke alle funksjoner er implementert enda, men vi vil fortløpende gjøre tilgjengelig nye funskjoner så snart de
    blir tilgjengelige. Hvis du trenger hjelp kan du sende en melding til meg ved å trykke
      <a href="mailto:torgeir@karriere.no">her</a>.</p>
    <p lang="la">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    <h1 lang="la">Finibus Bonorum et Malorum</h1>
    <p lang="la">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>
    -->
    <h1>Hei, velkommen til Heldel</h1>
    <p>Så hyggelig at du kom innom.</p>
    <p>Heldel.no er et nytt produkt fra Karriere.no. Dette er en beta-versjon. Vi vil at du skal teste den.
      Heldel er laget i samarbeid med SALT Lofoten, SMB Norge og Framtidsfylket Vestland.</p>
    <h1>Hva er Heldel?</h1>
    <p>Målet med Heldel er å bidra til at det blir enklere for bedrifter å samarbeide om tilgangen til arbeidkraft.
      Det kan være å samarbeid om å rekruttere nye personer til en delt-stilling, utlån av personer i en begrenset
      periode, samarbeidsprosjekter, eller samlet oppdrag. Heldel handler om å vise frem behovene og skape kontakt
      og dialog (og forhåpentligvis en løsning). Det hele handler om å tenke helhet og deling (derav navnet).
      Samarbeidet kan skje mellom bedrifter i samme bransje, samme region eller med allerede eksisterende grupper.</p>
    <p>Heldel en en oppdrag og kommunikasjonskanal. Du som bruker er medlem av en eller flere grupper. En gruppe er
      enten åpen eller lukket. Gruppene består av personer fra bedrifter som ønsker å samarbeide. Medlemmene av en 
      gruppe har mulighet til legge ut et behov eller en mulighet. De andre i gruppa kan komme med sine kommentarer 
      og svar. Målet er å komme frem til en løsning sammen.</p>
    <p>Eksempel: «Hei, vi søker etter en ny markedsfører i vårt firma, vi har imidlertid ikke ressurser til en 100% 
      stilling, er det noen som har samme behovet som kunne tenkt seg å dele 50/50»</p>
    <p>Svar: «Hei, har du noen kandidater? Jeg kjenner en som i dag jobber 50% hos Bedrift AS, skal jeg be henne 
      kontakte dere?»</p>
    <p>Svar: «Ja, bare send meg kontaktinfo, så tar jeg opp tråden»</p>
    <h1>Hvordan bruke HelDel?</h1>
    <p>Det første du må gjøre er å registrere en bruker. Men ettersom du leser dette så har du allerede klart det.</p>
    <p>Det neste er å opprette en gruppe og invitere dine samarbeidspartnere inn i gruppa. Når de er ombord så er
      dere klare. Hvis du er invitert av noen så er du allerede medlem av gruppa. Du kan også søke igjennom grupper 
      og melde deg inn om de er åpne eller spørre om å delte i lukkede grupper.</p>
    <p>I en gruppe kan du enten be om hjelp (legg inn et innlegg) eller hjelp til (send et svar). Når gruppa har
      funnet en løsning så lukkes innlegget. En gruppe kan jobbe med flere innlegg samtidig.</p>
    <p>Som medlem av grupper vil du få varsler om noen ber om hjelp (innlegg) eller nye forslag til hjelp (svar) kommer inn.</p>
    <p>Om du trenger hjelp så sender du en epost hit (torgeir@karriere.no)</p>
    <p>Lykke til.</p>


  </div>
  <div class="container-fluid" v-if="!userStore.getters.isLoggedIn">
    Heldel.no er et prosjekt fra Karriere.no.
  </div>
</template>

<script type="ts">
import userStore from '@/stores/user'
import {defineComponent} from "vue";

export default defineComponent({
  components: {  },
  setup() {
    return { userStore }
  }
})
</script>
