import axios from "axios";

// see https://v3.vuejs.org/guide/custom-directive.html#hook-functions

export default {
  install: (app, options) => {
    app.directive('auth-image', {
      mounted(el, binding) {
        // console.log("mounted")
        setImgSrc(el, binding);
      },
      bind: function(el, binding) {
        // console.log("bind")
        setImgSrc(el, binding);
      },
      componentUpdated: function(el, binding) {
        // console.log("updated")
        setImgSrc(el, binding);
      }
    });    
  }
}

function setImgSrc(el, binding) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    var imageUrl = binding.value;
    axios({ method: 'get', url: imageUrl, responseType: 'arraybuffer' })
      .then(function(resp) {
        var mimeType = resp.headers['content-type'].toLowerCase();
        var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
        el.src = 'data:' + mimeType + ';base64,' + imgBase64;
      }).catch((function() {
      el.src = imageUrl;
    }));
  }
}
