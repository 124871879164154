import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa255394"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  ref: "element",
  id: "editPostingModal",
  "data-bs-backdrop": "static",
  tabindex: "-1",
  "aria-labelledby": "editPostingModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-fullscreen" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = {
  type: "button",
  class: "btn",
  "data-bs-dismiss": "modal",
  "aria-label": "Lukk"
}
const _hoisted_5 = {
  key: 0,
  class: "modal-title",
  id: "editPostingModalLabel"
}
const _hoisted_6 = {
  key: 1,
  class: "modal-title",
  id: "editPostingModalLabel2"
}
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "m-3 d-flex" }
const _hoisted_9 = {
  key: 0,
  class: "avatar-img avatar-img-lg"
}
const _hoisted_10 = {
  key: 1,
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
  alt: ""
}
const _hoisted_11 = { class: "d-flex flex-column ms-3" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { class: "modal-body" }
const _hoisted_16 = { class: "mb-3" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { class: "mb-3" }
const _hoisted_19 = { class: "sepa" }
const _hoisted_20 = { class: "form-floating mb-4" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = { for: "location" }
const _hoisted_23 = { class: "form-floating mb-4" }
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { for: "deadline" }
const _hoisted_26 = { class: "mb-3" }
const _hoisted_27 = { for: "newPostingUrl" }
const _hoisted_28 = ["placeholder"]
const _hoisted_29 = { class: "select-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_Briefcase = _resolveComponent("Briefcase")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_vue_bottom_sheet = _resolveComponent("vue-bottom-sheet")!
  const _component_Form = _resolveComponent("Form")!
  const _directive_auth_image = _resolveDirective("auth-image")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-content modal-content-rounded", {'job': _ctx.job, 'people': !_ctx.job}])
        }, [
          _createVNode(_component_Form, {
            onSubmit: _ctx.onSubmit,
            "validation-schema": _ctx.schema,
            class: "form-vertical"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", _hoisted_4, [
                  _createVNode(_component_Close)
                ]),
                (_ctx.form.id)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.t('headingEdit')), 1))
                  : _createCommentVNode("", true),
                (!_ctx.form.id)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(_ctx.t('heading')), 1))
                  : _createCommentVNode("", true),
                (!_ctx.form.id)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 2,
                      class: "btn btn-hover",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                    }, _toDisplayString(_ctx.t('create')), 1))
                  : _createCommentVNode("", true),
                (_ctx.form.id)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      class: "btn btn-hover",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                    }, _toDisplayString(_ctx.t('save')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.userStore.getters.id !=='00000000-0000-0000-0000-000000000000')
                    ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_9, null, 512)), [
                        [_directive_auth_image, _ctx.userStore.getters.imageUrl]
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.userStore.getters.id ==='00000000-0000-0000-0000-000000000000')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_11, [
                    (_ctx.userStore.getters.hasCompany)
                      ? (_openBlock(), _createElementBlock("h2", _hoisted_12, _toDisplayString(_ctx.userStore.getters.companyName), 1))
                      : _createCommentVNode("", true),
                    (_ctx.userStore.getters.hasCompany)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.userStore.getters.givenName) + " " + _toDisplayString(_ctx.userStore.getters.surName), 1))
                      : _createCommentVNode("", true),
                    (!_ctx.userStore.getters.hasCompany)
                      ? (_openBlock(), _createElementBlock("h2", _hoisted_14, _toDisplayString(_ctx.userStore.getters.givenName) + " " + _toDisplayString(_ctx.userStore.getters.surName), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "btn btn-outline-dark btn-rounded",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openTypeSelector && _ctx.openTypeSelector(...args)))
                    }, [
                      _createVNode(_component_Briefcase, { class: "briefcase me-3" }),
                      _createElementVNode("span", null, _toDisplayString(_ctx.postingTypeLabel), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control border-0",
                      rows: "6",
                      id: "newPostingMessage",
                      name: "newPostingMessage",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.newPostingMessage) = $event)),
                      placeholder: _ctx.t('newPostingMessage')
                    }, null, 8, _hoisted_17), [
                      [_vModelText, _ctx.form.newPostingMessage]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_Multiselect, {
                      modelValue: _ctx.form.newPostingTags,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.newPostingTags) = $event)),
                      mode: "tags",
                      "close-on-select": false,
                      "filter-results": false,
                      "min-chars": 2,
                      "resolve-on-load": false,
                      delay: 0,
                      searchable: true,
                      noOptionsText: "",
                      createTag: true,
                      addOptionOn: _ctx.separators,
                      options: async (query ) => { return await _ctx.autocomplete(query) },
                      placeholder: _ctx.t('keywords')
                    }, null, 8, ["modelValue", "addOptionOn", "options", "placeholder"])
                  ]),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.t('ifrelevant')), 1),
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control border-0",
                      id: "location",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.newPostingLocation) = $event)),
                      placeholder: _ctx.t('location')
                    }, null, 8, _hoisted_21), [
                      [_vModelText, _ctx.form.newPostingLocation]
                    ]),
                    _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.t('location')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control border-0",
                      id: "deadline",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.newPostingDeadline) = $event)),
                      placeholder: _ctx.t('deadline')
                    }, null, 8, _hoisted_24), [
                      [_vModelText, _ctx.form.newPostingDeadline]
                    ]),
                    _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.t('deadline')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.t('newPostingUrlLabel')), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control border-0",
                      rows: "6",
                      id: "newPostingUrl",
                      name: "newPostingUrl",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.newPostingUrl) = $event)),
                      placeholder: _ctx.t('newPostingUrl')
                    }, null, 8, _hoisted_28), [
                      [_vModelText, _ctx.form.newPostingUrl]
                    ])
                  ])
                ]),
                _createVNode(_component_vue_bottom_sheet, { ref: "typeSheet" }, {
                  default: _withCtx(() => [
                    _createElementVNode("ul", _hoisted_29, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          href: "#",
                          onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.select('job')), ["prevent"]))
                        }, [
                          _createVNode(_component_Briefcase, { class: "briefcase icon me-3" }),
                          _createTextVNode(_toDisplayString(_ctx.t('needpeople')), 1)
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          href: "#",
                          onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.select('people')), ["prevent"]))
                        }, [
                          _createVNode(_component_Briefcase, { class: "briefcase icon me-3" }),
                          _createTextVNode(_toDisplayString(_ctx.t('needjob')), 1)
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }, 512)
              ])
            ]),
            _: 1
          }, 8, ["onSubmit", "validation-schema"])
        ], 2)
      ])
    ], 512)
  ]))
}