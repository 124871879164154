import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2de520a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-wrapper" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  id: "profiles",
  class: "profiles mt-3"
}
const _hoisted_4 = { class: "search-wrapper" }
const _hoisted_5 = { class: "is-loading" }
const _hoisted_6 = {
  key: 0,
  class: "spinner-border spinner-border-sm"
}
const _hoisted_7 = { class: "invalid-feedback" }
const _hoisted_8 = { class: "sent-feedback" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Magnify = _resolveComponent("Magnify")!
  const _component_ProfileBubble = _resolveComponent("ProfileBubble")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Send = _resolveComponent("Send")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.filterList && _ctx.filterList(...args)), ["prevent"])),
      class: "searchList"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Magnify, { class: "icon" }),
        _createElementVNode("input", {
          type: "text",
          ref: "query",
          onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.filterList && _ctx.filterList(...args))),
          class: "form-control border-0",
          placeholder: _ctx.t('search')
        }, null, 40, _hoisted_2)
      ]),
      (_ctx.state.users.length > 0)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.users, (profile) => {
              return (_openBlock(), _createElementBlock("li", {
                key: profile.id,
                class: "profile"
              }, [
                _createVNode(_component_ProfileBubble, {
                  channelId: _ctx.id,
                  channel: _ctx.state.channel,
                  profile: profile,
                  invite: true,
                  onSubscribeProfile: _ctx.subscribeProfile
                }, null, 8, ["channelId", "channel", "profile", "onSubscribeProfile"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 32),
    _createVNode(_component_Form, {
      ref: "fm",
      onSubmit: _ctx.invite,
      "validation-schema": _ctx.schema,
      class: ""
    }, {
      default: _withCtx(({ errors }) => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.t('heading')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.t('description')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              name: "email",
              type: "text",
              class: _normalizeClass(["form-control", { 'is-invalid': errors.email, 'disabled': _ctx.state.showSpinner }]),
              id: "email",
              initial: "off",
              modelValue: _ctx.form.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
              disabled: _ctx.state.showSpinner,
              required: "",
              autofocus: "",
              placeholder: _ctx.t('email')
            }, null, 8, ["class", "modelValue", "disabled", "placeholder"]),
            (_ctx.state.showSpinner)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(errors.email), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.state.feedback), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            ref: "send",
            class: "btn btn-rounded btn-dark mt-2 mb-5 mx-auto d-block text-nowrap",
            disabled: _ctx.state.showSpinner
          }, [
            _createVNode(_component_Send, { class: "send" }),
            _createTextVNode(" " + _toDisplayString(_ctx.t('sendinvite')), 1)
          ], 8, _hoisted_10)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"])
  ], 64))
}