import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bc mb-3" }
const _hoisted_2 = { class: "home bc-item" }
const _hoisted_3 = {
  key: 0,
  class: "bc-item bc-title"
}
const _hoisted_4 = {
  key: 1,
  class: "app-logo bc-item"
}
const _hoisted_5 = { class: "avatar-handle" }
const _hoisted_6 = {
  key: 0,
  class: "avatar-img"
}
const _hoisted_7 = {
  key: 1,
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
  class: "avatar-img"
}
const _hoisted_8 = {
  key: 2,
  class: "bc-info bc-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Back = _resolveComponent("Back")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Info = _resolveComponent("Info")!
  const _directive_auth_image = _resolveDirective("auth-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.back)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: "",
            to: _ctx.back
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Back)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.userStore.getters.id && _ctx.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            class: "",
            to: "/me"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, [
                (_ctx.userStore.getters.id !=='00000000-0000-0000-0000-000000000000')
                  ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_6, null, 512)), [
                      [_directive_auth_image, _ctx.userStore.getters.imageUrl]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.userStore.getters.id ==='00000000-0000-0000-0000-000000000000')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.info)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            class: "info-wrap",
            to: _ctx.info
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Info, { class: "info" })
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}