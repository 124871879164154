
// export type UserSession = { name: string; username: string; token: string; }

// example token response
// data: {"access_token":"6biVfBgrrTkBcfNUXnR7to14wRrTGPpUU5vRMp1QSiIo+Ji0mf9rPltuljgImEdx2XA6JeD87ab/Xui1SNxc2Axls6R9xKvurEl89D7M9tpIk1nVPYupERElHAgfhqkwOk5YeMClrrN6Xb6SZCsd2/Ry8UbkJuagffzQJh6715UVRk0RJlsu43dRUzUDOdpS2qy0Sxf+Fr46ylN89VqmlRX/KpEmSKs15MU6m19ZVFsXtAmFB1U/I24m9Zrl54IEiM/YZ/hhQamknPTrdwxMTN1sCDeQf2z/844U8g==","refresh_token":"k33CkJ/WcTXIk+94SwhszFaLfknpD7TeYKpap6rlUvwo+JWxsu4mbxwyhDAb0EZ6wHZqFeTl5fT5UvCyX4ZcwQdqtcdyirq1qXNtqGOdq8pDkxDGa97pEVcqEwhOhK4wPk4XftjJprN5Q+qbY3lWhvNw9kDjTeagffDMCQWs1JQvXEtsaAdztilDWScGatNW266gUVnvEL9tiRMipQ+plg68JM55FKYlnINlz1MXS1la9QnKV0M4KXA3vsGrtc1EkMnJIrEqUIW1mvv3LRhUXpxsUGyUYz6g8+EU2p7Z0/LUHhJ01Hu/9cbcNiBsKrc=","token_type":"Bearer","expires_in":3600,"properties":{"id":"a9510182-463b-4202-93e8-7f462c62d2c2","uid":"torgeir@pobox.com"}} user.ts:36

type OauthProps = {
  id: string
  uid: string
}
export type OauthResponse = { access_token: string; refresh_token: string; token_type: string; expires_in: number; properties: OauthProps; }

// export type UserList = Array<UserSession & { password: string }>
export interface User {
  id: string
  email: string
  title: string
  givenName: string
  middleName: string
  surName: string
  companyName: string
  companyUrl: string
  location: string
  phone: string
  description: string
  DOB: string
  imageref: string
  contactPhone: boolean
  contactEmail: boolean
  notifyMessage: boolean
  notifyPost: boolean
  notifyReply: boolean
  locale: string
}

// https://bobbyhadz.com/blog/typescript-class-with-default-values
export class UserImpl implements User {
  id: string = ''
  email: string = ''
  title: string = ''
  givenName: string = ''
  middleName: string = ''
  surName: string = ''
  companyName: string = ''
  companyUrl: string = ''
  location:string = ''
  phone: string = ''
  description: string = ''
  DOB: string = ''
  imageref: string = ''
  contactPhone: boolean = false
  contactEmail: boolean = false
  notifyMessage: boolean = false
  notifyPost: boolean = false
  notifyReply: boolean = false
  locale: string = ''
}


/*class SomePoint implements User {
  constructor(public id: number, public email: string, public title: string) {
    this.id = id;
    this.email = email;
    this.title = title;
  }
}*/

// export class UserProfile implements User {
//
// }

export type Channel = {
  id: string
  createdAt: string
  title: string
  description: string
  participants:	Array<User>
  read: Array<Read>
  // moderators		[]users.User	`json:"-" gorm:"many2many:channel_moderators"`
  // messages		[]Message		`json:"messages" gorm:"foreignkey:ChannelID"`
}


export type Throbber = {
  email: string
  userId: string
  id: string
}

export type Call = {
  type: string
  recipientId: string
  id: string
  connection: string
}


export type Protocol = {
  type: string
  id: string
  throbber: Throbber
  message: Message
  call: Call
}

export type Message = {
  id: string
  channelId: string
  channel: Channel
  createdAt: Date
  title: string
  message: string
  recipientId: string
  inReplyToId: string
  messageType: string
  systemFlags: string
  postingType: string
  email: string
  userId: string
  replies: Array<Message>
  recipient: User
  location: string
  deadline: string
  user: User
  read: Array<Read>
  likes: Array<Like>
  tags: Array<string>
  externalUrl: string
}

export type Read = {
  channelId: string
  readAt: Date
  userId: string
}

export type Like = {
  messageId: string
  likeAt: Date
  userId: string
}

export type SearchResults<T> = {
  items: [T]
  offset: number
  page: number
  pageCount: number
  pageSize: number
  totalCount: number
}
export type ChannelList = Array<Channel>

// class ChannelList: Array<Channel>

// export default { Channel, ChannelList }
