
import {defineComponent, PropType} from "vue"
import moment from 'moment'
import userStore from '@/stores/user'
import router from "@/router";
import { User, Message, ChannelList, Channel, OauthResponse, SearchResults} from '@/types'

export default defineComponent({
  props: {
    msg: { 
      type: Object as PropType<Message>,
      default: () => ({})
    },
  },  
  data() {
    return {
      imagePrefix: process.env.VUE_APP_BACKEND + '/images/'
    }
  },
  setup() {
    return { userStore }
  },
  methods: {
    // https://stackoverflow.com/questions/66857293/vue-3-date-time-format-in-moment-js
    dateTime(value: string) {
      moment(value).format('YYYY-MM-DD');
    },

    // https://momentjs.com/guides/
    dateTime2(value: string) {
      moment.locale("nb_NO")
      const diff = moment(new Date()).diff(value, 'day');
      if (diff < 3) {
        return moment(value).fromNow()
      } else {
        return moment(value).format('MMM Do YYYY')
      }
      
    }
  }
})
