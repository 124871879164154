import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-dialog modal-fullscreen" }
const _hoisted_2 = { class: "modal-content modal-content-rounded" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, {
      name: "slide2",
      appear: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "modal show",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          onBeforeLeave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBeforeLeave && _ctx.onBeforeLeave(...args))),
          onLeave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onLeave && _ctx.onLeave(...args))),
          onAfterLeave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onAfterLeave && _ctx.onAfterLeave(...args)))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "back"),
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createTextVNode("default header")
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn-close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "body")
              ]),
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: "btn btn-light btn-rounde",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
                  }, "close")
                ])
              ])
            ])
          ])
        ], 544), [
          [_vShow, _ctx.show]
        ])
      ]),
      _: 3
    })
  ]))
}