import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "vax" }
const _hoisted_2 = { class: "vax" }
const _hoisted_3 = { class: "d-flex gap-2 m-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vax_editor = _resolveComponent("vax-editor")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VOnboardingWrapper = _resolveComponent("VOnboardingWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vax_editor, {
        onFinished: _ctx.saveClicked,
        onSelectFile: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectFile($event))),
        ref: "vueavatar",
        id: "vueavatar",
        scale: _ctx.scale,
        imageSrc: _ctx.imageUrl
      }, {
        default: _withCtx(() => [
          _createTextVNode(" velg.. ")
        ]),
        _: 1
      }, 8, ["onFinished", "scale", "imageSrc"]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          class: "me-3",
          type: "range",
          min: "1",
          max: "3",
          step: "0.02",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.scale) = $event))
        }, null, 512), [
          [_vModelText, _ctx.scale]
        ]),
        _createElementVNode("button", {
          class: "btn btn-primary btn-rounded",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveClicked && _ctx.saveClicked(...args)))
        }, _toDisplayString(_ctx.t('save')), 1),
        _createVNode(_component_router_link, {
          class: "btn btn-secondary btn-rounded",
          to: "/me"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_VOnboardingWrapper, {
      ref: "wrapper",
      steps: _ctx.steps,
      options: _ctx.options
    }, null, 8, ["steps", "options"])
  ]))
}