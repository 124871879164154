
import {defineComponent, reactive, ref} from 'vue'
import chatStore from '@/stores/chat'
import messageStore from '@/stores/message'
import userStore from '@/stores/user'
import {Channel, Message, Protocol, User} from '@/types'
import {Delta, QuillEditor} from '@vueup/vue-quill'
import {Cellphone, CellphoneMessage, CellphoneText, CircleSmall, Phone} from 'mdue'
import Post from '@/assets/img/icons/post.svg'
import Mail from '@/assets/img/icons/mail.svg'
import Posting from '@/assets/img/icons/posting.svg'
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue"
import {useI18n} from "vue-i18n"
import MessagingBubble from './MessagingBubble.vue'
import moment from "moment/moment"
import VideoPane from './VideoPane.vue'

const messages = {
  "en": {
    messagesfor: "Messages for ",
    created: "created",
    replies: "replies",
    reply: "reply",
    initialEditorPlaceholder: "",
    editorPlaceholder: "write to ",
    membersince: "member since ",
  },
  "nb_NO": {
    messagesfor: "Meldinger for ",
    created: "opprettet",
    replies: "kommentarer",
    reply: "kommentar",
    initialEditorPlaceholder: "",
    editorPlaceholder: "skriv til ",
    membersince: "medlem siden ",
  }
}

export default defineComponent({
  components: {
    QuillEditor, BreadcrumbsBar, Post, MessagingBubble, Mail, Phone, CellphoneMessage, VideoPane,
  },
  props: {
    id: { type: String, default: '', },
  },
  watch: {
  },
  data() {
    return {
      content: "meldingen kommer her",
      lastScrollTop: Infinity as number,
      messages: [] as Message[]
    }
  },
  setup() {
    console.log("MessagingPane:Setup()")
    // const smoothScroll = Vue.inject('smoothScroll')
    
    const editor = ref(null)
    const newMsg = ref('')

    // const scr = ref(null)
    const chscroller = ref(null)
    const state = reactive({
      id: '',
      title: '',
      messages: [] as Message[],
      group: {} as Channel,
      fetching: false,
      throbber: false,
      throbberId: '',
      user: {} as User,
    })

    function hasMessages(): boolean { return state.messages.length !== 0 }

    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    const bindings = {
      custom: {
        key: 'enter',
        handler: function () {
          console.log("Channel:handler()")
          document.getElementById('send-btn')!.click()
        }
      },
    }

    const quillOptions = {
      theme: 'snow',
      placeholder: t('initialEditorPlaceholder'),
      modules: {
        toolbar: false,
        keyboard: {
          bindings: bindings
        }
      }
    }
    
    return { state, chatStore, hasMessages, t, quillOptions, editor, newMsg, chscroller, }
  },
  
  mounted() {
    console.log("MessagingPane:mounted()", this.state.id);
    messageStore.connect(localStorage.getItem("id") as string, this.onmessage);
  },
  
  beforeRouteEnter: function (to, from, next) {
    console.log("beforeRouteEnter()", to, from, next)
    next(vm => {
      console.log("updating id: " + to.params.id);
      (vm as any).state.id = to.params.id as string
      // (vm as any).messages = messages
      chatStore.getChat((vm as any).state.id)
        .then(resp => {
          (vm as any).state.messages = resp
          console.log("response: ", resp);
          userStore.getUserProfile((vm as any).state.id)
            .then(resp => {
              console.log("response", resp);
              (vm as any).state.user = resp

              // https://github.com/quilljs/quill/issues/1150
              if ((vm as any).editor) {
                console.log("editor:", (vm as any).editor.editor.__quill.root);
                (vm as any).editor.editor.__quill.root.setAttribute('data-placeholder',
                  (vm as any).t('editorPlaceholder') + (vm as any).state.user.givenName);
              }
              
            })
          
        })      
    })
    /*chatStore.getChat(to.params.id as string, (messages: Message[]) => {
      console.log("beforeRouteEnter()", messages)
      next(vm => {
        (vm as any).messages = messages
      })
    })*/
  },
  beforeRouteUpdate: function (to, from, next) {
    console.log("MessagingPane:beforeRouteUpdate()", to, from, next)

    this.state.id = to.params.id as string
    console.log("updating id: " + to.params.id);

    // fetch messages
    chatStore.getChat(this.state.id)
      .then(resp => {
        this.state.messages = resp
        console.log("response: ", resp);
        if (this.editor) {
          (this.editor as any).editor.__quill.root.setAttribute('data-placeholder',
            this.t('editorPlaceholder') + this.state.user.givenName);
          
          // (this.editor as any).root.dataset.placeholder = 'Your new placeholder';
          // (this.editor as any).root.setAttribute('data-placeholder', "Your new placeholder");
        }
        userStore.getUserProfile(this.state.id)
          .then(resp => {
            console.log("response", resp);
            this.state.user = resp
          })
        
      })
    next();
  },
  computed: {
    imageUrl() {
      console.log('getting image url for profile: ', this.state.user.id)
      let ret =  (!this.state.user || this.state.user.id === '' || this.state.user.id === '00000000-0000-0000-0000-000000000000') ?
        'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==' : process.env.VUE_APP_BACKEND + '/images/' + this.state.user.id
      console.log("url: ", ret)
      return ret
    },
  },
  methods: {
    throbberImage(): string {
      return process.env.VUE_APP_BACKEND + '/images/' + this.state.throbberId
    },
    scrollToBottom() {
      setTimeout(() => {
        try {

          const el = this.$refs.chscroller;

          if (el) {
            console.log("scroll..", (this.editor as any).editor.__quill.root);
            (this.editor as any).editor.__quill.root.focus();
              
            // Use el.scrollIntoView() to instantly scroll to the element
            (el as HTMLBaseElement).scrollTo({top: (el as HTMLBaseElement).scrollHeight, behavior: 'smooth'})
            // (el as HTMLBaseElement).scrollIntoView({block: "nearest", inline: "nearest"});
            // (el as HTMLBaseElement).scrollTop = (el as HTMLBaseElement).scrollHeight
          }

          // (this.chscroller! as HTMLBaseElement).scrollTop = (this.chscroller! as HTMLBaseElement).scrollHeight
        } catch (e) {
          console.log("Err")
        }
      }, 200)
    },
    
    dateTime2(value: string) {
      moment.locale("nb_NO")
      const diff = moment(new Date()).diff(value, 'day');
      if (diff < 3) {
        return moment(value).fromNow()
      } else {
        return moment(value).format('MMM Do YYYY')
      }
    },
    textChange( delta: Delta, oldContents: Delta ) {
      // console.log("textChange", (this.editor as any).getContents())
      if ((this.editor as any).getContents() === "<p><br></p>") {
        this.state.throbber = false
      } else {
        messageStore.typing(this.state.id, localStorage.getItem("uid") as string, localStorage.getItem("id") as string)
      }
    },
    /*onScroll() {
      // console.log("scroll?")
      let scrollTop = (this.chscroller! as HTMLBaseElement).scrollTop;
      let offsetHeight = (this.chscroller! as HTMLBaseElement).offsetHeight;
        
      if (scrollTop < 50 && scrollTop < this.lastScrollTop) {
        console.log(" top: " + scrollTop + ", height: " + offsetHeight)
        // console.log("fetch more!")
        this.fetchOlder();
      }
      this.lastScrollTop = scrollTop
    },
    fetchOlder() {
      if (!this.state.fetching) {
        console.log("fetching older messages: ")
        this.state.fetching = true
        // this.state.id = to.params.id as string
        // this.state.title = to.params.title as string
        // need to turn off fetching older
        let lastTimestamp = ""
        if (this.state.messages.length > 0) {
          // console.log("last timestamp: " + this.state.messages[this.state.messages.length-1].createdAt)
          console.log("last timestamp: " + this.state.messages[0].createdAt)
          lastTimestamp = this.state.messages[0].createdAt
        }

        messageStore.getMessages(this.state.id, this.state.title, 10, lastTimestamp,
          (title: string, messages: Message[]) => {
            console.log("Channel:beforeRouteUpdate()", messages, title)
            this.appendData(messages)
            this.state.fetching = false
            // turn back on fetching older  
          })
      } else {
        console.log(".")
      }
    },*/

    onmessage(proto: Protocol) {
      console.log("onmessage()", proto)

      if (proto.type === 'msg') {
        if (this.state.id !== proto.message.userId && this.state.id !== proto.message.recipientId) {
          console.log("ignoring id: " + proto.id + " our id: " + this.state.id)
          return
        }

        this.state.throbber = false
        const message = proto.message
        if (message.messageType === 'reply') {
          const parent = this.state.messages.find(e => e.id === message.inReplyToId)
          if (parent) {
            if (!parent.replies) {
              parent.replies = new Array<Message>()
            }
            parent.replies.push(message)
          } else {
            if (!this.state.messages) {
              this.state.messages = new Array<Message>()
            }
            this.state.messages.push(message)
          }
        } else {
          if (!this.state.messages) {
            this.state.messages = new Array<Message>()
          }
          this.state.messages.push(message)
        }

        // Auto scroll to the bottom
        setTimeout(() => {
          // console.log("scrollTop 2");
          try {
            // (this.chscroller! as HTMLBaseElement).scrollTop = (this.chscroller! as HTMLBaseElement).scrollHeight
          } catch (e) {
            console.log("Err")
          }
        }, 200)
      // } else if (proto.type === 'call') {
        // call method on videoPane component
      // } else if (proto.type === 'answer') {
        // call method on videoPane component
      // } else if (proto.type === 'candidate') {
        // call method on videoPane component
      } else if (proto.throbber) {
        if (this.state.id !== proto.throbber.userId) {
          console.log("ignoring id: " + proto.id + " our id: " + this.state.id)
          return
        }
        
        console.log("throbber;", proto)
        this.showThrobber(proto.throbber.userId)
      }      
    },
    showThrobber(userId: string) {
      // console.log("adding throbber for userId: " + userId)
      // if (userId !== localStorage.getItem("id")) {
        this.state.throbber = true
        setTimeout(() => {
          console.log("clearing throbber")
          this.state.throbber = false
        }, 3000)
      // }
    },
    appendData(messages: Message[]) {
      // let tmp = 
      this.state.messages = messages.concat(this.state.messages)
      // messages.push(tmp)
    },
    setData (/* err: Error, */ messages: Message[]) {
      // if (err) {
      //   this.state.error = err.toString()
      // } else {
        this.state.messages = messages
      
      if (messages.length > 0) { 
        console.log("last timestamp: " + messages[messages.length-1].createdAt)
      }
      // need to extract last time stamp for further fetching
      
      // Auto scroll to the bottom on view
      setTimeout(() => {
        // console.log("scrollTop 1");
        try {
          // (this.chscroller! as HTMLBaseElement).scrollTop = (this.chscroller! as HTMLBaseElement).scrollHeight
        } catch (e) { console.log("Err") }
      }, 800)
      
    },
    send1() {
      console.log("send1: ", (this.editor as any).getContents())
      this.sendMessage((this.editor as any).getContents(), this.state.id);
      (this.editor as any).setContents("")
    },

    sendMessage(message: string, channelId: string) {
      messageStore.message(this.state.id, localStorage.getItem("uid") as string, localStorage.getItem("id") as string, message)
    },
  }  
})
