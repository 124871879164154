<template>
  <div class="scroll-area-2">
    <header>
      <div class="container d-flex p-3">
        <div class="logo flex-grow-1">
          <h4>HelDel</h4>
        </div>
        <div class="actions flex-shrink-0 d-flex">
          <a class="">Hvorfor Heldel?</a>
          <a class="">Kontakt</a>
          <a class="">Vanlig stilte spørsmål</a>
        </div>
      </div>
    </header>
    <div class="container hero">
      <div class="row">
        <div class="col-md-8">
          <h1>HelDel er et nytt produkt fra Karriere.no</h1>
          <div class="lead">Dette er en beta-versjon. Vi vil at du skal teste den.
            Heldel er laget i samarbeid med SALT Lofoten, SMB Norge og Framtidsfylket Vestland.</div>

          <div class="action mt-5">
            <button class="btn btn-rounded btn-primary" @click="scrollToLogin">Kom i gang</button>
          </div>
          
        </div>
        <div class="col-md-4">
          <img class="image" src="@/assets/img/welcome/phone.jpg"/>
        </div>
  

      </div>
      
      <h2 class="sep">3 gode grunner til å bruke Heldel:</h2>

      <div class="row">
        <div class="col-sm-4">
          <div class="card card-yellow">
            <div class="card-header">
              <h2 class="card-title">Samarbeide om tilgangen til arbeidkraft</h2>
            </div>
            <div class="card-body">
              Målet med Heldel er å bidra til at det blir enklere for bedrifter å samarbeide om tilgangen til arbeidkraft.
              Det kan være å samarbeid om å rekruttere nye personer til en delt-stilling, utlån av personer i en begrenset
              periode, samarbeidsprosjekter, eller samlet oppdrag.
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card card-yellow">
            <div class="card-header">
              <h2 class="card-title">Oppdrag og kommunikasjonskanal</h2>
            </div>
            <div class="card-body">
              Heldel en en oppdrag og kommunikasjonskanal. Du som bruker er medlem av en eller flere grupper. En gruppe er
              enten åpen eller lukket. Gruppene består av personer fra bedrifter som ønsker å samarbeide. Medlemmene av en
              gruppe har mulighet til legge ut et behov eller en mulighet.
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card card-yellow">
            <div class="card-header">
              <h2 class="card-title">Samarbeid mellom bedrifter</h2>
            </div>
            <div class="card-body">
              Samarbeidet kan skje mellom bedrifter i samme bransje, samme region eller med allerede eksisterende grupper.
            </div>
          </div>
        </div>
      </div>
      
      <h2 class="sep" ref="start">Kom i gang, logg inn på Heldel:</h2>
      
      <div class="row" ref="login">
        <div class="col-sm-12">
          <div class="card card-blue">
<!--            <div class="card-header d-flex">-->
<!--              Velkommen til Heldel!-->
<!--            </div>-->
            <div class="card-body d-flex flex-column">
              <p>Registrering på Heldel krever foreløbig en e-post adresse fra en forhåndsgodkjent liste. </p>
              <div class="mx-auto">
                <router-link to="/login" class="btn btn-rounded btn-secondary">Logg inn</router-link>
                <router-link to="/signup" class="btn btn-rounded btn-secondary ms-3">Opprett konto</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <div><a class="" href="">Hvorfor Heldel</a></div>
            <div><a class="" href="">Kom i gang</a></div>
            <div><a class="" href="">Vanlig stilte spørsmål</a></div>
          </div>
          <div class="col-sm-4">
            <div><a class="" href="">Kontakt oss</a></div>
            <div><a class="" href="">Personvern og personopplysninger</a></div>
          </div>
          <div class="col-sm-4">
            <div class="d-none d-md-block"><a class="" href="">Facebook</a></div>
            <div class="d-none d-md-block"><a class="" href="">Instagram</a></div>
            <div class="d-none d-md-block"><a class="" href="">Twitter</a></div>
            <div class="d-none d-md-block"><a class="" href="">Linkedin</a></div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-4">
            <h2 class="strong">HelDel</h2>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            Karriere.no AS<br/>
            Thorvald Meyers gate 7,<br/>
            0555 OSLO,<br/>
            Tlf: 760 70 708 (man-fre kl 9-15)<br/>
            <a class="text-link" href="mailto:support@karriere.no">support@karriere.no</a><br/>
                  
                
          </div>
        </div>
      </div>   
      
    </footer>
  </div>
</template>

<script type="ts">
import BreadcrumbsBar from '@/components/BreadcrumbsBar'
import userStore from '@/stores/user'
import {defineComponent, ref} from "vue";

export default defineComponent({
  components: { BreadcrumbsBar },
  setup() {
    const start = ref(null)
    
    return { userStore, start }
  },
  methods: {
    scrollToLogin() {
      const el = this.$refs.start;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  }
})
</script>

<style scoped lang="scss">
.actions {
  a {
    margin-left: 10px;
  }
}
h2.sep {
  margin-bottom: 20px;
  padding-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fbfbf6;
}
.hero {
  h1 {
    font-size: 70px;
    line-height: 82px;
    font-weight: 600;
  }
  .image {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .hero {
    h1 {
      font-size: 40px;
      line-height: 48px;
      font-weight: 600;
    }
  }
}

</style>
