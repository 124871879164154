<template>
  <div class="toast-wrapper">
    <div class="toaster">
      You must enter a value for the todo
    </div>
  </div>
</template>

<script>
export default {
  name: "LocalToast"
}
</script>

<style scoped>
  .toast-wrapper {
    position: fixed;
    width: 100%;
    top: 20px;
  }
  .toaster {
    padding: 20px;
    color: white;
    background: #ff0062;
    border-radius: 10px;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
    max-width: 400px;
    margin: 0 auto;
  }
</style>
