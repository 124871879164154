
/* eslint-disable vue/no-reserved-component-names */
import { Form, Field } from 'vee-validate'
import { useI18n } from 'vue-i18n'
// import i18n from '@/i18n/index.js'

import { defineComponent, reactive, ref, onMounted } from "vue"
import channelStore from '@/stores/channel'
import userStore from "@/stores/user"
import { useRouter } from "vue-router"
import * as Yup from "yup"
import { Modal } from 'bootstrap'
import { User } from "@/types"

const messages = {
  "en": {
    heading: "Create New Network",
    newGroupName: "Network name",
    newGroupDescription: "About the network",
    newGroupUrl: "External resource",
    settings: "Settings",
    interests: "Interests",
    nameRequired: "Network name required",
    externalLink: "External link",
    visibleFeedDescription: "Show as suggestion in home page feed?",
    openNetworkDescription: "You can choose between opening the network for everyone, or only for invited users",
    openNetwork: "Open for all",
    create: "Create",
    save: "Save",
  },
  "nb_NO": {
    heading: "Opprett nytt nettverk",
    headingEdit: "Rediger nettverk",
    newGroupName: "Navn på nettverk",
    newGroupDescription: "Om nettverket",
    newGroupUrl: "Ekstern ressurs",
    settings: "Innstillinger",
    interests: "Interesser",
    nameRequired: "Nettverksnavnet kan ikke være tomt",
    externalLink: "Ekstern lenke",
    visibleFeedDescription: "Synlig som forslag i feeden?",
    openNetworkDescription: "Du kan velge å la nettverket være åpent for alle, eller kun for inviterte",
    openNetwork: "Åpen for alle",
    create: "Opprett",
    save: "Lagre",
  }
}

export default defineComponent({
  components: { Form, Field, },
  setup() {

    const element = ref<HTMLElement>()
    const instance = ref<Modal>()
    
    const form = reactive({
      newGroupName: '',
      newGroupDescription: '',
      newGroupUrl: '',
      newGroupOpen: false,
      newGroupPromote: false,
      id: "",
    })
    
    const router = useRouter()

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const onSubmit= () => {
      console.log("lagrer nettverk: " + form.newGroupName + ", ny? " + form.id === "")

      channelStore.storeChannel(form.newGroupName, form.newGroupDescription, form.newGroupUrl,
        form.newGroupOpen ? true : false, form.newGroupPromote ? true : false, form.id)
        .then((success) => {
          if (success) {
            router.push('/subscriptions');
            // reload channel list
            channelStore.getChannels()
          } else {
            // errors = {}
            // need to reset input validation, only show login error
          }
        })
      instance?.value?.hide()
    }
    
    const schema = Yup.object().shape({
      newGroupName: Yup.string()
        .required(t('nameRequired')),
    })

    onMounted(() => {
      instance.value = new Modal(element.value as HTMLElement, {})
      // instance.value?.show()
    })
    
    return { form, t, router, schema, instance, element, onMounted, onSubmit }
  },
  methods: {
    newGroup() {
      console.log("showing modal");
      this.form.newGroupName = ""
      this.form.newGroupDescription = ""
      this.form.newGroupOpen = false
      this.form.newGroupPromote = false
      this.form.id = ""
      
      // let mo = new Modal((this.element as HTMLElement), {})
      // mo.show()
      this.instance?.show()
    },
    async editGroup(id: string) {
      console.log("showing modal");
      // (this.editGroupModal2!).show()
      // (this.instance!).value!.show()
      let self = this
      await channelStore.getChannel(id)
      .then(results => {
        console.log("resp1", results);

        self.form.newGroupName = results.title
        self.form.newGroupDescription = results.description
        self.form.newGroupOpen = results.open
        self.form.newGroupPromote = results.promote
        self.form.id = id

        console.log("using name: " + self.form.newGroupName)
        self.instance?.show()
      })
      // console.log("resp2", group);
      
      // console.log("group: " + group)
      // let mo = new Modal((this.element as HTMLElement), {})
      // mo.show()
      // this.instance.value = new Modal((this.element.value! as HTMLElement), {})
      // (this.instance?.value!).show()
      
    },
    /*
    onSubmit2() {
      console.log("oppretter nytt nettverk: " + this.form.newGroupName)

      channelStore.createChannel(this.form.newGroupName, this.form.newGroupDescription, this.form.newGroupOpen, 
        this.form.newGroupPromote)
      .then((success) => {
        if (success) {
          this.router.push('/channels');
        } else {
          // router.push('/login')
          // errors = {}
          // need to reset input validation, only show login error
        }
      })
    }*/
  }  
})
