import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8229f6c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "scroll-area container-fluid"
}
const _hoisted_2 = { class: "image-wrap" }
const _hoisted_3 = {
  ref: "profileImage",
  id: "profileImage",
  class: "profile-image",
  src: ""
}
const _hoisted_4 = { class: "actions my-3 d-flex" }
const _hoisted_5 = { class: "form-floating mb-3" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { for: "mail" }
const _hoisted_8 = { class: "form-floating mb-3" }
const _hoisted_9 = { for: "givenName" }
const _hoisted_10 = { class: "form-floating mb-5" }
const _hoisted_11 = { for: "surName" }
const _hoisted_12 = { class: "sepa" }
const _hoisted_13 = { class: "form-floating mb-3" }
const _hoisted_14 = { for: "companyName" }
const _hoisted_15 = { class: "form-floating mb-5" }
const _hoisted_16 = { for: "companyUrl" }
const _hoisted_17 = { class: "form-floating mb-4" }
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { for: "location" }
const _hoisted_20 = { class: "form-floating mb-3" }
const _hoisted_21 = {
  disabled: "",
  value: ""
}
const _hoisted_22 = ["value"]
const _hoisted_23 = { for: "yearBorn" }
const _hoisted_24 = { class: "form-floating mb-3" }
const _hoisted_25 = { for: "phone" }
const _hoisted_26 = { class: "form-floating mb-3" }
const _hoisted_27 = { for: "description" }
const _hoisted_28 = { class: "mt-3" }
const _hoisted_29 = {
  class: "btn btn-primary btn-rounded d-block mx-auto",
  type: "submit"
}
const _hoisted_30 = { class: "links mx-4" }
const _hoisted_31 = { class: "l flex-grow-1 text-start" }
const _hoisted_32 = { class: "l flex-grow-1 text-start" }
const _hoisted_33 = { class: "l flex-grow-1 text-start" }
const _hoisted_34 = { class: "wr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsBar = _resolveComponent("BreadcrumbsBar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_ArrowForward = _resolveComponent("ArrowForward")!
  const _component_Help = _resolveComponent("Help")!
  const _component_Key = _resolveComponent("Key")!
  const _component_Logout = _resolveComponent("Logout")!
  const _component_VOnboardingWrapper = _resolveComponent("VOnboardingWrapper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadcrumbsBar, {
      title: _ctx.t('myprofile'),
      image: "true"
    }, null, 8, ["title"]),
    (!_ctx.state.isFetching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.state.isFetching)
            ? (_openBlock(), _createElementBlock("form", {
                key: 0,
                class: "form-vertical form-profile",
                onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", _hoisted_3, null, 512),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_router_link, {
                      class: "mx-auto text-link",
                      to: "/me/avatar",
                      id: "changeProfileImage"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('editimage')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("input", {
                    type: "text",
                    class: "disabled form-control-static form-control border-0",
                    id: "mail",
                    disabled: "",
                    value: _ctx.userStore.getters.uid
                  }, null, 8, _hoisted_6),
                  _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.t('email')), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control border-0",
                    id: "givenName",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.givenName) = $event)),
                    placeholder: "{{ t('givenname') }}",
                    required: ""
                  }, null, 512), [
                    [_vModelText, _ctx.state.givenName]
                  ]),
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.t('givenname')), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control border-0",
                    id: "surName",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.surName) = $event)),
                    placeholder: "{{ t('surname') }}",
                    required: ""
                  }, null, 512), [
                    [_vModelText, _ctx.state.surName]
                  ]),
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.t('surname')), 1)
                ]),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('ifcompany')), 1),
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control border-0",
                    id: "companyName",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.companyName) = $event)),
                    placeholder: "{{ t('companyName') }}"
                  }, null, 512), [
                    [_vModelText, _ctx.state.companyName]
                  ]),
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.t('companyName')), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control border-0",
                    id: "companyUrl",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.companyUrl) = $event)),
                    placeholder: "{{ t('companyUrl') }}"
                  }, null, 512), [
                    [_vModelText, _ctx.state.companyUrl]
                  ]),
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t('companyUrl')), 1)
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control border-0",
                    id: "location",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.location) = $event)),
                    placeholder: _ctx.t('location')
                  }, null, 8, _hoisted_18), [
                    [_vModelText, _ctx.state.location]
                  ]),
                  _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.t('location')), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _withDirectives(_createElementVNode("select", {
                    id: "yearBorn",
                    class: "form-select border-0",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.yearBorn) = $event))
                  }, [
                    _createElementVNode("option", _hoisted_21, _toDisplayString(_ctx.t('bornyear')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allYears, (year) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: year,
                        key: year
                      }, _toDisplayString(year), 9, _hoisted_22))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.state.yearBorn]
                  ]),
                  _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.t('bornyear')), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _withDirectives(_createElementVNode("input", {
                    type: "string",
                    class: "form-control border-0",
                    id: "phone",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.phone) = $event)),
                    placeholder: "{{ t('phone') }}"
                  }, null, 512), [
                    [_vModelText, _ctx.state.phone]
                  ]),
                  _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.t('phone')), 1)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _withDirectives(_createElementVNode("textarea", {
                    rows: "6",
                    class: "form-control border-0",
                    id: "description",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.description) = $event)),
                    placeholder: "{{ t('aboutme') }}"
                  }, null, 512), [
                    [_vModelText, _ctx.state.description]
                  ]),
                  _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.t('aboutme')), 1)
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("button", _hoisted_29, _toDisplayString(_ctx.t('update')), 1)
                ])
              ], 32))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_30, [
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/me/settings",
                class: "d-flex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Settings, { class: "settings indent" }),
                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('mysettings')), 1),
                  _createVNode(_component_ArrowForward, { class: "chevron-right" })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/about",
                class: "d-flex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Help, { class: "help indent" }),
                  _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t('whyheldel')), 1),
                  _createVNode(_component_ArrowForward, { class: "chevron-right" })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/about#privacy",
                class: "d-flex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Key, { class: "key indent" }),
                  _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('terms')), 1),
                  _createVNode(_component_ArrowForward, { class: "chevron-right" })
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("button", {
              class: "d-block mx-auto btn btn-rounded btn-dark",
              onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.signout()), ["stop"]))
            }, [
              _createVNode(_component_Logout, { class: "invert logout" }),
              _createTextVNode(" Logg ut")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_VOnboardingWrapper, {
      ref: "wrapper",
      steps: _ctx.steps,
      options: _ctx.options
    }, null, 8, ["steps", "options"])
  ]))
}