import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ca875aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vcall" }
const _hoisted_2 = { class: "vcall-wrap" }
const _hoisted_3 = { class: "video-wrapper" }
const _hoisted_4 = {
  class: "video",
  ref: "local",
  id: "local_video",
  autoplay: "",
  playsinline: ""
}
const _hoisted_5 = {
  class: "video",
  ref: "remote",
  id: "remote_video",
  autoplay: "",
  playsinline: ""
}
const _hoisted_6 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Video = _resolveComponent("Video")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("video", _hoisted_4, null, 512),
        _createElementVNode("video", _hoisted_5, null, 512),
        _createTextVNode(" " + _toDisplayString(_ctx.t('disabled')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "disabled start-btn btn",
          disabled: "",
          id: "enable-btn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.start1 && _ctx.start1(...args)))
        }, [
          _createVNode(_component_Video)
        ])
      ])
    ])
  ]))
}