import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "scroll-area container-fluid" }
const _hoisted_2 = { href: "/" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsBar = _resolveComponent("BreadcrumbsBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadcrumbsBar, {
      back: "/",
      title: _ctx.t('pageTitle')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.t('title')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.t('description')), 1),
      _createElementVNode("p", null, [
        _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.t('home')), 1)
      ])
    ])
  ]))
}