import { computed, inject, reactive, readonly } from 'vue'
import { Channel, ChannelList } from '@/types'
import axios from "axios";

// const backend = inject('backend')

const state: {
  chats: Array<Channel>
  error: string
} = reactive({
  chats: [],
  error: '',
})

const getters = reactive({
  chats: computed(() => state.chats),
})


const actions = {
  async getChats() {
    console.log("auth: ", axios.defaults.headers.common['Authorization'])
    return await axios.get(process.env.VUE_APP_BACKEND + '/api/v1/chats')
      .then(resp => {
        console.log("chat.ts:getChats()", resp.data.items)
        return resp.data.items
      })
      .catch(err => {
        console.log("error: " + err)
        return
      })
    
    /*axios.get(process.env.VUE_APP_BACKEND + '/api/v1/chats')
      .then(resp => {
        console.log("chat.ts:getChats() my chats:", resp.data.items);
        state.chats = resp.data.items
        return resp.data.items
      })
      .catch(err => {
        console.log("error: " + err)
      })*/
    
  },
  
  async getChat(recipientId: string): Promise<any> {
    console.log("chat:getChat(): loading chat with id: " + recipientId)

    return await axios.get(process.env.VUE_APP_BACKEND + '/api/v1/messages/' + recipientId)
      .then(resp => {
        console.log("chat.ts:getChat()", resp.data)
        return resp.data
      })
      .catch(err => {
        console.log("error: " + err)
        return
      })
  },

}

export default { state, getters, ...actions }
