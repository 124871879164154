

// based on https://github.com/nivaldomartinez/link-prevue

import axios from "axios"
import {defineComponent} from "vue"
import {useI18n} from "vue-i18n";


const messages = {
  "en": {
    viewmore: "View More",
  },
  "nb_NO": {
    viewmore: "Les mer",
  }
}

export default defineComponent({
  name: "link-prevue",
  props: {
    url: { type: String, default: "", },
    cardWidth: { type: String, default: "400px",},
    onButtonClick: {type: Function, default: undefined,},
    showButton: {type: Boolean, default: true,},
    apiUrl: {type: String, default: process.env.VUE_APP_BACKEND + "/api/v1/preview",},
  },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })

    return { t, }
  },
  created() {
    this.getLinkPreview()
  },
  data() {
    return {
      response: null as any,
      validUrl: false as boolean,
    }
  },
  watch: {
    'url': function (newValue: string, oldValue: string) {
      this.response = null
      this.getLinkPreview()
    },
  },
  methods: {
    viewMore: function () {
      if (this.onButtonClick !== undefined) {
        this.onButtonClick(this.response);
      } else {
        const win = window.open(this.url, "_blank");
        if (win) { win.focus(); }
      }
    },
    isValidUrl: function (url: string): boolean {
      const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
      this.validUrl = regex.test(url)
      return this.validUrl
    },
    getLinkPreview: function () {
      if (this.isValidUrl(this.url)) {
        this.httpRequest((response: any) => {
            this.response = response
          },
          () => {
            this.response = null
            this.validUrl = false
          }
        );
      }
    },
    httpRequest: function (success: any, error: any) {

      const params = new URLSearchParams()
      params.append("url", this.url)
      const config = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}

      return axios.post(process.env.VUE_APP_BACKEND + '/api/v1/preview', params, config).then(resp => {
        console.log("preview response: ", resp)
        success(resp.data)
        return Promise.resolve()
      })
    },
  },
})

