import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29d2c082"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "hd-help"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_OnlineHelp = _resolveComponent("OnlineHelp")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      class: _normalizeClass({main: _ctx.$route.name !== 'welcome', 'welcome' : _ctx.$route.name === 'welcome'})
    }, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "nicee",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { ref: "view" }, null, 512))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ], 2),
    (_ctx.$route.name !== 'welcome')
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createVNode(_component_OnlineHelp)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$route.name !== 'welcome')
      ? (_openBlock(), _createBlock(_component_NavigationBar, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}