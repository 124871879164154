
/* eslint-disable vue/no-reserved-component-names */
import { defineComponent, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { Form, Field } from 'vee-validate';
import userStore from '@/stores/user'
import { useRouter } from 'vue-router'
import * as Yup from "yup";

const messages = {
  "en": {
    heading: "Forgot Password",
    description: "Enter email address and we will send an email contaning a link to reset your password",
    username: "email address",
    send: "send email",
    emailRequired: "email required",
    emailInvalid: "email format invalid",
    action: "register account",
    login: "login",
  },
  "nb_NO": {
    heading: "Glemt passord",
    description: "Skriv inn e-post adressen din så sender vi deg en lenke hvor du kan sette nytt passord",
    username: "e-post adresse",
    send: "send e-post",
    emailRequired: "oppgi e-post",
    emailInvalid: "ugyldig e-post format",
    action: "opprett brukerkonto",
    login: "logg inn",
  }
}

export default defineComponent({
  components: {
    Form,
    Field,
  },
  setup() {
    const form = reactive({
      username: '',
    })
    const router = useRouter()

    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const schema = Yup.object().shape({
      username: Yup.string()
        .required(t('emailRequired'))
        .email(t('emailInvalid')),
      password: Yup.string()
        .required(t('passwordRequired')),
    })

    return { t, schema, form, router, userStore }
  },
  methods: {
    onSubmit() {
      console.log("sending reset password request")
      const u = this.form.username;

      userStore.resetpassword(u)
        .then(() => {
          this.router.push('/');
        })
    }
  }
})
