
import {defineComponent, onMounted, reactive, ref} from "vue";
import userStore from '@/stores/user'
import messageStore from '@/stores/message'
import { useI18n } from "vue-i18n";
import {Magnify, Pencil, PlusCircleOutline, Close, } from 'mdue'

import Waving from "@/assets/img/extras/Emoji_u1f44b.svg";
import Dot from "@/assets/img/icons/dot.svg";
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import GroupBubble from "@/components/groups/GroupBubble.vue";
import PendingGroupBubble from "@/components/groups/PendingGroupBubble.vue";
import MessageBubble from "@/components/groups/MessageBubble.vue";
import channelStore from "@/stores/channel";
import GroupModal from "@/components/groups/GroupModal.vue";
import {Channel, Message, User} from "@/types";
// import {asyncComputed} from "@/stores/async";
import ProfileSheet from "@/components/profile/ProfileSheet.vue";

const messages = {
  "en": {
    home: "Home",
    hello: "Hello",
    networknews: "New in Networks:",
    networkpending: "Invitations:",
    suggested: "Suggested for You:",
    newhere: "Hello, you're new here?",
    teaser: "Or something else?",
    createnetwork: "Create Network",
    search: "Search",
    searchresult: "Search Result",
  },
  "nb_NO": {
    home: "Hjem",
    hello: "Heisann",
    networknews: "Nytt i nettverkene:",
    networkpending: "Invitasjoner:",
    suggested: "Foreslått for deg:",
    newhere: "Hei, er du ny her?",
    teaser: "Eller noe annet?",
    createnetwork: "opprett nettverk",
    search: "Søk",
    searchresult: "Søkeresultat",
  }
}

export default defineComponent({
  components: { BreadcrumbsBar, MessageBubble, PendingGroupBubble, GroupBubble, Dot, 
    PlusCircleOutline, GroupModal, Magnify, Close, ProfileSheet, },
  setup() {
    const { t } = useI18n({ messages: messages, useScope: 'global' })

    const query = ref()

    const state = reactive({
      messages: [] as Message[],
    })

    /*
    const messages7 = asyncComputed(async () => {
      console.log('loading messages7')

      let beef6 = messageStore.lastMessages('', 3);
      await new Promise(resolve => setTimeout(resolve, 2000))
      return beef6
    }, { })

    const channels7 = asyncComputed(async () => {
      console.log('loading channels7')

      let beef7 = channelStore.getChannels();
      await new Promise(resolve => setTimeout(resolve, 2000))
      return beef7
    }, { lazy: true })
  */  
    return { userStore, messageStore, channelStore, t, query, state, } //messages7, channels7, messagesStatus: messages7.status }
  },
  mounted() {
    console.log("getting channels... ");

    // https://learnvue.co/2020/12/how-to-use-lifecycle-hooks-in-vue3/
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
    let response = this.messageStore.lastMessages('', 3)
      .then((resp) => {
        console.log("got latest messages ", JSON.stringify(response), resp)
      })
    let response2 = this.channelStore.getChannels()
      .then((resp) => {
        console.log("got channels: ", JSON.stringify(response2), resp)
      })    
  },
  methods: {
    newChannel() {
      console.log("creating new group");
      (this.$refs.mymodal! as any).newGroup()
    },
    showProfile(id: string) {
      console.log("22 show profile: " + id);
      (this.$refs.profileSheet! as any).show(id)
    },

    clearSearch(ev: Event) {
      // if (ev.currentTarget 
      console.log("clearing search input", ev);
      (this.query as HTMLInputElement).value = ""
      this.state.messages = []
    },
    filterList() {
      console.log("searching for: " + (this.query as HTMLInputElement).value)

      messageStore.searchMessages((this.query as HTMLInputElement).value)
        .then(data => {
          console.log("data; ", data)
          this.state.messages = data
        })
    },
    
  },
  computed: {
    title() {
      return (this.userStore.getters.givenName.trim() === '') ? 
        this.userStore.getters.email : this.userStore.getters.givenName
    }
  },
})
