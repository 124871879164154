<template>
  <div>
  <BreadcrumbsBar title="Om" image="true"/>
  <div class="scroll-area container-fluid" v-if="userStore.getters.isLoggedIn">
    
<!--    <h1>Hvorfor HelDel?</h1>
    <p>Å finne dyktige folk tar tid og energi. Og godt er det - rekruttering er viktig for bedrifter.</p>
    <p>
      Vi startet HelDel fordi vi så et behov for en ny arena, der flinke folk kan møtes og bli kjent og dele erfaringer.</p>
    <p>
      HelDel skal være sosialt og uformelt, et sted som gjør arbeidsdagen bedre og rekruttering en hel del enklere.
      
      Les også: Hvordan HelDel?
    </p>-->

    <h1>Hvorfor HelDel?</h1>
    <p>Heldel.no er et nytt produkt fra Karriere.no. Dette er en beta-versjon. Vi vil at du skal teste den.
      Heldel er laget i samarbeid med SALT Lofoten, SMB Norge og Framtidsfylket Vestland.</p>
    <h1>Hva er Heldel?</h1>
    <p>Målet med Heldel er å bidra til at det blir enklere for bedrifter å samarbeide om tilgangen til arbeidkraft.
      Det kan være å samarbeid om å rekruttere nye personer til en delt-stilling, utlån av personer i en begrenset
      periode, samarbeidsprosjekter, eller samlet oppdrag. Heldel handler om å vise frem behovene og skape kontakt
      og dialog (og forhåpentligvis en løsning). Det hele handler om å tenke helhet og deling (derav navnet).
      Samarbeidet kan skje mellom bedrifter i samme bransje, samme region eller med allerede eksisterende grupper.</p>
    <p>Heldel en en oppdrag og kommunikasjonskanal. Du som bruker er medlem av en eller flere grupper. En gruppe er
      enten åpen eller lukket. Gruppene består av personer fra bedrifter som ønsker å samarbeide. Medlemmene av en
      gruppe har mulighet til legge ut et behov eller en mulighet. De andre i gruppa kan komme med sine kommentarer
      og svar. Målet er å komme frem til en løsning sammen.</p>
    <p>Eksempel: «Hei, vi søker etter en ny markedsfører i vårt firma, vi har imidlertid ikke ressurser til en 100%
      stilling, er det noen som har samme behovet som kunne tenkt seg å dele 50/50»</p>
    <p>Svar: «Hei, har du noen kandidater? Jeg kjenner en som i dag jobber 50% hos Bedrift AS, skal jeg be henne
      kontakte dere?»</p>
    <p>Svar: «Ja, bare send meg kontaktinfo, så tar jeg opp tråden»</p>
    <h1>Hvordan bruke HelDel?</h1>
    <p>Det første du må gjøre er å registrere en bruker. Men ettersom du leser dette så har du allerede klart det.</p>
    <p>Det neste er å opprette en gruppe og invitere dine samarbeidspartnere inn i gruppa. Når de er ombord så er
      dere klare. Hvis du er invitert av noen så er du allerede medlem av gruppa. Du kan også søke igjennom grupper
      og melde deg inn om de er åpne eller spørre om å delte i lukkede grupper.</p>
    <p>I en gruppe kan du enten be om hjelp (legg inn et innlegg) eller hjelp til (send et svar). Når gruppa har
      funnet en løsning så lukkes innlegget. En gruppe kan jobbe med flere innlegg samtidig.</p>
    <p>Som medlem av grupper vil du få varsler om noen ber om hjelp (innlegg) eller nye forslag til hjelp (svar) kommer inn.</p>
    <p>Om du trenger hjelp så sender du en epost hit (torgeir@karriere.no)</p>
    <p>Lykke til.</p>
    

    
    <h1 id="privacy">Personvern</h1>
    
    <p>Personver er viktig for deg og for oss. Vi har lagt stor vekt på at det skal være ryddig og enkelt å
    forstå hvordan vi behandler dine data. </p>
    
    <p>Derfor har vi skrevet en utfyllende personvernerklæring som du kan lese her: &lt;link&gt;</p>
    
    <p>All innsamling og bruk av personopplysninger er underlagt norsk personvernlovgivning.</p>
    
    <p>Dersom du har spørsmål er det bare å ta kontakt - vi er her for å hjelpe!</p>
    
    <p>Ansvarlig for personvern: <br/> Navn:<br/>Kontaktinformasjon:<br/></p>
  </div>
  <div class="scroll-area container-fluid" v-if="!userStore.getters.isLoggedIn">
    Heldel.no er et nytt prosjekt fra Karriere.no.
  </div>
  </div>
</template>

<script type="ts">
import BreadcrumbsBar from '@/components/BreadcrumbsBar'
import userStore from '@/stores/user'
import { defineComponent } from "vue";

export default defineComponent({
  components: { BreadcrumbsBar },
  setup() {
    return { userStore }
  }
})
</script>
