
import {defineComponent, PropType} from "vue"
import moment from 'moment'
import userStore from '@/stores/user'
import router from "@/router";
// import Message from "@/types";
import { User, Message, ChannelList, Channel, OauthResponse, SearchResults} from '@/types'
import {useI18n} from "vue-i18n";

const messages = {
  "en": {
  },
  "nb_NO": {
  }
}

export default defineComponent({
  props: {
    msg: { type: Object as PropType<Message>, default: () => ({}) },
  },  
  data() {
    return {
      imagePrefix: process.env.VUE_APP_BACKEND + '/images/'
    }
  },
  computed: {
    otherParty(): User | null {
      if (this.msg && this.msg.id && this.msg.recipient) {
        if (this.msg.user.id == userStore.getters.id) {
          return this.msg.recipient
        } else {
          return this.msg.user
        }
      }
      return null
    }
  },
  setup() {

    const { t } = useI18n({ messages: messages, useScope: 'global' })
    
    return { userStore, t }
  },
  methods: {
    // https://stackoverflow.com/questions/66857293/vue-3-date-time-format-in-moment-js
    dateTime(value: string) {
      moment(value).format('YYYY-MM-DD');
    },

    select() {
      console.log("navigating to channel: " + this.msg .recipientId)
      router.push({name: 'messagingPane', params: {'id': this.msg.recipientId}})
    },
    // https://momentjs.com/guides/
    dateTime2(value: string) {
      moment.locale("nb_NO")
      const diff = moment(new Date()).diff(value, 'day');
      if (diff < 3) {
        return moment(value).fromNow()
      } else {
        return moment(value).format('MMM Do YYYY')
      }
    }
  }
})
